import { CreatePromotionModule } from '@modules';
import { StyledPromotionPage } from './Promotion';

export const EditPromotionPage = () => {
  return (
    <StyledPromotionPage>
      <div className="page__head">
        <h2 className="title">Sửa khuyến mãi</h2>
      </div>
      <div className="page__section">
        <CreatePromotionModule isEdit />
      </div>
    </StyledPromotionPage>
  );
};
