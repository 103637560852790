import { warehouseAPI } from '@api';
import { WarehouseForm } from '@components';
import {
  AddressItem,
  AutocompleteData,
  AutocompleteItem,
  CreateWarehousePayload,
  CreateWarehouseResponse,
  CreateWarehouseRoot,
  DistrictRoot,
  ProvinceRoot,
  SelectedAddress,
  WardRoot,
} from '@interfaces';
import { selectApp, setLoading, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { useEffect } from 'react';
import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
export enum ESelectedAddressItem {
  PROVINCE = 'province_code',
  DISTRICT = 'district_code',
  WARD = 'ward_code',
}
export const ADDRESS_ERRORS = {
  [ESelectedAddressItem.PROVINCE]: 'Vui lòng chọn tỉnh/thành phố',
  [ESelectedAddressItem.DISTRICT]: 'Vui lòng chọn quận/huyện',
  [ESelectedAddressItem.WARD]: 'Vui lòng chọn phường/xã',
};
const schema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên kho'),
  code: yup.string().required('Vui lòng nhập mã kho'),
  phone_number: yup.string().required('Vui lòng nhập số điện thoại kho'),
  address_detail: yup.string().required('Vui lòng nhập địa chỉ chi tiết kho'),
});
export const WarehouseFormModule = ({
  onSuccess,
  onModalInteract,
  modalVisible,
  register,
  handleSubmit,
  errors,
  isEdit,
  defaultAddress,
  currentId,
}: {
  onSuccess: (res: CreateWarehouseResponse) => void;
  onModalInteract: (val: boolean, id: string) => void;
  modalVisible: boolean;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  isEdit: boolean;
  defaultAddress: any;
  currentId: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeMode } = useAppSelector(selectApp);
  const { id } = useParams();

  const goBack = () => {
    navigate(-1);
  };
  const [autocompleteData, setAutoCompleteData] = useImmer<AutocompleteData>({
    provinces: [],
    districts: [],
    wards: [],
  });
  const [selectedAddress, setSelectedAddress] = useImmer<SelectedAddress>({
    province_code: defaultAddress?.province?.code || '',
    district_code: defaultAddress?.district?.code || '',
    ward_code: defaultAddress?.ward?.code || '',
  });
  const [addressErrors, setAddressErrors] = useImmer({
    province_code: '',
    district_code: '',
    ward_code: '',
  });
  const onCheckAddressItem = (key: ESelectedAddressItem) => {
    if (selectedAddress[key] === '') {
      setAddressErrors((draft) => {
        draft[key] = ADDRESS_ERRORS[key];
      });
      return false;
    }
    setAddressErrors((draft) => {
      draft[key] = '';
    });
    return true;
  };

  const onGetAddress = async () => {
    try {
      const res: ProvinceRoot = await warehouseAPI.getProvinces();
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.provinces.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.provinces = optionValues;
      });
    } catch (error) {}
  };

  const onSelectAddress = (value: string, key: 'province_code' | 'district_code' | 'ward_code') => {
    setSelectedAddress((draft) => {
      draft[key] = value;
    });
  };
  const onSelectProvince = async (value: string) => {
    try {
      onSelectAddress(value, 'province_code');
      const res: DistrictRoot = await warehouseAPI.getDistricts(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.districts.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.districts = optionValues;
      });
    } catch (error) {}
  };
  const onSelectDistrict = async (value: string) => {
    try {
      onSelectAddress(value, 'district_code');
      const res: WardRoot = await warehouseAPI.getWards(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.wards.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.wards = optionValues;
      });
    } catch (error) {}
  };
  const onSelectWard = (value: string) => {
    onSelectAddress(value, 'ward_code');
  };
  const onCreateWarehouse = handleSubmit(async (data) => {
    try {
      if (
        !onCheckAddressItem(ESelectedAddressItem.PROVINCE) ||
        !onCheckAddressItem(ESelectedAddressItem.DISTRICT) ||
        !onCheckAddressItem(ESelectedAddressItem.WARD)
      )
        return;
      dispatch(setLoading(true));
      const payload: CreateWarehousePayload = {
        name: data.name,
        code: data.code,
        phone_number: data.phone_number,
        address_request: {
          province_code: selectedAddress.province_code,
          district_code: selectedAddress.district_code,
          ward_code: selectedAddress.ward_code,
          detail: data.address_detail,
        },
      };
      const res: CreateWarehouseRoot = !isEdit
        ? await warehouseAPI.createWarehouse(payload)
        : await warehouseAPI.updateWarehouse(payload, currentId);
      toast.success(res.message, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
      onSuccess(res.data);
    } catch (error) {
      LogApp(error, 'keke');
    } finally {
      dispatch(setLoading(false));
    }
  });
  useEffect(() => {
    onGetAddress();
  }, []);
  useEffect(() => {
    if (defaultAddress.province.code) {
      onSelectProvince(defaultAddress.province.code);
    }
    if (defaultAddress.district.code) onSelectDistrict(defaultAddress.district.code);
    if (defaultAddress.ward.code) onSelectWard(defaultAddress.ward.code);
  }, [defaultAddress]);
  const onCloseModal = () => {
    onModalInteract(false, '');
    setAddressErrors((draft) => {
      draft.province_code = '';
      draft.district_code = '';
      draft.ward_code = '';
    });
  };
  return (
    <WarehouseForm
      register={register}
      onCreateWarehouse={onCreateWarehouse}
      errors={errors}
      goBack={goBack}
      autocompleteData={autocompleteData}
      selectedAddress={selectedAddress}
      onSelectProvince={onSelectProvince}
      onSelectDistrict={onSelectDistrict}
      onSelectWard={onSelectWard}
      onModalInteract={onModalInteract}
      modalVisible={modalVisible}
      addressErrors={addressErrors}
      isEdit={isEdit}
      defaultAddress={defaultAddress}
      onCloseModal={onCloseModal}
    />
  );
};
