import { OrderDetailModule } from '@modules';
import { StyledOrderPage } from './Order';

export const OrderDetailPage = () => {
  return (
    <StyledOrderPage>
      <div className="page__head">
        <h2 className="title">Chi tiết đơn hàng</h2>
      </div>
      <OrderDetailModule />
    </StyledOrderPage>
  );
};
