import { DefaultApiParams } from '@api';
import { DEFAULT_CATEGORY_FILTER_ITEM, MAIN_THEME_DATA } from '@configs';
import {
  Product,
  ProductFilterDropdowns,
  ProductResponse,
  PromotionColumn,
  PromotionData,
  PromotionalProduct,
  PromotionalProductResponse,
} from '@interfaces';
import { useTheme } from '@theme';
import { Divider, List, Skeleton } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ChangeEvent } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { AppModal } from '../AppModal';
import { IConCancel, SearchIcon } from '../Icon';
import {
  Pagination,
  ShareInput,
  ShareSelectInput,
  SharedButton,
  SharedCheckbox,
  SharedImage,
  SharedTable,
} from '../shared';
import { LogApp } from '@utils';
interface IProps {
  columns: ColumnsType<PromotionColumn>;
  onNavigateToCreatePromotion: () => void;
  productFilterData: ProductFilterDropdowns;
  onSearch: (text: string) => void;
  onSearchPromotion: (text: string) => void;
  onChangeWarehouse: (id: string) => void;
  onChangeCategory: (id: string) => void;
  payload: DefaultApiParams;
  productData: ProductResponse;
  loadMoreData: (key: 'promotion' | 'product') => void;
  promotions: PromotionData;
  currentPage?: number;
  onPageChange: (page: number) => void;
  modalVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSelectProduct: (item: Product) => void;
  onRemovePromotionalProduct: (item: PromotionalProduct) => void;
  promotionalProducts: PromotionalProductResponse;
  onChangeFile: (event: ChangeEvent<HTMLInputElement>) => void;
  onUploadFile: () => void;
  onSelectAllProduct: () => void;
  onAddAllProduct: () => void;
  selectedFile: any;
  onPickProduct: (index: number) => void;
}
export const Promotion = (props: IProps) => {
  const {
    columns,
    onNavigateToCreatePromotion,
    onSearch,
    productFilterData,
    onChangeWarehouse,
    onChangeCategory,
    payload,
    productData,
    loadMoreData,
    promotions,
    currentPage,
    onPageChange,
    modalVisible,
    onClose,
    onConfirm,
    onSelectProduct,
    promotionalProducts,
    onRemovePromotionalProduct,
    onChangeFile,
    onUploadFile,
    selectedFile,
    onSelectAllProduct,
    onAddAllProduct,
    onPickProduct,
    onSearchPromotion,
  } = props;
  const { theme } = useTheme();
  const childCategory =
    productFilterData.categories
      .find((item) => item.item.id === payload.category_id)
      ?.item.sub_categories.map((item) => {
        return {
          label: item.name,
          value: item.id,
          item,
        };
      }) || [];
  const promotionalProductIds = promotionalProducts.content.map((item) => item.product_id);
  const filteredProduct = productData.products.filter(
    (item) => !promotionalProductIds.includes(item.id),
  );
  return (
    <StyledTableWrapper>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search-tier__box"
            className="search-tier__input"
            onChange={(e: any) => {
              onSearchPromotion(e.target.value);
            }}
            placeholder="Tìm kiếm"
            prevIcon={<SearchIcon />}
          />
        </div>
        <div className="flex">
          <SharedButton
            onClick={() => {
              onNavigateToCreatePromotion();
            }}
            className="create-tier__button"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            text="Thêm khuyến mãi"
            btnStyle="pad"
          />
        </div>
      </StyledSearchWrapper>
      <SharedTable columns={columns} dataSource={promotions.data} />
      <div className="bottom-pagination">
        <Pagination
          currentPage={currentPage}
          className="pagination-bar"
          totalCount={promotions.count}
          pageSize={promotions.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      {modalVisible && (
        <AppModal open={modalVisible}>
          <form className="modal">
            <h2>Chọn sản phẩm</h2>
            <div className="filter-block">
              <ShareInput
                containerClassName="search__box"
                placeholder="Tìm theo tên"
                className="search__input"
                type="text"
                onChange={(e: any) => {
                  onSearch(e.target.value);
                }}
                prevIcon={<SearchIcon />}
              />
              <ShareSelectInput
                onChange={(value) => {
                  onChangeWarehouse(value);
                }}
                containerClassName="filter-wrapper"
                placeholder="Chọn kho"
                data={productFilterData.warehouses}
              />
              <ShareSelectInput
                onChange={onChangeCategory}
                containerClassName="filter-wrapper"
                placeholder="Chọn danh mục cha"
                data={productFilterData.categories}
              />
              <ShareSelectInput
                onChange={onChangeCategory}
                containerClassName="filter-wrapper"
                placeholder="Chọn danh mục con"
                data={[
                  {
                    label: 'Tất cả',
                    value: 'null',
                    item: DEFAULT_CATEGORY_FILTER_ITEM,
                  },
                  ...childCategory,
                ]}
              />
            </div>
            <div className="product-block">
              <div className="list-wrapper">
                <div className="flex justify-between items-center">
                  <h3 className="text-center">Sản phẩm khuyến mãi</h3>
                  <div className="flex flex-row items-center px-3">
                    <input
                      onChange={onChangeFile}
                      type="file"
                      id="custom-input"
                      hidden
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <label
                      htmlFor="custom-input"
                      className="block text-sm mr-4 py-2 px-4
            rounded-md border-0 text-sm font-semibold
             cursor-pointer bg text-white"
                    >
                      Chọn file sản phẩm
                    </label>
                    <label className="text-sm mr-4">{selectedFile?.name ?? 'Chưa có file'}</label>
                    <SharedButton
                      disabled={selectedFile === null}
                      onClick={onUploadFile}
                      textColor="white"
                      className="bg py-2 px-4"
                      text={'Thêm sản phẩm'}
                    />
                  </div>
                </div>
                <div id="scrollableDiv1" className="list">
                  <InfiniteScroll
                    dataLength={promotionalProducts.content.length}
                    next={() => {
                      loadMoreData('promotion');
                    }}
                    hasMore={promotionalProducts.content.length < promotionalProducts.amount}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={
                      <Divider plain>Đã tải {promotionalProducts.content.length} sản phẩm</Divider>
                    }
                    scrollableTarget="scrollableDiv1"
                  >
                    <List
                      dataSource={promotionalProducts.content}
                      renderItem={(item) => (
                        <List.Item key={item.product_id}>
                          <div className="item-list">
                            <div className="left-block">
                              <SharedImage
                                containerClassName="img-ctn"
                                src={item?.image?.url || ''}
                              />
                              <div className="item-info">
                                <span className="product-name">{item.product_name}</span>
                                <span className="warehouse-text">Kho: {item.warehouse_name}</span>
                              </div>
                            </div>
                            <div className="right-block">
                              <SharedButton
                                onClick={() => {
                                  onRemovePromotionalProduct(item);
                                }}
                                className="btn"
                                backgroundColor={MAIN_THEME_DATA.mainColor}
                                text="Gỡ khỏi khuyến mãi"
                                textColor="white"
                              />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </div>
              <div className="list-wrapper">
                <div className="flex justify-between items-center">
                  <SharedButton
                    onClick={onSelectAllProduct}
                    textColor="white"
                    className="bg py-2 px-4"
                    text={'Chọn tất cả sản phẩm'}
                  />
                  <h3 className="text-center">Toàn bộ sản phẩm</h3>
                  <SharedButton
                    textColor="white"
                    className="bg py-2 px-4"
                    text={'Thêm sản phẩm đã chọn'}
                    onClick={onAddAllProduct}
                  />
                </div>
                <div id="scrollableDiv" className="list border-left-0">
                  <InfiniteScroll
                    dataLength={productData.products.length}
                    next={() => {
                      loadMoreData('product');
                    }}
                    hasMore={productData.products.length < productData.amount}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={
                      <Divider plain>Đã tải {productData.products.length} sản phẩm</Divider>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={filteredProduct}
                      renderItem={(item, index) => (
                        <List.Item key={item.id}>
                          <div className="item-list">
                            <div className="left-block">
                              <div className="flex">
                                <SharedCheckbox
                                  onChange={() => {
                                    onPickProduct(index);
                                  }}
                                  value={item.isSelected}
                                />
                                <SharedImage
                                  containerClassName="img-ctn"
                                  src={item.images?.[0]?.url}
                                />
                              </div>
                              <div className="item-info">
                                <span className="product-name">{item.name}</span>
                                <span className="warehouse-text">Kho: {item.warehouse.name}</span>
                              </div>
                            </div>
                            <div className="right-block">
                              <SharedButton
                                onClick={() => {
                                  onSelectProduct(item);
                                }}
                                className="btn"
                                backgroundColor={MAIN_THEME_DATA.mainColor}
                                text="Thêm vào khuyến mãi"
                                textColor="white"
                              />
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <SharedButton
                onClick={onConfirm}
                className="btn-save"
                textColor="white"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                text={'Cập nhật'}
              />
            </div>
            <IConCancel onClick={onClose} className="cancel" />
          </form>
        </AppModal>
      )}
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div`
  .input-wrapper {
    margin-top: 1rem;
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination-bar {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .modal {
    width: 150rem;
    position: relative;
    .search__box {
      width: 25%;
      .search__input {
        height: 4.5rem;
      }
    }
    .filter-block {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      .filter-wrapper {
        margin-left: 1rem;
        width: 25%;
        .ant-select-selector {
          height: 4.5rem !important;
        }
      }
    }
    .cancel {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    .product-block {
      margin-top: 1rem;
      .border-left-0 {
        border-left: 0;
      }
      .list-wrapper {
        min-width: 50%;
        .bg {
          background-color: ${MAIN_THEME_DATA.mainColor};
        }
        .list {
          height: 50rem;
          margin-top: 2rem;
          overflow: auto;
          padding: 0 1.6rem;
          width: 100%;
          border: 1px solid rgba(140, 140, 140, 0.35);
          .item-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .right-block {
              display: flex;
              .btn {
                padding: 1rem 1.2rem;
              }
            }
            .left-block {
              display: flex;
            }
            .price {
              display: inline-block;
              margin-right: 1rem;
              font-size: 1.7rem;
            }
            .item-info {
              margin-left: 1rem;
              display: flex;
              flex-direction: column;
              .product-name {
                font-size: 1.7rem;
                margin-bottom: 1rem;
              }
              .classification-wrapper {
                display: flex;
                align-items: center;
                .classification-block {
                  display: flex;
                  margin-left: 1rem;
                }
              }
            }
            .img-ctn {
              width: 9.6rem;
              aspect-ratio: 1;
              border-radius: 8px;
              img {
                border-radius: 8px;
              }
            }
          }
        }
      }

      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .submit-btn {
        padding: 0.8rem 1.2rem;
        height: fit-content;
        align-self: center;
      }
      .inp-wrapper {
        width: 40%;
      }
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .search-tier__box {
    min-width: 20rem;
    width: fit-content;
    .search-tier__input {
      height: 4.5rem;
    }
  }
  .create-tier__button {
    height: 4.5rem;
    width: fit-content;
  }
`;
