import { FilterPayload, PayDebtPayload } from '@interfaces';
import { ApiClient } from './axiosClient';

export const dashboardAPI = {
  getDebtStatistics: (payload: FilterPayload) => {
    const url = 'admin/statistics/debt';
    return ApiClient.post(url, payload);
  },
  getProductStatistics: (payload: FilterPayload) => {
    const url = 'admin/statistics/product';
    return ApiClient.post(url, payload);
  },
  getProductItemStatistics: (id: string, from_time?: string | null, to_time?: string | null) => {
    const url = `/admin/statistics/product/${id}/item?from_time=${from_time}&to_time=${to_time}`;
    return ApiClient.get(url);
  },
  getDebtOrders: (customer_id: string) => {
    const url = `admin/statistics/order-debt?customer=${customer_id}`;
    return ApiClient.get(url);
  },
  payDebtOrders: (payload: PayDebtPayload) => {
    const url = `admin/payment`;
    return ApiClient.post(url, payload);
  },
  getTotalStatistic: (payload: FilterPayload) => {
    const url = `admin/statistics/debt-overall`;
    return ApiClient.post(url, payload);
  },
};
