import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultApiParams, memberAPI, warehouseAPI } from '@api';
import { CustomerListSection } from '@components';
import { INITIAL_PAGE, MAX_ITEM_PER_PAGE } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccountStatus,
  AddressItem,
  AutocompleteItem,
  Customer,
  CustomerColumn,
  CustomerData,
  CustomerLevel,
  CustomerRoot,
  DistrictRoot,
  FilterDataItem,
  ProvinceRoot,
  RootResponse,
  SellerAutocompleteData,
  SellerItem,
  SellerRoot,
  UpdateAccountStatusPayload,
  UpdateCustomerPayload,
  WardRoot,
  WarehouseItem,
  WarehouseRoot,
} from '@interfaces';
import {
  selectApp,
  selectAuth,
  setCurrentPage,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, convertPriceToFloat, convertPriceToInt, useFilters } from '@utils';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
const rejectedSchema = yup.object().shape({
  reason: yup.string().required('Vui lòng nhập lí do'),
});
const acceptedSchema = yup.object().shape({
  code: yup.string().required('Vui lòng nhập mã khách hàng'),
});
const DEFAULT_MODAL_DATA = {
  opened: false,
  currentId: '',
};
const DEFAULT_EXTRA_INPUT = {
  status: AccountStatus.ACCEPTED,
  shown: false,
};
export const CustomersModule = () => {
  const [showExtraInput, setShowExtraInput] = useImmer(DEFAULT_EXTRA_INPUT);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      showExtraInput.status === AccountStatus.ACCEPTED ? acceptedSchema : rejectedSchema,
    ),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(selectAuth);
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const [tierFilterData, setTierFilterData] = useState<FilterDataItem[]>([]);
  const [sellerData, setSellerData] = useState<AutocompleteItem<SellerItem>[]>([]);
  const [modalData, setModalData] = useImmer(DEFAULT_MODAL_DATA);
  const [currentCustomer, setCurrentCustomer] = useState<Customer>({
    id: '',
    bonus_coin: 0,
    status: 0,
    //@ts-ignore
    seller: {},
    username: '',
    full_name: '',
    phone_number: '',
    email: '',
    dob: '',
    code: '',
    //@ts-ignore
    customer_level: {},
  });
  const [members, setMembers] = useImmer<CustomerData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [filters, setFilters] = useImmer<{
    customerLevels: AutocompleteItem<CustomerLevel>[];
  }>({
    customerLevels: [],
  });
  const [payload, setPayload] = useImmer<DefaultApiParams>({
    page: currentPage || 0,
    customer_type_id: null,
    keyword: null,
  });
  const [autocompleteData, setAutoCompleteData] = useImmer<SellerAutocompleteData>({
    provinces: [],
    districts: [],
    wards: [],
    warehouses: [],
  });
  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value === '' ? null : value.trim(), page: INITIAL_PAGE });
  }, 500);
  const onChangeAccountStatus = async (id: string, payload: UpdateAccountStatusPayload) => {
    try {
      dispatch(setLoading(true));
      const res = await memberAPI.changeAccountStatus(id, payload);
      await getMembers();
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onViewProfile = async (item: Customer) => {
    try {
      dispatch(setLoading(true));
      const { image, full_name, phone_number, address, seller, status, customer_level, dob } = item;
      setCurrentCustomer(item);
      reset({
        avatar: image?.url,
        full_name,
        phone_number,
        province: address?.provinces.province_name,
        district: address?.districts.district_name,
        ward: address?.wards.ward_name,
        address_detail: address?.detail,
        seller_name: seller.brand_name,
        status,
        reason: '',
        code: '',
        image_id: image?.id,
        dob,
        seller_id: seller.id,
        address_id: address?.id,
      });
      await onSelectProvince(address?.provinces.code ?? '');
      await onSelectDistrict(address?.districts.code ?? '');
      onSelectWard(address?.wards.code ?? '');
      setValue('customer_level', {
        value: item.customer_level.name,
        label: item.customer_level.name,
        item: item.customer_level,
      });
      setValue('seller', {
        value: item.seller.brand_name,
        label: item.seller.brand_name,
        item: {
          id: item.seller.id,
          name: item.seller.brand_name,
          phone_number: item.seller.name,
        },
      });
      setModalData((draft) => {
        draft.currentId = item.id;
        draft.opened = true;
      });
      setShowExtraInput(DEFAULT_EXTRA_INPUT);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onModalInteract = (value: boolean) => {
    setModalData((draft) => {
      draft.opened = value;
    });
  };
  const onClose = () => {
    setModalData(DEFAULT_MODAL_DATA);
    setShowExtraInput((draft) => {
      draft.shown = false;
    });
  };
  const getMembers = async () => {
    try {
      dispatch(setTableLoading(true));
      const res: CustomerRoot = await memberAPI.getCustomers({
        ...payload,
        keyword: payload?.keyword?.trim(),
      });
      const members = res.data?.customers;
      const memberList: CustomerColumn[] = members?.map((item, index) => {
        return {
          no: index + 1,
          key: item.id,
          full_name: item.full_name,
          phone_number: item.phone_number,
          seller_name: item.seller.brand_name,
          email: item.email,
          level: item.customer_level.name,
          dob: item.dob,
          status: item.status,
          code: item?.code || '',
          bonus_coin: item.bonus_coin,
          onEdit: () => {
            onViewProfile(item);
          },
          onChangeStatus: () => {
            onChangeAccountStatus(item.id, {
              status:
                item.status === AccountStatus.ACCEPTED
                  ? AccountStatus.LOCKED
                  : AccountStatus.ACCEPTED,
              code: item.code,
            });
          },
        };
      });
      setMembers({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.count,
        data: memberList,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
    dispatch(setCurrentPage(Number(page)));
  };
  const onDeny = async () => {
    if (getValues('status') === AccountStatus.ACCEPTED) {
      await onChangeAccountStatus(modalData.currentId, { status: AccountStatus.LOCKED });
      onModalInteract(false);
    } else
      setShowExtraInput((draft) => {
        draft.shown = true;
        draft.status = AccountStatus.REJECTED;
      });
  };
  const onAccept = async () => {
    if (getValues('status') === AccountStatus.LOCKED) {
      await onChangeAccountStatus(modalData.currentId, { status: AccountStatus.ACCEPTED });
      onModalInteract(false);
    } else
      setShowExtraInput((draft) => {
        draft.shown = true;
        draft.status = AccountStatus.ACCEPTED;
      });
  };
  const getValidMoneyBeginningPeriod = () => {
    if (
      !getValues('total_money_debt') &&
      !getValues('total_money_revenue') &&
      !getValues('total_money_reward')
    )
      return null;
    else
      return {
        total_money_debt: isValidValue('total_money_debt'),
        total_money_revenue: isValidValue('total_money_revenue'),
        total_money_reward: isValidValue('total_money_reward'),
      };
  };
  const onSubmit = handleSubmit(async (data) => {
    const { reason, code } = data;
    const rejectedPayload: UpdateAccountStatusPayload = {
      status: AccountStatus.REJECTED,
      reason,
      code: null,
      money_beginning_period: null,
    };
    const acceptedPayload: UpdateAccountStatusPayload = {
      status: AccountStatus.ACCEPTED,
      reason: null,
      code,
      money_beginning_period: getValidMoneyBeginningPeriod(),
    };
    try {
      dispatch(setLoading(true));
      await onChangeAccountStatus(
        modalData.currentId,
        showExtraInput.status === AccountStatus.ACCEPTED ? acceptedPayload : rejectedPayload,
      );
      // onModalInteract(false);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      await getMembers();
    } catch (err: any) {
      LogApp('catch');
    } finally {
      dispatch(setLoading(false));
    }
  });
  const getDropdownData = async () => {
    try {
      const customerLevels: RootResponse<CustomerLevel[]> = await memberAPI.getCustomerLevels();
      setFilters((draft) => {
        draft.customerLevels = customerLevels.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const onSelectCustomerType = (value: AutocompleteItem<CustomerLevel>) => {
    setPayload((d) => {
      if (value.value) d.customer_type_id = value.item.id;
      else d.customer_type_id = value.value;
    });
  };
  const onUpdateCustomer = async () => {
    try {
      dispatch(setLoading(true));
      const customerLevel = getValues('customer_level') as AutocompleteItem<CustomerLevel>;
      const seller = getValues('seller') as AutocompleteItem<SellerItem>;
      const payload: UpdateCustomerPayload = {
        customer_type_id: customerLevel.item.id,
        seller_id: seller.item.id,
        full_name: getValues('full_name'),
        phone_number: getValues('phone_number'),
        dob: getValues('dob'),
        image_id: getValues('image_id'),
        address: {
          id: getValues('address_id'),
          province_code: getValues('province_code'),
          district_code: getValues('district_code'),
          ward_code: getValues('ward_code'),
          detail: getValues('address_detail'),
        },
      };
      const res = await memberAPI.updateCustomer(modalData.currentId, payload);
      await getMembers();
      // onModalInteract(false);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const updateCustomerLevel = async () => {
    if (!getValues('customer_level')) {
      setError('customer_level', { message: 'Vui lòng chọn nhóm khách hàng' });
      return;
    }
    if (!getValues('customer_level')) {
      setError('customer_level', { message: 'Vui lòng chọn nhóm khách hàng' });
      return;
    }
    await onUpdateCustomer();
  };
  const updateCustomerInfo = async () => {
    // LogApp(getValues('province_code'),getValues('district_code'),getValues('ward_code'),'keke')
    if (!getValues('province_code')) {
      setError('province_code', { message: 'Vui lòng chọn Tỉnh/thành phố' });
      return;
    }
    if (!getValues('district_code')) {
      setError('district_code', { message: 'Vui lòng chọn Quận/huyện' });
      return;
    }
    if (!getValues('ward_code')) {
      setError('ward_code', { message: 'Vui lòng chọn Phường/xã' });
      return;
    }
    await onUpdateCustomer();
  };
  const isValidValue = (key: string) => {
    if (getValues(key)) return convertPriceToFloat(getValues(key));
    return 0;
  };
  const updateMoneyBeginningPeriod = async () => {
    try {
      dispatch(setLoading(true));
      const payload: UpdateAccountStatusPayload = {
        money_beginning_period: {
          total_money_debt: isValidValue('total_money_debt'),
          total_money_revenue: isValidValue('total_money_revenue'),
          total_money_reward: isValidValue('total_money_reward'),
        },
      };
      const res = await memberAPI.changeAccountStatus(modalData.currentId, payload);
      await getMembers();
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onChangeCustomerType = (v: AutocompleteItem<CustomerLevel>) => {
    if (v.value) setValue('customer_level', v);
    else setValue('customer_level', '');
  };
  const onSellerChange = (v: AutocompleteItem<SellerItem>) => {
    if (v.value) setValue('seller', v);
    else setValue('seller', '');
  };
  const getSellerDropdown = async () => {
    try {
      const sellers: RootResponse<SellerItem[]> = await memberAPI.getSellerItems();
      const data = sellers.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          item,
        };
      });
      setSellerData(data);
    } catch (error) {}
  };
  const onSelectAddress = (value: string, key: 'province_code' | 'district_code' | 'ward_code') => {
    setValue(key, value);
  };
  const onSelectProvince = async (value: string) => {
    try {
      onSelectAddress(value, 'province_code');
      const res: DistrictRoot = await warehouseAPI.getDistricts(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.districts.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.districts = optionValues;
      });
    } catch (error) {}
  };
  const onSelectDistrict = async (value: string) => {
    try {
      onSelectAddress(value, 'district_code');
      const res: WardRoot = await warehouseAPI.getWards(value);
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.wards.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      setAutoCompleteData((draft) => {
        draft.wards = optionValues;
      });
    } catch (error) {}
  };
  const onSelectWard = (value: string) => {
    onSelectAddress(value, 'ward_code');
  };
  const getAutocompleteData = async () => {
    try {
      dispatch(setLoading(true));
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses();
      const res: ProvinceRoot = await warehouseAPI.getProvinces();
      const optionValues: AutocompleteItem<AddressItem>[] = res.data.provinces.map((item) => {
        return {
          label: item.name,
          value: item.name,
          item,
        };
      });
      const warehouseOption: AutocompleteItem<WarehouseItem>[] =
        warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.name,
            item,
          };
        });
      setAutoCompleteData((draft) => {
        draft.provinces = optionValues;
        draft.warehouses = warehouseOption;
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getMembers();
  }, [payload]);
  useEffect(() => {
    getDropdownData();
    getSellerDropdown();
    getAutocompleteData();
  }, []);
  return (
    <CustomerListSection
      onDeny={onDeny}
      onAccept={onAccept}
      register={register}
      errors={errors}
      getValues={getValues}
      handleSubmit={onSubmit}
      onSearch={onSearch}
      memberData={members}
      tierFilterData={tierFilterData}
      currentPage={payload?.page || 0}
      onPageChange={handleChangePage}
      modalData={modalData}
      onClose={onClose}
      showExtraInput={showExtraInput}
      filters={filters}
      onSelectCustomerType={onSelectCustomerType}
      onChangeCustomerType={onChangeCustomerType}
      updateCustomerLevel={updateCustomerLevel}
      updateMoneyBeginningPeriod={updateMoneyBeginningPeriod}
      control={control}
      sellerData={sellerData}
      onSellerChange={onSellerChange}
      onSelectProvince={onSelectProvince}
      onSelectDistrict={onSelectDistrict}
      onSelectWard={onSelectWard}
      autocompleteData={autocompleteData}
      customer={currentCustomer}
      updateCustomerInfo={updateCustomerInfo}
    />
  );
};
