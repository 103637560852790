import { ShareInput } from '@components';
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { styled } from 'styled-components';
interface SellerInfoProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}
export const SellerInfo = (props: SellerInfoProps) => {
  const { control, register, setValue, getValues } = props;
  return (
    <StyledSellerInfo>
      <div className="info-block">
        <div className="input-address-wrapper">
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="seller_name"
            label="Tên người bán hàng"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="seller_phone"
            label="Số điện thoại"
            disabled
          />
        </div>
      </div>
    </StyledSellerInfo>
  );
};
const StyledSellerInfo = styled.div`
  .inner-input {
    font-weight: 500;
  }
  .input-mr {
    margin-right: 1rem;
  }
  .m-top-2 {
    margin-top: 2rem;
  }
  .code-block {
    display: flex;
    .inp-wrapper {
      width: 50%;
    }
  }

  .info-block {
    .input-address-wrapper {
      display: flex;
      .inp-wrapper {
        width: 50%;
        margin-right: 2rem;
      }
    }
  }
`;
