import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { categoriesAPI, productAPI, warehouseAPI } from '@api';
import { ProductForm } from '@components';
import { PATH_PRODUCT } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AttributeGroupData,
  AttributeItem,
  AttributeParentKey,
  AutocompleteItem,
  Category,
  CategoryListRoot,
  Classification,
  CreateProductAttributeRoot,
  DropdownProps,
  ProductAttribute,
  ProductAttributeRoot,
  ProductAutocompleteData,
  ProductDetailRoot,
  ProductImage,
  ProductImageRoot,
  ProductInputType,
  ProductItem,
  Promotion,
  SubCategory,
  WarehouseItem,
  WarehouseRoot,
} from '@interfaces';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';
import {
  LogApp,
  convertPriceToFloat,
  convertPriceToInt,
  generateRandomId,
  parsePercent,
} from '@utils';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
export const PARENT_ATTR_POST_FIX = 'parent_attr';
export const CHILD_ATTR_POST_FIX = 'child_attr';
const COLOR_ATTR_ID = '95cd46bb-0a07-44c1-ad83-fe30f75f1708';
const SIZE_ATTR_ID = 'b32d65a7-c18d-488a-ac80-a673b239fb20';
const DEFAULT_DROPDOWN = [
  {
    value: '95cd46bb-0a07-44c1-ad83-fe30f75f1708',
    label: 'Màu Sắc',
    disabled: false,
  },
  {
    value: 'b32d65a7-c18d-488a-ac80-a673b239fb20',
    label: 'Size',
    disabled: false,
  },
];
const IMAGE_UPLOAD_LIMIT = 15;
const DEFAULT_PRODUCT_IMAGES = Array.from({ length: IMAGE_UPLOAD_LIMIT }).map((_, index) => ({
  index: index + 1,
  id: generateRandomId(),
  key: generateRandomId(),
  file: null,
  url: '',
  name: '',
}));
const productFormSchema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên sản phẩm'),
  origin: yup.string().required('Vui lòng nhập xuất xứ'),
  unit: yup.string().required('Vui lòng nhập đơn vị'),
  category_ids: yup.string().required('Vui lòng chọn danh mục'),
  type: yup.string().required('Vui lòng nhập loại hàng'),
  // warehouse_id: yup.string().required('Vui lòng chọn kho'),
  // warehouse_ids: yup.array()
  //   .required('Vui lòng chọn kho')
  //   .min(1, 'Vui lòng chọn kho'),
});
const createProductFormSchema = yup.object().shape({
  ...productFormSchema.fields,
  warehouse_ids: yup.array().required('Vui lòng chọn kho').min(1, 'Vui lòng chọn kho'),
});
const noAttrsProductSchema = yup.object().shape({
  buying_price: yup.string().required('Vui lòng nhập giá mua').nullable(),
  selling_price: yup.string().required('Vui lòng nhập giá bán').nullable(),
  quantity: yup.string().required('Vui lòng nhập số lượng').nullable(),
  code: yup.string().required('Vui lòng nhập mã sản phẩm').nullable(),
});
const mergedSchema = yup.object().shape({
  ...noAttrsProductSchema.fields,
  ...productFormSchema.fields,
});
const createMergedSchema = yup.object().shape({
  ...mergedSchema.fields,
  warehouse_ids: yup.array().required('Vui lòng chọn kho').min(1, 'Vui lòng chọn kho'),
});
export const CreateProductModule = ({ isEdit }: { isEdit: boolean }) => {
  const [attrGroupData, setAttrGroupData] = useImmer<AttributeGroupData[]>([]);
  const schemas = {
    edit: {
      mergedSchema,
      productFormSchema,
    },
    create: {
      createMergedSchema,
      createProductFormSchema,
    },
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    // mode: 'onChange',
    resolver: yupResolver(
      !attrGroupData.length
        ? isEdit //handle truong hop khong co phan loai
          ? schemas.edit.mergedSchema //neu la sua san pham thi validate cho chon 1 kho
          : schemas.create.createMergedSchema //neu la them san pham thi validate cho chon nhieu kho
        : isEdit //truong hop co phan loai
        ? schemas.edit.productFormSchema //neu la sua san pham thi validate cho chon 1 kho
        : schemas.create.createProductFormSchema, //neu la them san pham thi validate cho chon nhieu kho
    ),
  });
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { themeMode } = useAppSelector(selectApp);
  const editorRef = useRef<any>(null);
  const { id } = useParams();
  const uploadImageRef = useRef<any>(null);
  const imageRef = useRef<ProductImage[]>([]);
  const deleteImages = useRef<string[]>([]);
  const [productImages, setProductImages] = useImmer<ProductImage[]>(DEFAULT_PRODUCT_IMAGES);
  const [selectedImage, setSelectedImage] = useState();
  const [tiers, setTiers] = useState<DropdownProps>([]);
  const [selectedTiers, setSelectedTiers] = useState<number[]>([]);
  const [htmlDes, setHtmlDes] = useState(EditorState.createEmpty());
  const [attributeList, setAttributeList] = useImmer<ProductAttribute[]>([]);
  const [promotions, setPromotions] = useImmer<Promotion[]>([]);
  const [autocompleteData, setAutocompleteData] = useImmer<ProductAutocompleteData>({
    categories: [],
    warehouses: [],
    attributes: [],
  });
  const [selectedCategory, setSelectedCategory] = useState<AutocompleteItem<Category>>({
    value: '',
    label: '',
    item: {
      id: '',
      name: '',
      sub_categories: [],
    },
  });
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory>({
    id: '',
    name: '',
    sub_categories: [],
  });

  const [opened, setOpened] = useState(false);
  const [attrOpened, setAttrOpened] = useState(false);
  const getChildAttrs = (): AttributeItem[] => {
    const attrItems: AttributeItem[] = [];
    const foundParentIndex = attrGroupData.findIndex((value) => value.key === 'parent');
    const foundChildIndex = attrGroupData.findIndex((value) => value.key === 'child');
    const parentAttrs = attrGroupData[foundParentIndex]?.data;
    const childAttrs = attrGroupData[foundChildIndex]?.data;
    if (foundChildIndex !== -1)
      parentAttrs.forEach((item) => {
        childAttrs.forEach((val, index) => {
          const childAttr: AttributeItem = {
            id: generateRandomId(),
            // name: `${CHILD_ATTR_POST_FIX}_${index + 1}`,
            name: `${val.name}`,
          };
          attrItems.push(childAttr);
        });
      });
    return attrItems;
  };
  const getProductInputs = (
    attrGroupData: AttributeGroupData[],
    type: ProductInputType,
  ): AttributeItem[] => {
    const attrItems: AttributeItem[] = [];
    const foundParentIndex = attrGroupData.findIndex((value) => value.key === 'parent');
    const foundChildIndex = attrGroupData.findIndex((value) => value.key === 'child');
    if (foundChildIndex !== -1)
      attrGroupData[foundParentIndex].data.forEach((item) => {
        attrGroupData[foundChildIndex].data.forEach((val, index) => {
          const childAttr: AttributeItem = {
            id: generateRandomId(),
            name: `${type}_${item.name}_${val.name}`,
          };
          attrItems.push(childAttr);
        });
      });
    else if (foundParentIndex !== -1)
      attrGroupData[foundParentIndex].data.forEach((item) => {
        const childAttr: AttributeItem = {
          id: generateRandomId(),
          name: `${type}_${item.name}`,
        };
        attrItems.push(childAttr);
      });
    return attrItems;
  };
  const removeAttributeItem = (item: AttributeItem, key: AttributeParentKey) => {
    if (key === 'child') removeChildAttribute(item);
    else removeParentAttribute(item);
  };
  const addAttributeItem = (key: AttributeParentKey) => {
    if (key === 'child') addChildAttribute();
    else addParentAttribute();
  };
  const addParentAttribute = () => {
    setAttrGroupData((d) => {
      const foundParentIndex = d.findIndex((value) => value.key === 'parent');
      const parentAttrs = d[foundParentIndex].data;
      const lastIndex = getLastIndex(parentAttrs);
      const name = `${PARENT_ATTR_POST_FIX}_${parseInt(lastIndex) + 1}`;
      setValue(name, '');
      // d[foundParentIndex].images?.push({
      //   id: generateRandomId(),
      //   url: '',
      //   name: '',
      //   index: 0,
      //   file: null
      // })
      parentAttrs.push({
        id: generateRandomId(),
        name,
        server_id: null,
        image: { id: '', key: generateRandomId(), file: null, index: 0, name: '', url: '' },
      });
      resetInput(d);
    });
  };
  const addChildAttribute = () => {
    setAttrGroupData((d) => {
      const foundChildIndex = d.findIndex((value) => value.key === 'child');
      const childAttrs = d[foundChildIndex].data;
      const lastIndex = getLastIndex(childAttrs);
      const name = `${CHILD_ATTR_POST_FIX}_${parseInt(lastIndex) + 1}`;
      setValue(name, '');
      childAttrs.push({
        id: generateRandomId(),
        name,
        server_id: null,
      });
      resetInput(d);
    });
  };
  const removeParentAttribute = (parentItem: AttributeItem) => {
    setAttrGroupData((d) => {
      const foundParentIndex = d.findIndex((value) => value.key === 'parent');
      const item = d[foundParentIndex].data;
      const itemIndex = item.findIndex((value) => value.id === parentItem.id);
      if (foundParentIndex !== -1 && itemIndex !== -1) {
        item.splice(itemIndex, 1);
      }
    });
  };
  const getLastIndex = (items: AttributeItem[]) => {
    const lastItemName = items[items.length - 1].name;
    const match = lastItemName.match(/\d+/);
    return match?.[0] || '0';
    // return lastItemName.substring(lastItemName.length - 1, lastItemName.length);
  };
  const removeChildAttribute = (childItem: AttributeItem) => {
    setAttrGroupData((d) => {
      const foundChildIndex = d.findIndex((value) => value.key === 'child');
      const item = d[foundChildIndex].data;
      const itemIndex = d[foundChildIndex].data.findIndex((value) => value.id === childItem.id);
      LogApp(childItem, 'childItem');
      if (foundChildIndex !== -1 && itemIndex !== -1) {
        item.splice(itemIndex, 1);
      }
    });
  };
  const getAttributeList = async () => {
    try {
      const res: ProductAttributeRoot = await productAPI.getAttributes();
    } catch (error) {}
  };

  const resetInput = (attrGroupData: AttributeGroupData[]) => {
    //reset gia tri cua input khi them 1 attr hoac 1 attr group
    const spInputs = getProductInputs(attrGroupData, 'sp');
    const bpInputs = getProductInputs(attrGroupData, 'bp');
    const qInputs = getProductInputs(attrGroupData, 'quantity');
    const rInputs = getProductInputs(attrGroupData, 'reward');
    if (process.env.NODE_ENV === 'development' && !isEdit) {
      const cInputs = getProductInputs(attrGroupData, 'code');
      cInputs.forEach((item) => {
        //update lai du lieu
        if (!getValues(item.name)) setValue(item.name, generateRandomId(8));
      });
    }
    spInputs.forEach((item) => {
      //update lai du lieu
      if (!getValues(item.name)) setValue(item.name, getValues('selling_price'));
    });
    bpInputs.forEach((item) => {
      if (!getValues(item.name)) setValue(item.name, getValues('buying_price'));
    });
    qInputs.forEach((item) => {
      if (!getValues(item.name)) setValue(item.name, getValues('quantity'));
    });
    rInputs.forEach((item) => {
      if (!getValues(item.name)) setValue(item.name, getValues('reward'));
    });
  };
  const removeAttributeGroup = (key: AttributeParentKey, item: AttributeGroupData) => {
    if (key === 'parent') {
      setAttrGroupData((draft) => {
        const foundChildIndex = draft.findIndex((val) => val.key === 'child');
        const foundParentIndex = draft.findIndex((value) => value.key === 'parent');
        if (foundChildIndex !== -1) {
          const childAttrs = draft[foundChildIndex].data;
          const parentAttrs = draft[foundParentIndex].data;
          const parentKey = draft[foundParentIndex].key;
          const childKey = draft[foundChildIndex].key;
          setValue(parentKey, getValues(childKey));
          let childIndex = childAttrs.length - 1;
          let parentIndex = parentAttrs.length - 1;

          while (childIndex >= 0 && parentIndex >= 0) {
            // swap tung gia tri cua thuoc tinh
            const childName = childAttrs[childIndex].name;
            const parentName = parentAttrs[parentIndex].name;
            setValue(parentName, getValues(childName));
            childIndex--;
            parentIndex--;
          }
          draft[foundChildIndex] = {
            key: 'parent',
            data: draft[foundChildIndex].data.map((val) => {
              return {
                ...val,
                name: val.name.replace(CHILD_ATTR_POST_FIX, PARENT_ATTR_POST_FIX),
              };
            }),
            dropdown: draft[foundParentIndex].dropdown,
          }; //neu xoa parent thi gan parent cho child
        }
        if (foundParentIndex !== -1) draft.splice(foundParentIndex, 1); //sau do xoa parent
        resetInput(draft);
      });
    } else {
      setAttrGroupData((draft) => {
        const foundChildIndex = draft.findIndex((item) => item.key === 'child');
        if (foundChildIndex !== -1) {
          draft.splice(foundChildIndex, 1); //xoa child khong can gan lai cho parent
        }
        resetInput(draft);
      });
    }
  };
  const addAttributeGroup = () => {
    if (!attrGroupData.length) {
      setAttrGroupData((draft) => {
        draft.push({
          key: 'parent',
          data: [
            {
              id: generateRandomId(),
              name: `${PARENT_ATTR_POST_FIX}_1`,
              server_id: null,
              image: { id: '', key: generateRandomId(), file: null, index: 0, name: '', url: '' },
            },
          ],
          dropdown: DEFAULT_DROPDOWN,
        });
      });
    } else {
      setAttrGroupData((draft) => {
        draft.push({
          key: 'child',
          data: [{ id: generateRandomId(), name: `${CHILD_ATTR_POST_FIX}_1`, server_id: null }],
          dropdown: DEFAULT_DROPDOWN.filter((item) => item.value !== getValues('parent')),
        });
        const foundChildIndex = draft.findIndex((item) => item.key === 'child');
        const childAttrs = draft[foundChildIndex].data;
        setValue('child', '');
        childAttrs.forEach((item) => {
          setValue(item.name, '');
        });
        resetInput(draft);
        if (isEdit) {
          const idInputs = getProductInputs(attrGroupData, 'id');
          idInputs.forEach((item, index) => {
            const parentPostFix = `${PARENT_ATTR_POST_FIX}_${index + 1}`;
            setValue(`id_${parentPostFix}`, null);
          });
        }
      });
    }
  };
  const onSelectDropdown = (value: string, key: AttributeParentKey) => {
    if (key === 'parent') {
      setValue(key, value);
      setValue('child', '');
    } else {
      setValue(key, value);
    }
    setAttrGroupData((draft) => {
      const foundIndex = draft.findIndex((item) => item.key !== key);
      if (foundIndex !== -1 && key === 'parent')
        draft[foundIndex].dropdown = DEFAULT_DROPDOWN.filter((item) => item.value !== value);
    });
  };
  const applyAll = () => {
    const sPrice = getValues('selling_price');
    const bPrice = getValues('buying_price');
    const quantity = getValues('quantity');
    const percent = getValues('deduct_percent');
    const reward = getValues('reward');
    const spInputs = getProductInputs(attrGroupData, 'sp');
    const bpInputs = getProductInputs(attrGroupData, 'bp');
    const qInputs = getProductInputs(attrGroupData, 'quantity');
    const rInputs = getProductInputs(attrGroupData, 'reward');
    const pInputs = getProductInputs(attrGroupData, 'deduct_percent');
    spInputs.forEach((item) => {
      if (sPrice) setValue(item.name, sPrice);
    });
    bpInputs.forEach((item) => {
      if (bPrice) setValue(item.name, bPrice);
    });
    qInputs.forEach((item) => {
      if (quantity) setValue(item.name, quantity);
    });
    rInputs.forEach((item) => {
      setValue(item.name, reward || false);
    });
    pInputs.forEach((item) => {
      if (percent) setValue(item.name, percent);
    });
  };
  function checkProductItemValidity(productItem: ProductItem, key: keyof ProductItem) {
    if (key === 'code') return productItem.code !== '';
    if (key === 'selling_price') return productItem.selling_price !== 0;
    if (key === 'import_price') return productItem.import_price !== 0;
    return !Number.isNaN(productItem.quantity);
  }
  const onCheckInputValue = (classifications: Classification[], key: keyof ProductItem) => {
    LogApp(classifications, 'classifications');
    let hasItemWithoutValue = false;
    if (!classifications) return false;
    if (classifications.every((item) => !item.sub_classifications)) {
      const hasCode = classifications.every((item) => {
        if (item.product_item) return checkProductItemValidity(item.product_item, key);
      });
      if (!hasCode) {
        hasItemWithoutValue = true;
        return hasItemWithoutValue;
      }
    } else {
      for (const classification of classifications) {
        const hasCode = classification.sub_classifications.every((subClassification) => {
          return checkProductItemValidity(subClassification.product_item, key);
        });
        if (!hasCode) {
          hasItemWithoutValue = true;
          LogApp(
            `The product with color '${classification.value}' does not have any item with a code.`,
          );
          return hasItemWithoutValue;
        }
      }
    }
    return hasItemWithoutValue;
  };
  const toastError = (msg: string) => {
    toast.error(`${msg}`, {
      position: 'top-right',
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'colored',
    });
  };
  const handleSubmitForm = handleSubmit(async (value) => {
    if (!selectedSubCategory.id) {
      setError('category_ids', { message: 'Vui lòng danh mục con' });
      return;
    }
    if (attrGroupData.length) {
      if (!getValues('parent')) {
        setError('parent', { message: 'Vui lòng chọn thuộc tính cha' });
        return;
      }
      if (attrGroupData.length === 2) {
        if (!getValues('parent')) {
          setError('parent', { message: 'Vui lòng chọn thuộc tính cha' });
          return;
        }
        if (!getValues('child')) {
          setError('child', { message: 'Vui lòng chọn thuộc tính con' });
          return;
        }
      }
    }
    try {
      const { unit, warehouse_ids, description, name, origin, deduct_percent, warehouse_id, type } =
        value;
      const foundParentIndex = attrGroupData.findIndex((value) => value.key === 'parent');
      const foundChildIndex = attrGroupData.findIndex((value) => value.key === 'child');
      const parentAttrs = attrGroupData[foundParentIndex];
      const childAttrs = attrGroupData[foundChildIndex];
      const classifications =
        foundParentIndex !== -1
          ? parentAttrs?.data?.map((item, index) => {
              let childResAttrs: any = [];
              const parentPostFix = `${PARENT_ATTR_POST_FIX}_${index + 1}`;
              if (foundChildIndex !== -1) {
                childResAttrs = childAttrs?.data?.map((val, pos) => {
                  const postFix = `${item.name}_${val.name}`;
                  return {
                    id: getValues(`sub_id_${postFix}`),
                    attribute_id: getValues('child'),
                    value: getValues(val.name?.trim()),
                    index: pos + 1,
                    product_item: {
                      id: getValues(`id_${postFix}`),
                      quantity: convertPriceToInt(getValues(`quantity_${postFix}`)),
                      import_price: convertPriceToFloat(getValues(`bp_${postFix}`)),
                      selling_price: convertPriceToFloat(getValues(`sp_${postFix}`)),
                      code: getValues(`code_${postFix}`),
                      reward: getValues(`reward_${postFix}`) || false,
                      deduct_percent: parsePercent(getValues(`deduct_percent_${postFix}`)),
                    },
                  };
                });
              }
              const res =
                foundChildIndex !== -1
                  ? {
                      id: item.server_id,
                      attribute_id: getValues('parent'),
                      index: index + 1,
                      value: getValues(item.name?.trim()),
                      image_id: item.image?.id,
                      sub_classifications: childResAttrs,
                    }
                  : {
                      id: getValues(`sub_id_${parentPostFix}`),
                      attribute_id: getValues('parent'),
                      value: getValues(item.name?.trim()),
                      index: index + 1,
                      image_id: item.image?.id,
                      product_item: {
                        id: getValues(`id_${parentPostFix}`),
                        quantity: convertPriceToInt(getValues(`quantity_${parentPostFix}`)),
                        import_price: convertPriceToFloat(getValues(`bp_${parentPostFix}`)),
                        selling_price: convertPriceToFloat(getValues(`sp_${parentPostFix}`)),
                        code: getValues(`code_${parentPostFix}`),
                        reward: getValues(`reward_${parentPostFix}`) || false,
                        deduct_percent: parsePercent(getValues(`deduct_percent_${parentPostFix}`)),
                      },
                    };
              return res;
            })
          : undefined;
      const isBuyingPriceInvalid = onCheckInputValue(
        classifications as Classification[],
        'import_price',
      );
      const isSellingPriceInvalid = onCheckInputValue(
        classifications as Classification[],
        'selling_price',
      );
      const isQuantityInvalid = onCheckInputValue(classifications as Classification[], 'quantity');
      const isCodeInvalid = onCheckInputValue(classifications as Classification[], 'code');

      if (isBuyingPriceInvalid) {
        toastError('Vui lòng nhập đầy đủ giá mua sản phẩm');
        return;
      }
      if (isSellingPriceInvalid) {
        toastError(`Vui lòng nhập đầy đủ giá bán sản phẩm`);
        return;
      }
      if (isQuantityInvalid) {
        toastError(`Vui lòng nhập đầy đủ số lượng sản phẩm`);
        return;
      }
      if (isCodeInvalid) {
        toastError(`Vui lòng nhập đầy đủ mã sản phẩm`);
        return;
      }
      // if (productImages.every((item) => item.file === null)) {
      //   toastError(`Vui lòng nhập chọn tối thiểu 1 ảnh`);
      //   return;
      // }
      dispatch(setLoading(true));
      const attributes = attributeList.map((item) => {
        return {
          attribute_id: item.id,
          value: getValues(item.id),
        };
      });
      let images: ProductImage[] = [];
      let imagePayload: any[] = [];
      if (
        productImages.some((item) => item.file !== null) ||
        productImages.some((item) => item.url !== '')
      ) {
        if (isEdit) {
          const changedImages = findChangedItem(imageRef.current, productImages);
          if (changedImages.hasChanged) {
            images = (await uploadProductImages(changedImages.changedItems)).data;
            const rest = [...changedImages.rest];
            const changedItems = [...changedImages.changedItems];
            const uploadedImages = [...images];
            uploadedImages.forEach((item, index) => {
              item.index = changedItems[index].index;
            });
            for (const image of uploadedImages) {
              rest.push(image);
            }
            images = rest;
          } else {
            images = imageRef.current;
          }
        } else {
          images = (await uploadProductImages(productImages)).data;
        }
        imagePayload = [...images]
          .sort((a, b) => {
            return a.index - b.index;
          })
          .map((item, index) => {
            return {
              image_id: item.id,
              index: index + 1,
            };
          })
          .filter((item) => {
            return !deleteImages.current.includes(item.image_id ?? '');
          });
      }
      const basicInfo = {
        category_ids: [selectedSubCategory.id],
        origin: origin?.trim(),
        unit: unit?.trim(),
        description: convertToHTML(editorRef.current?.getCurrentContent()),
        attributes,
        name: name?.trim(),
        id,
        images: imagePayload,
        type: type?.trim(),
      };
      const noSubAttrsPayload = {
        ...basicInfo,
        import_price: convertPriceToFloat(getValues('buying_price')),
        selling_price: convertPriceToFloat(getValues('selling_price')),
        quantity: convertPriceToInt(getValues('quantity')),
        code: getValues('code'),
        reward: getValues('reward') || false,
        deduct_percent: parsePercent(deduct_percent),
      };
      const fullPayload = {
        ...basicInfo,
        category_ids: [selectedSubCategory?.id],
        classifications,
      };
      const payload = foundParentIndex !== -1 ? fullPayload : noSubAttrsPayload;
      const createPayload = { ...payload, warehouse_ids };
      const editPayload = { ...payload, warehouse_id };
      const res = isEdit
        ? await productAPI.updateProduct(id || '', editPayload)
        : await productAPI.createProduct(createPayload);
      toast.success(`${isEdit ? 'Sửa' : 'Tạo'} sản phẩm thành công`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      navigate(PATH_PRODUCT);
    } catch (error) {
      LogApp(error, 'ndh_err');
    } finally {
      dispatch(setLoading(false));
    }
  });
  const getAutocompleteData = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const categories: CategoryListRoot = await categoriesAPI.getCategories({ all: true });
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses();
      const attributes: ProductAttributeRoot = await productAPI.getAttributes();
      const categoryOption: AutocompleteItem<Category>[] = categories.data.categories.map(
        (item) => {
          return {
            label: item.name,
            value: item.name,
            item,
          };
        },
      );
      const warehouseOption: AutocompleteItem<WarehouseItem>[] =
        warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.name,
            item,
          };
        });
      const attributeOption: AutocompleteItem<ProductAttribute>[] = attributes.data.attributes.map(
        (item) => {
          return {
            label: item.name,
            value: item.name,
            item,
          };
        },
      );
      setAttributeList((draft) => {
        const detailAttrs = attributes.data.attributes.filter((item) => item.detail);
        detailAttrs.forEach((item, index) => {
          draft[index] = item;
        });
      });
      setAutocompleteData((draft) => {
        draft.categories = categoryOption;
        draft.warehouses = warehouseOption;
        draft.attributes = attributeOption;
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  }, []);
  const uploadProductImages = async (productImages: ProductImage[]): Promise<ProductImageRoot> => {
    try {
      const images = new FormData();
      productImages.forEach((item) => {
        if (item.file !== null) images.append('files', item.file);
      });
      const res: ProductImageRoot = await productAPI.uploadImages(images);
      return res;
    } catch (error) {
      LogApp(error);
      throw error;
    }
  };
  const changeSelectedImage = (value: any, index: number) => {
    if (value)
      setProductImages((draft) => {
        draft[index].file = value;
        draft[index].id = generateRandomId();
        draft[index].index = index + 1;
        draft[index].name = value.name;
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };
  const onSelectBasicInfo = (value: any, type: 'warehouse' | 'category') => {
    if (type === 'warehouse') {
      if (!isEdit)
        setValue(
          'warehouse_ids',
          value.map((val: any) => val.item.id),
        );
      else setValue('warehouse_id', value.item.id);
    } else
      setValue(
        'category_ids',
        value.map((val: any) => val.item.id),
      );
  };
  const getAllSubClassificationIds = (classifications: Classification[]) => {
    const subClassificationIds: string[] = [];
    classifications.forEach((classification) => {
      const subClassifications = classification.sub_classifications;
      if (!subClassifications.length) {
        subClassificationIds.push(classification.id);
      } else
        subClassifications.forEach((subClassification) => {
          subClassificationIds.push(subClassification.id);
        });
    });

    return subClassificationIds;
  };
  const testRef = useRef(0);
  const getProductDetail = async () => {
    if (!id) return;
    try {
      dispatch(setLoading(true));
      const res: ProductDetailRoot = await productAPI.getProduct(id);
      await getAutocompleteData();
      const {
        name,
        origin,
        unit,
        description,
        categories,
        warehouse,
        attributes,
        classifications,
        images,
        reward,
        type,
        promotions,
      } = res.data;
      setPromotions(promotions);
      if (description) {
        const blocksFromHTML = convertFromHTML(description);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        setHtmlDes(editorState);
      }
      attributes.forEach((item) => {
        reset({
          [item.attribute_id]: item.value,
        });
      });
      if (categories.length) {
        setSelectedCategory({
          item: categories?.[0],
          label: categories?.[0].name,
          value: categories?.[0].name,
        });
        setSelectedSubCategory(categories?.[0]?.sub_categories?.[0]);
      }
      setProductImages((draft) => {
        images.forEach((item, index) => {
          if (draft[index]) {
            draft[index].url = item.url;
            draft[index].id = item.id;
            draft[index].file = {};
            draft[index].index = index + 1;
            draft[index].name = item.url;
          }
        });
        draft.forEach((item, index) => {
          imageRef.current[index] = {
            id: item.id,
            url: item.url,
            file: item.file,
            index: index + 1,
            name: item.url,
          };
        });
      });
      const selling_price = res.data?.selling_price;
      const buying_price = res.data?.import_price;
      const code = res.data?.code;
      const quantity = res.data?.quantity;
      const deduct_percent =
        res.data.deduct_percent > 0 ? parseFloat(res.data.deduct_percent.toString()) : '';
      reset({
        name,
        origin,
        unit,
        description,
        category_ids: categories.length ? buildCategoryHierarchy(categories?.[0]) : [],
        warehouse_id: warehouse.id,
        deduct_percent,
        type,
      });

      const isColorParent =
        classifications.filter((item) => item.attribute_id === COLOR_ATTR_ID).length > 0;
      const haveChildAttr = classifications.some((item) => item.sub_classifications.length > 0);
      const childAttr = classifications?.[0]?.sub_classifications;
      if (classifications.length) {
        setAttrGroupData((draft) => {
          draft.push({
            key: 'parent',
            data: classifications.map((item, index) => {
              return {
                id: item.id,
                server_id: item.id,
                name: `${PARENT_ATTR_POST_FIX}_${index + 1}`,
                image: {
                  id: item.image?.id ?? '',
                  index,
                  url: item.image?.url ?? '',
                  file: null,
                  name: item.image?.url ?? '',
                },
              };
            }),
            dropdown: DEFAULT_DROPDOWN,
          });
          // onSelectDropdown(isColorParent ? COLOR_ATTR_ID : SIZE_ATTR_ID, 'parent'); //neu parent la color thi chon color
          setValue('parent', isColorParent ? COLOR_ATTR_ID : SIZE_ATTR_ID);
          classifications.forEach((item, index) => {
            setValue(`${PARENT_ATTR_POST_FIX}_${index + 1}`, item.value);
          });
          if (haveChildAttr) {
            draft.push({
              key: 'child',
              data: childAttr.map((item, index) => {
                return {
                  id: item.id,
                  server_id: item.id,
                  name: `${CHILD_ATTR_POST_FIX}_${index + 1}`,
                };
              }),
              dropdown: DEFAULT_DROPDOWN.filter((item) => item.value !== getValues('parent')),
            });
            setValue('child', isColorParent ? SIZE_ATTR_ID : COLOR_ATTR_ID);
            // onSelectDropdown(isColorParent ? SIZE_ATTR_ID : COLOR_ATTR_ID, 'child'); // neu parent la color thi chon size
            childAttr.forEach((item, index) => {
              setValue(`${CHILD_ATTR_POST_FIX}_${index + 1}`, item.value);
            });
          }
          const spInputs = getProductInputs(draft, 'sp');
          const bpInputs = getProductInputs(draft, 'bp');
          const qInputs = getProductInputs(draft, 'quantity');
          const cInputs = getProductInputs(draft, 'code');
          const idInputs = getProductInputs(draft, 'id');
          const subIdInputs = getProductInputs(draft, 'sub_id');
          const rInputs = getProductInputs(draft, 'reward');
          const pInputs = getProductInputs(draft, 'deduct_percent');
          const productItems: ProductItem[] = [];
          getAllSubClassificationIds(classifications).forEach((val, i) => {
            setValue(subIdInputs[i].name, val);
          });
          classifications.forEach((item, index) => {
            if (!item.sub_classifications.length) {
              //lay value cua parent
              setValue(spInputs[index].name, item.product_item?.selling_price);
              setValue(bpInputs[index].name, item.product_item?.import_price);
              setValue(qInputs[index].name, item.product_item?.quantity);
              setValue(cInputs[index].name, item.product_item?.code);
              setValue(idInputs[index].name, item.product_item?.id);
              setValue(rInputs[index].name, item.product_item?.reward);
              setValue(pInputs[index].name, item.product_item?.deduct_percent);
            } else {
              item.sub_classifications.forEach((val, i) => {
                testRef.current++;
                productItems.push(val.product_item);
              });
            }
            LogApp(testRef.current);
            if (productItems.length === item.sub_classifications.length * classifications.length) {
              productItems.forEach((item, index) => {
                setValue(spInputs[index].name, item?.selling_price);
                setValue(bpInputs[index].name, item?.import_price);
                setValue(qInputs[index].name, item?.quantity);
                setValue(cInputs[index].name, item?.code);
                setValue(idInputs[index].name, item?.id);
                setValue(rInputs[index].name, item?.reward);
                setValue(pInputs[index].name, item?.deduct_percent);
              });
            }
          });
        });
      } else {
        reset({
          selling_price,
          buying_price,
          code,
          quantity,
          category_ids: buildCategoryHierarchy(categories?.[0]),
          warehouse_id: warehouse.id,
          reward,
          deduct_percent,
          type,
        });
      }
    } catch (error) {
      LogApp(error, 'ndh');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onSelectParentCategory = (item: AutocompleteItem<Category>) => {
    setSelectedCategory(item);
    onSelectSubCategory({
      id: '',
      name: '',
      sub_categories: [],
    });
  };
  const onSelectSubCategory = (item: SubCategory) => {
    setSelectedSubCategory(item);
  };
  const onModalInteract = (value: boolean) => {
    setOpened(value);
  };
  const onAttrModalInteract = (value: boolean) => {
    setAttrOpened(value);
  };
  const onCategoryConfirm = () => {
    setValue('category_ids', `${selectedCategory.label} > ${selectedSubCategory.name}`);
    onModalInteract(false);
  };
  const onCategoryCancel = () => {
    onModalInteract(false);
  };
  const buildCategoryHierarchy = (category: Category) => {
    const hierarchy = [category.name];
    if (category.sub_categories && category.sub_categories.length > 0) {
      const subCategory = category.sub_categories[0];
      hierarchy.push(buildCategoryHierarchy(subCategory));
    }

    return hierarchy.join(' > ');
  };
  function findChangedItem(originalArray: ProductImage[], modifiedArray: ProductImage[]) {
    const changedItems: ProductImage[] = [];
    const changedIndexes: number[] = [];
    const rest: ProductImage[] = [];
    modifiedArray.forEach((item, index) => {
      if (item.id !== originalArray[index].id) {
        changedItems.push(item);
        changedIndexes.push(index);
      } else {
        rest.push(item);
      }
    });
    return {
      hasChanged: changedItems.length > 0,
      changedItems,
      changedIndexes,
      rest,
    };
  }
  const isOptionEmpty = (searchText: string) => {
    const filteredData = autocompleteData.attributes.filter((item) =>
      item.label.toLowerCase().includes(searchText.toLowerCase()),
    );
    setValue('is_empty', filteredData.length === 0);
    setValue('attr_item', searchText);
  };
  const addAttribute = async () => {
    try {
      dispatch(setLoading(true));
      const res: CreateProductAttributeRoot = await productAPI.createAttribute({
        name: getValues('attr_item'),
      });
      setAttributeList((draft) => {
        draft.push(res.data);
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onAddAttribute = (attr: ProductAttribute) => {
    setValue('selected_attr', attr);
    setValue('attr_item', attr.name);
  };
  const onConfirmAddAttribute = () => {
    const selectedAttr = getValues('selected_attr') as ProductAttribute;
    if (getValues('is_empty') && getValues('attr_item')) {
      addAttribute();
    } else if (selectedAttr)
      setAttributeList((draft) => {
        if (draft.findIndex((item) => item.id === selectedAttr.id) !== -1)
          setError('attr_item', { message: 'Đã tồn tại danh mục' });
        else draft.push(selectedAttr);
      });
    onAttrModalInteract(false);
  };
  const deleteProduct = async () => {
    try {
      dispatch(setLoading(true));
      const res = await productAPI.deleteProduct(id || '');
      toast.success(`Xoá sản phẩm thành công`, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      navigate(PATH_PRODUCT);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const deleteImage = (image: ProductImage) => {
    const foundIndex = productImages.findIndex((item) => item.id === image.id);
    if (foundIndex !== -1) {
      setProductImages((d) => {
        if (d[foundIndex].url) deleteImages.current.push(d[foundIndex].id ?? '');
        d[foundIndex].file = null;
        d[foundIndex].url = '';
      });
      uploadImageRef.current?.removeImage();
    }
  };
  const uploadAttributeImage = async (id: string, file: any) => {
    try {
      const logo = new FormData();
      if (file !== null) {
        logo.append('files', file);
        const res: ProductImageRoot = await productAPI.uploadImages(logo);
        setAttrGroupData((d) => {
          const foundChildIndex = d.findIndex((value) => value.key === 'parent');
          const parentAttrs = d[foundChildIndex].data;
          const foundItemIndex = parentAttrs.findIndex((item) => item.id === id);
          if (foundItemIndex !== -1) {
            const foundItem = parentAttrs[foundItemIndex];
            if (foundItem.image) {
              foundItem.image.file = file;
              foundItem.image.url = res.data.at(0)?.url ?? '';
              foundItem.image.id = res.data.at(0)?.id ?? '';
            }
          }
        });
      }
    } catch (error) {
      LogApp(error);
    }
  };
  const deleteAttributeImage = (id: string) => {
    setAttrGroupData((d) => {
      const foundChildIndex = d.findIndex((value) => value.key === 'parent');
      const parentAttrs = d[foundChildIndex].data;
      const foundItemIndex = parentAttrs.findIndex((item) => item.id === id);
      if (foundItemIndex !== -1) {
        const foundItem = parentAttrs[foundItemIndex];
        if (foundItem.image) {
          foundItem.image.file = null;
          foundItem.image.url = '';
          foundItem.image.id = null;
        }
      }
    });
  };
  useEffect(() => {
    if (!isEdit) getAutocompleteData();
  }, []);
  useEffect(() => {
    getProductDetail();
  }, []);
  return (
    <ProductForm
      errors={errors}
      register={register}
      setValue={setValue}
      getValues={getValues}
      watch={watch}
      onSubmit={handleSubmitForm}
      isEdit={isEdit}
      onCancel={handleCancel}
      changeSelectedImage={changeSelectedImage}
      tiers={tiers}
      // attributeGroup={attributeGroup}
      attrGroupData={attrGroupData}
      addAttributeItem={addAttributeItem}
      removeAttributeItem={removeAttributeItem}
      removeAttributeGroup={removeAttributeGroup}
      addAttributeGroup={addAttributeGroup}
      getChildAttrs={getChildAttrs}
      getProductInputs={getProductInputs}
      attributeList={attributeList}
      onSelectDropdown={onSelectDropdown}
      autocompleteData={autocompleteData}
      onSelectBasicInfo={onSelectBasicInfo}
      applyAll={applyAll}
      control={control}
      productImages={productImages}
      selectedCategory={selectedCategory}
      onSelectParentCategory={onSelectParentCategory}
      onSelectSubCategory={onSelectSubCategory}
      selectedSubCategory={selectedSubCategory}
      opened={opened}
      onModalInteract={onModalInteract}
      onCategoryConfirm={onCategoryConfirm}
      onCategoryCancel={onCategoryCancel}
      isOptionEmpty={isOptionEmpty}
      onAddAttribute={onAddAttribute}
      onConfirmAddAttribute={onConfirmAddAttribute}
      attrOpened={attrOpened}
      onAttrModalInteract={onAttrModalInteract}
      editorRef={editorRef}
      htmlDes={htmlDes}
      deleteProduct={deleteProduct}
      deleteImage={deleteImage}
      uploadImageRef={uploadImageRef}
      promotions={promotions}
      uploadAttributeImage={uploadAttributeImage}
      deleteAttributeImage={deleteAttributeImage}
    />
  );
};
