import { PopoverPopup, SharedImage, Trash } from '@components';
import { OrderItem } from '@interfaces';
import { LogApp, toVND } from '@utils';
import parse from 'html-react-parser';
import moment from 'moment';
import { styled } from 'styled-components';
import { QuantityControl } from './QuantityControl';

interface ProductItemListProps {
  orderList: OrderItem[];
  onIncrease: (item: OrderItem) => void;
  onDecrease: (item: OrderItem) => void;
  onViewDetail: (item: OrderItem) => void;
  onChangeQuantity: (item: OrderItem, value: number) => void;
  onChangeNote: (item: OrderItem, note: string) => void;
  onDeleteOrderItem: (item: OrderItem) => void;
}
export const ProductItemList = (props: ProductItemListProps) => {
  const {
    orderList,
    onIncrease,
    onDecrease,
    onChangeQuantity,
    onDeleteOrderItem,
    onChangeNote,
    onViewDetail,
  } = props;
  const getDiscountPercent = (orderItem: OrderItem) => {
    return orderItem.promotions?.reduce((acc, item) => acc + item.discount_percent, 0) / 100;
  };
  const getDiscountMoney = (orderItem: OrderItem) => {
    return toVND(orderItem.price * orderItem.amount * (1 - getDiscountPercent(orderItem)));
  };
  LogApp({ orderList });
  return (
    <StyledOrderItemList>
      {orderList.map((item, index) => {
        const isOverload = item.amount > item.product_amount_remain;
        const isDiscounted = getDiscountPercent(item) > 0;

        return (
          <div
            key={item.product_item_id || item.product_id}
            className={`order-item ${index === orderList.length - 1 ? 'no-border no-mb' : ''} 
            ${isOverload ? 'warning-item' : ''}
            `}
          >
            <div className="left-order-item">
              <SharedImage containerClassName="img-ctn" src={item.image?.url} />
              <div className="info-block">
                <div className="info-head">
                  <div className="heading">
                    <span className="product-name med-text">{item.name}</span>
                  </div>
                  <span>
                    Phân loại:{' '}
                    {item.classification_name || item.sub_classification_name
                      ? `${item.classification_name + ','} ${
                          item.sub_classification_name ? item.sub_classification_name : ''
                        }`
                      : 'Không có'}
                  </span>
                </div>
                <QuantityControl
                  size="small"
                  quantity={item.amount}
                  onIncrease={() => {
                    onIncrease(item);
                  }}
                  onDecrease={() => {
                    onDecrease(item);
                  }}
                  onChangeQuantity={(value) => {
                    onChangeQuantity(item, value);
                  }}
                />
                <span className="warehouse-text">Hàng tồn: {item.product_amount_remain}</span>
                <span className="warehouse-text">Kho: {item.warehouse.name}</span>
                <span className="warehouse-text">Đơn giá: {toVND(item.price)}</span>
                <span className="warehouse-text">
                  Giá sau chiết khấu: {toVND(item.price_deducted || 0)}
                </span>
                <span className="warehouse-text">
                  Chiết khấu: {item?.deduct_percent}% (-
                  {toVND((item?.price * item?.deduct_percent) / 100)})
                </span>
                {item.promotions.length > 0 && (
                  <div className="promotions">
                    <span className="title">Khuyến mãi:</span>
                    {item.promotions?.map((item, index) => {
                      return (
                        <div className="promotion-item" key={item.id}>
                          <div className="promotion-info">
                            <span className="sub-title">
                              {index + 1}.Mã khuyến mãi:{' '}
                              <span className="content">{item.code}</span>
                            </span>
                            <span className="sub-title ml-3">
                              Thời gian:
                              <span className="content">
                                {moment(item.start_day).format('DD/MM/YYYY')} -{' '}
                                {moment(item.end_day).format('DD/MM/YYYY')}
                              </span>
                            </span>
                            <span className="sub-title ml-3">
                              Chi tiết: {parse(item.description)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* <span
                  onClick={() => {
                    onViewDetail(item);
                  }}
                  className="view-more"
                >
                  {!item.showDetail ? 'Xem thêm' : 'Ẩn'}
                </span> */}
                {isOverload && (
                  <span className="warehouse-text warning-text">
                    Tồn kho không đủ cho đơn hàng. Vui lòng liên hệ lại khách
                  </span>
                )}
              </div>
            </div>
            <div className="right-order-item">
              {isDiscounted && <span className="price">{getDiscountMoney(item)}</span>}
              <span className={`${isDiscounted ? 'original-price' : ''} price`}>
                {toVND(item.total_money_deducted || 0)}
              </span>
            </div>
            <PopoverPopup
              content={
                <StyledConfirmPopup>
                  <p className="text-sm">Xác nhận xoá sản phẩm này?</p>
                </StyledConfirmPopup>
              }
              isHaveConfirmButton
              onConfirm={() => {
                onDeleteOrderItem(item);
              }}
            >
              <Trash className="trash" color="#B10000" />
            </PopoverPopup>
          </div>
        );
      })}
    </StyledOrderItemList>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
const StyledOrderItemList = styled.div`
  max-height: 50rem;
  overflow-y: scroll;
  .view-more {
    color: rgb(38, 164, 222);
    cursor: pointer;
  }
  .warning-item {
    background-color: #fff4f3;
  }
  .warning-text {
    color: #b10000;
  }
  .no-border {
    border-bottom: 0 !important;
  }
  .no-mb {
    margin-bottom: 0 !important;
  }
  .order-item {
    position: relative;
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #d9d9d9;
    textarea {
      width: 100%;
      border-width: 1px;
      margin-top: 1rem;
      padding: 1rem 0 0 1rem;
      height: 9.6rem;
      background-color: #fafafa;
    }
    .promotions {
      span {
        display: inline-block;
      }
      .promotion-item {
        .promotion-info {
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
        }
        .title {
          font-size: 1.4rem;
        }
        .sub-title {
          font-size: 1.2rem;
          .content {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }
    .left-order-item {
      display: flex;
      flex-direction: row;
      flex: 1;
      .img-ctn {
        width: 8rem;
        aspect-ratio: 1;
        img {
          border-radius: 8px;
        }
      }
      .info-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1.6rem;
        width: 100%;
        .info-head {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .right-order-item {
      display: flex;
      flex-direction: column;
      .original-price {
        text-decoration-line: line-through;
      }
    }
    .trash {
      position: absolute;
      right: 0;
      top: 0;
    }
    .price {
      font-size: 1.7rem;
    }
    span {
      display: inline-block;
    }
    .product-name {
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 140%;
      margin-bottom: 0.4rem;
    }
    .quantity-block {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`;
