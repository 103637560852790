import moment from 'moment';

export const DEFAULT_ADDRESS = {
  id: '',
  wards: {
    ward_name: '',
    ward_name_en: '',
    ward_code_name: '',
    code: '',
  },
  districts: {
    district_name: '',
    district_name_en: '',
    district_code_name: '',
    code: '',
  },
  provinces: {
    province_name: '',
    province_name_en: '',
    province_code_name: '',
    code: '',
  },
  detail: '',
};
export const DEFAULT_ORDER_DETAIL = {
  id: '',
  code: '',
  status: 0,
  from_address: DEFAULT_ADDRESS,
  to_address: DEFAULT_ADDRESS,
  seller: {
    id: '',
    name: '',
    brand_name: '',
    phone_number: '',
    role: '',
    username: '',
    full_name: '',
    email: '',
    dob: '',
    address: DEFAULT_ADDRESS,
    code: '',
    status: 1,
    warehouse: {
      id: '',
      name: '',
      code: '',
      phone_number: '',
      address_response: DEFAULT_ADDRESS,
    },
  },
  customer: {
    id: '',
    customer_name: '',
    customer_phone_number: '',
  },
  promotions: [],
  order_items: [],
  total_money: 0,
  money_debt: 0,
  total_money_debt: 0,
  money_paid: 0,
  money_addon: 0,
  money_deducted: 0,
  created_at: 0,
  last_updated_at: 0,
  reviews: [],
  customer_level_name: '',
  total_money_reward_cumulative: 0,
};
export const DEFAULT_CLASSIFICATION_PARAMS = {
  id: '',
  attribute_name: '',
  attribute_id: '',
  value: '',
  index: 0,
  image_id: '',
  product_item: {
    id: '',
    import_price: 0,
    selling_price: 0,
    code: '',
    quantity: 0,
    solded_quantity: 0,
    reward: false,
    deduct_percent: 0,
  },
  sub_classifications: [],
  detail: true,
};
export const DEFAULT_SELLER_DETAIL = {
  id: '',
  brand_name: '',
  username: '',
  full_name: '',
  name: '',
  phone_number: '',
  email: '',
  dob: '',
  address: DEFAULT_ADDRESS,
  role: '',
  code: '',
  status: 1,
  warehouse: {
    id: '',
    name: '',
    code: '',
    phone_number: '',
    address_response: {},
  },
};
export const DEFAULT_API_PARAMS = {
  keyword: '',
  size: 10,
};
export const DEFAULT_WAREHOUSE_FILTER_ITEM = {
  id: '',
  name: '',
  code: '',
  phone_number: '',
  address_response: null,
};
export const DEFAULT_CATEGORY_FILTER_ITEM = {
  id: '',
  name: '',
  image_url: null,
  sub_categories: [],
  activated: true,
};
export const DEFAULT_FROM_DATE = moment(new Date(), 'yyyy-MM-DD');
export const DEFAULT_TO_DATE = moment(
  `${moment().year()}-0${moment().month() - 3 + 1}-01`,
  'yyyy-MM-DD',
);
export const DEFAULT_DASHBOARD_FILTER_ITEM = {
  customer_id: null,
  seller_id: null,
  customer_type_id: null,
  // to_time: moment().format('yyyy-MM-DD'),
  to_time: null,
  from_time: null,
  // from_time: `${moment().year()}-0${moment().month() - 3 + 1}-01`,
  page: 0,
  keyword: null,
  warehouse_id: null,
};
export const DEFAULT_SELLER_FILTER_ITEM = {
  id: '',
  name: '',
  phone_number: '',
  brand_name: '',
};
export const DEFAULT_CUSTOMER_FILTER_ITEM = {
  id: '',
  full_name: '',
  phone_number: '',
};
