import * as yup from 'yup';
export const AccountFormValidator = yup.object().shape({
  username: yup.string().required('Vui lòng nhập tên tài khoản'),
  password: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
      'Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.',
    ),
  confirm_password: yup
    .string()
    .required('Vui lòng nhập xác nhận mật khẩu')
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),
  code: yup.string().required('Vui lòng nhập mã seller'),
});
export const DefaultSellerFormValidator = yup.object().shape({
  warehouse_id: yup.string().required('Vui lòng chọn kho'),
  email: yup.string().required('Vui lòng nhập email'),
  phone_number: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .min(9, 'Số điện thoại không hợp lệ')
    .max(13, 'Số điện thoại không hợp lệ'),
  full_name: yup.string().required('Vui lòng nhập họ và tên'),
  dob: yup.string().required('Vui lòng chọn ngày sinh'),
  province_code: yup.string().required('Vui lòng chọn tỉnh/thành phố'),
  district_code: yup.string().required('Vui lòng chọn quận/huyện'),
  ward_code: yup.string().required('Vui lòng chọn phường/xã'),
  address_detail: yup.string().required('Vui lòng nhập địa chỉ chi tiết'),
});
export const SellerFormValidator = yup.object().shape({
  ...AccountFormValidator.fields,
  ...DefaultSellerFormValidator.fields,
});
