import {
  AppModal,
  IConCancel,
  Pagination,
  PlusIcon,
  PopoverPopup,
  SearchIcon,
  SharedButton,
  SharedTable,
  ShareInput,
  Trash,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  CategoryPayload,
  ProductImage,
  ProductImageResponse,
  SubCategoryColumn,
} from '@interfaces';
import { ModalState, UploadImageModule } from '@modules';
import { themes, useTheme } from '@theme';
import { ColumnsType } from 'antd/lib/table';
import { BaseSyntheticEvent } from 'react';
import {
  FieldArrayWithId,
  FieldErrors,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form';
import styled from 'styled-components';
interface IProps {
  categories: CategoryData;
  columns: ColumnsType<SubCategoryColumn>;
  onSearch: (value: string) => void;
  currentPage?: number;
  onPageChange: (value: number) => void;
  onChangePageSize: (size: number) => void;
  onModalInteract: (value: ModalState) => void;
  modalOpened: boolean;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  onCreateCategory: (
    e?: BaseSyntheticEvent<CategoryPayload, any, any> | undefined,
  ) => Promise<void>;
  onOpenCreateModal: () => void;
  isEdit: boolean;
  fields: FieldArrayWithId<FieldValues, any, any>[];
  append: UseFieldArrayAppend<FieldValues, any>;
  remove: UseFieldArrayRemove;
  renderChildRow: (record: SubCategoryColumn) => JSX.Element;
  onDeleteCategory: (index: number) => void;
  uploadCategoryLogo: (logo: any) => void;
  logo: ProductImage;
  uploadImageRef: any;
  deleteLogo: () => void;
}
export interface CategoryData {
  count: number;
  limit: number;
  data: SubCategoryColumn[];
}

export const CategoryTable = ({
  categories,
  columns,
  onSearch,
  currentPage,
  onPageChange,
  onChangePageSize,
  onModalInteract,
  modalOpened,
  register,
  errors,
  onCreateCategory,
  onOpenCreateModal,
  isEdit,
  fields,
  append,
  remove,
  renderChildRow,
  onDeleteCategory,
  uploadCategoryLogo,
  logo,
  uploadImageRef,
  deleteLogo,
}: IProps) => {
  const { theme } = useTheme();
  return (
    <StyledTableWrapper>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search-tier__box"
            className="search-tier__input"
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
            placeholder="Tìm kiếm"
            prevIcon={<SearchIcon />}
          />
        </div>
        <div className="flex">
          <SharedButton
            onClick={() => {
              onOpenCreateModal();
            }}
            className="create-tier__button"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            text="Thêm danh mục"
            btnStyle="pad"
          />
        </div>
      </StyledSearchWrapper>
      <SharedTable
        renderChildRow={renderChildRow}
        columns={columns?.filter((item: any) => !item?.hidden)}
        dataSource={[...categories.data]}
        // key={String(isLevelChanging)}
        // rowKey={String(isLevelChanging)}
      />
      <div className="bottom-pagination">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={categories.count}
          pageSize={categories.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      {modalOpened && (
        <AppModal open={modalOpened} onClose={() => {}}>
          {/* @ts-ignore */}
          <form onSubmit={onCreateCategory} className="modal">
            <h2>{isEdit ? 'Sửa' : 'Thêm'} danh mục</h2>
            <div className="mb-2">
              <span>Logo:</span>
              <div className="flex relative w-fit">
                <UploadImageModule
                  ref={uploadImageRef}
                  typeUpload="image"
                  defaultUrl={logo?.url}
                  onUploadEnd={(file) => {
                    uploadCategoryLogo(file);
                  }}
                />
                {(logo.file || logo.url) && (
                  <IConCancel
                    onClick={() => {
                      deleteLogo();
                    }}
                    className="delete"
                  />
                )}
              </div>
            </div>
            <ShareInput
              name="name"
              register={register}
              label="Danh mục cha"
              required
              isFieldArray
              placeholder="Nhập tên danh mục cha"
              errMsg="Vui lòng nhập tên danh mục cha"
              errors={errors['name']?.message}
            />
            <div className="sub-category-block">
              <span className="title">Danh mục con</span>
              {fields.map((item, index) => {
                return (
                  <div className="input-wrapper">
                    <ShareInput
                      key={item.id}
                      name={`sub_category.${index}.value`}
                      register={register}
                      label=""
                      required
                      isFieldArray
                      placeholder="Nhập tên danh mục con"
                      //@ts-ignore
                      errors={errors?.sub_category?.[index]?.value?.message}
                      errMsg="Vui lòng nhập tên danh mục con"
                      sufIcon={
                        <>
                          {isEdit ? (
                            <PopoverPopup
                              content={
                                <StyledConfirmPopup>
                                  <p className="text-sm">Xác nhận xoá danh mục này?</p>
                                </StyledConfirmPopup>
                              }
                              isHaveConfirmButton
                              onConfirm={() => {
                                onDeleteCategory(index);
                              }}
                              // placement="bottom"
                            >
                              <Trash color={'red'} />
                            </PopoverPopup>
                          ) : (
                            <Trash
                              onClick={() => {
                                remove(index);
                              }}
                              color={'red'}
                            />
                          )}
                        </>
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                append({ value: '' });
              }}
              className="add-sub-category-btn"
            >
              <div className="text-block">
                <PlusIcon size={24} color={MAIN_THEME_DATA.mainColor} />
                <span>Thêm danh mục con</span>
              </div>
            </div>
            <div className="button-wrapper">
              <SharedButton
                onClick={() => {
                  onModalInteract({ id: '', open: false });
                }}
                typeHtml="button"
                className="btn-save btn-cancel"
                backgroundColor="transparent"
                textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
                borderColor={
                  theme?.colors?.button?.border || themes.theme.light.colors.button.border
                }
                text={'Đóng'}
              />
              <SharedButton
                typeHtml="submit"
                className="btn-save"
                textColor="white"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                text={'Lưu'}
              />
            </div>
          </form>
        </AppModal>
      )}
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div`
  .sub-category-block {
    margin-top: 1rem;
    max-height: 30rem;
    overflow-y: auto;
    .title {
      display: inline-block;
      margin-bottom: 0.8rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      color: #303030;
    }
    .input-wrapper {
      margin-bottom: 1rem;
    }
  }
  .add-sub-category-btn {
    cursor: pointer;
    height: 4rem;
    border: 1px dashed ${MAIN_THEME_DATA.mainColor};
    width: 100%;
    border-radius: 0.6rem;
    margin-top: 1.5rem;
    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        font-weight: bold;
        margin-left: 1rem;
        font-size: larger;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    .level-change {
      /* margin-right: 3rem; */
      .btn {
        box-shadow: none;
      }
    }
  }
  .modal {
    width: 100rem;
    .delete {
      position: absolute;
      top: -15px;
      right: -15px;
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .search-tier__box {
    min-width: 20rem;
    width: fit-content;
    .search-tier__input {
      height: 4.5rem;
    }
  }
  .create-tier__button {
    height: 4.5rem;
    width: fit-content;
  }
  /* .input-wrapper {
    width: 30%;
    margin-right: 2rem;
  }
  .text-button {
    padding: 0.6rem 1.2rem;
  } */
`;

const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
