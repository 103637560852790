import { OrderModule } from '@modules';
import { styled } from 'styled-components';
export const OrderPage = () => {
  return (
    <StyledOrderPage className="announcement-page">
      <div className="page__head">
        <h2 className="title">Quản lý đơn hàng</h2>
      </div>
      <div className="page__section">
        <OrderModule />
      </div>
    </StyledOrderPage>
  );
};

export const StyledOrderPage = styled.div`
  .page__head {
    margin-bottom: 2rem;
    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .page__section {
    position: relative;
    padding: 2rem 2.8rem;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    box-shadow: 0px 3px 20px #0000000b;
  }
`;
