import { CreateProductModule, CreatePromotionModule } from '@modules';
import { StyledPromotionPage } from './Promotion';

export const CreatePromotionPage = () => {
  return (
    <StyledPromotionPage>
      <div className="page__head">
        <h2 className="title">Thêm khuyến mãi</h2>
      </div>
      <div className="page__section">
        <CreatePromotionModule />
      </div>
    </StyledPromotionPage>
  );
};
