import {
  ILoginFields,
  IRegisterFields,
  IResetPasswordFields,
  ISendMailForgotFields,
  IVerifyEmailFields,
} from '@interfaces';
import axiosClient, { ApiClient } from './axiosClient';

export const authAPI = {
  login: (values: ILoginFields) => {
    const url = '/admin/auth/login';
    return axiosClient.post(url, values);
  },
  register: (values: IRegisterFields) => {
    const url = '/merchant/register';
    return axiosClient.post(url, values);
  },
  forgotPassword: (values: ISendMailForgotFields) => {
    const url = '/merchant/send-otp';
    return axiosClient.post(url, values);
  },
  verifyEmail: (values?: IVerifyEmailFields) => {
    const url = '/merchant/verify-otp';
    return axiosClient.post(url, values);
  },
  changePassword: (id: string, values: IResetPasswordFields) => {
    const url = `/admin/accounts/${id}/change-password`;
    return axiosClient.post(url, values);
  },
  logout: () => {
    const url = '/auth/logout';
    return axiosClient.post(url);
  },
  checkVersion: () => {
    const url = `/tools/version`;
    return ApiClient.get(url);
  },
};
