import { MAIN_THEME_DATA } from '@configs';
import { selectApp, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { HideIcon, ViewIcon } from 'src/components/Icon';
import styled from 'styled-components';
interface ITableProps {
  columns: ColumnsType<any>;
  dataSource: any;
  pagination?: false | TablePaginationConfig;
  key?: number | string;
  rowKey?: string;
  renderChildRow?: (record: any) => JSX.Element;
  onRowClick?: (item: any) => void;
  onExpandSuccess?: (record: any) => void;
}
export const SharedTable = ({
  columns,
  dataSource,
  pagination = false,
  key,
  rowKey,
  renderChildRow,
  onRowClick,
  onExpandSuccess,
}: ITableProps) => {
  const { tableLoading } = useAppSelector(selectApp);
  return (
    <StyledTableWrapper $appTheme={MAIN_THEME_DATA.mainColor}>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClick?.(record);
            }, // click row
          };
        }}
        expandable={
          renderChildRow
            ? {
                expandedRowRender: (record) => {
                  return renderChildRow?.(record);
                },
                expandIcon: (props) => (
                  <a
                    onClick={(e) => {
                      props.onExpand(props.record, e);
                      if (!props.expanded) onExpandSuccess?.(props.record);
                    }}
                  >
                    {props.expanded ? <HideIcon size={20} /> : <ViewIcon size={20} />}
                  </a>
                ),
              }
            : undefined
        }
        bordered
        key={key}
        rowKey={rowKey}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        loading={tableLoading}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
      />
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div<{
  $appTheme: string;
}>`
  table {
    box-shadow: 0px 1px 16px 0px rgba(56, 56, 56, 0.15);
  }
  .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
  .ant-pagination-item-active a {
    border-color: ${(p) => p.$appTheme};
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: ${(p) => p.$appTheme};
  }
  .ant-table-thead > tr > th {
    position: relative;
    color: white;
    font-weight: 500;
    text-align: center;
    background: ${(p) => p.$appTheme};
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    text-align: center;
  }
`;
