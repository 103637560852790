import {
  IConCancel,
  PlusIcon,
  ShareInput,
  ShareSelectInput,
  SharedButton,
  SharedCheckbox,
  Trash,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  AttributeDropdown,
  AttributeGroupData,
  AttributeItem,
  AttributeParentKey,
  ProductInputType,
} from '@interfaces';
import { UploadImageModule } from '@modules';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { styled } from 'styled-components';
const LIMIT_ATTRIBUTE_GROUP = 2;
const LIMIT_ATTRIBUTE_ITEM = 20;
const DEFAULT_COLS = 7;
interface SaleInfoProps {
  addAttributeItem: (key: AttributeParentKey) => void;
  removeAttributeItem: (item: AttributeItem, key: AttributeParentKey) => void;
  attrGroupData: AttributeGroupData[];
  removeAttributeGroup: (key: AttributeParentKey, item: AttributeGroupData) => void;
  addAttributeGroup: () => void;
  getChildAttrs: () => AttributeItem[];
  getProductInputs: (
    attrGroupData: AttributeGroupData[],
    type: ProductInputType,
  ) => AttributeItem[];
  onSelectDropdown: (value: string, key: AttributeParentKey) => void;
  applyAll: () => void;
  control: Control<any, any>;
  errors: Partial<FieldErrorsImpl<any>>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  uploadAttributeImage: (id: string, file: any) => Promise<void>;
  deleteAttributeImage: (id: string) => void;
}
const ATTRIBUTES = {
  '95cd46bb-0a07-44c1-ad83-fe30f75f1708': 'Màu sắc',
  'b32d65a7-c18d-488a-ac80-a673b239fb20': 'Size',
};
const NUMBER_INPUT_OBJECT = {
  min: 0,
  max: 100,
  step: 0.1,
  type: 'number',
};
const TableInput = ({
  register,
  name,
  control,
}: {
  register: UseFormRegister<any>;
  name: string;
  control: Control<any, any>;
}) => {
  // return <input className="table-input" {...register(name)} />;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <NumericFormat
          className="table-input"
          thousandSeparator=","
          value={value}
          onChange={onChange}
          name={name}
        />
      )}
    />
  );
};
const TableCheckBox = ({
  register,
  name,
  control,
  label,
  className,
}: {
  register: UseFormRegister<any>;
  name: string;
  control: Control<any, any>;
  label?: string;
  className?: string;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <SharedCheckbox
            className={className}
            text={label}
            value={value}
            onChange={onChange}
            name={name}
          />
        );
      }}
    />
  );
};
const AttributeGroupItem = ({
  data,
  register,
  onAddItem,
  onDeleteItem,
  onCancel,
  attrKey,
  dropdown,
  onSelectDropdown,
  watch,
  errors,
}: {
  data: AttributeItem[];
  register: UseFormRegister<any>;
  onAddItem: () => void;
  onDeleteItem: (item: AttributeItem) => void;
  onCancel: () => void;
  attrKey: AttributeParentKey;
  dropdown: AttributeDropdown;
  onSelectDropdown: (value: string, key: AttributeParentKey) => void;
  watch: UseFormWatch<any>;
  errors: Partial<FieldErrorsImpl<any>>;
}) => {
  return (
    <StyledAttributeItem>
      <p className="title-attr">Nhóm phân loại</p>
      {/* <ShareInput
        register={register}
        className="input-mb"
        name={attrKey}
        label="Tên nhóm phân loại"
        placeholder="Nhập nhóm phân loại (Màu sắc, kích cỡ, kiểu dáng v.v...)"
      /> */}
      <div className="input-block input-mb">
        <ShareSelectInput
          label="Tên nhóm phân loại"
          placeholder="Chọn nhóm phân loại"
          value={watch(attrKey)}
          data={dropdown}
          onChange={(value) => {
            onSelectDropdown(value, attrKey);
          }}
          errors={errors?.[attrKey]?.message}
        />
      </div>

      <p className="class-attr">Phân loại</p>
      {data.map((item, index) => {
        return (
          <div className="input-block">
            <ShareInput
              register={register}
              className="input-mb"
              key={item.id}
              name={`${item.name}`}
              required
              placeholder="Nhập phân loại (Đỏ, XL, dáng thể thao v.v...)"
              sufIcon={
                <Trash
                  onClick={() => {
                    onDeleteItem(item);
                  }}
                />
              }
            />
          </div>
        );
      })}
      {data.length < LIMIT_ATTRIBUTE_ITEM && (
        <div onClick={onAddItem} className="repeater-add">
          <div className="text-block">
            <PlusIcon size={24} color={MAIN_THEME_DATA.mainColor} />
            <span>
              Đã thêm {data.length}/{LIMIT_ATTRIBUTE_ITEM}
            </span>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          onCancel();
        }}
        className="delete-attr"
      >
        <IConCancel />
      </div>
    </StyledAttributeItem>
  );
};
const AttributeTable = ({
  data,
  watch,
  itemHeight,
}: {
  itemHeight: string;
  data: AttributeItem[];
  watch: UseFormWatch<any>;
}) => {
  return (
    <StyledParentAttributeTable numRows={data.length}>
      <table>
        {data.map((item) => {
          return (
            <tr
              style={{
                height: itemHeight,
              }}
              key={item.id}
            >
              <td className="attribute-text">{watch(item.name) || 'Thuộc tính'}</td>
            </tr>
          );
        })}
      </table>
    </StyledParentAttributeTable>
  );
};
const ProductInputTable = ({
  data,
  register,
  control,
  isCodeInput = false,
  isCheckBox = false,
  inputType = 'default',
}: {
  data: AttributeItem[];
  register: UseFormRegister<any>;
  watch?: UseFormWatch<any>;
  control: Control<any, any>;
  isCodeInput?: boolean;
  isCheckBox?: boolean;
  inputType?: 'default' | 'number';
}) => {
  const inputObject = inputType === 'number' ? { ...NUMBER_INPUT_OBJECT } : {};
  return (
    <StyledParentAttributeTable numRows={data.length}>
      <table>
        {data.map((item) => {
          return (
            <tr key={item.id}>
              <>
                {isCheckBox ? (
                  <TableCheckBox
                    className="chk-item"
                    control={control}
                    register={register}
                    name={item.name}
                  />
                ) : (
                  <>
                    {!isCodeInput ? (
                      <TableInput control={control} register={register} name={item.name} />
                    ) : (
                      <textarea {...inputObject} className="table-input" {...register(item.name)} />
                    )}
                  </>
                )}
              </>
            </tr>
          );
        })}
      </table>
    </StyledParentAttributeTable>
  );
};
const ImageTable = ({
  data,
  itemHeight,
  uploadAttributeImage,
  deleteAttributeImage,
}: {
  itemHeight: string;
  data: AttributeItem[];
  uploadAttributeImage: (id: string, file: any) => Promise<void>;
  deleteAttributeImage: (id: string) => void;
}) => {
  return (
    <StyledParentAttributeTable numRows={data.length}>
      <table>
        {data.map((item) => {
          return (
            <tr
              style={{
                height: itemHeight,
              }}
              key={item.id}
            >
              <td className="flex justify-center items-center h-full">
                <div className="relative">
                  <UploadImageModule
                    defaultUrl={item?.image?.url}
                    onUploadEnd={(file) => {
                      uploadAttributeImage(item.id, file);
                    }}
                    typeUpload="image"
                  />
                  {(item.image?.file || item.image?.url) && (
                    <IConCancel
                      width={30}
                      height={30}
                      onClick={() => {
                        deleteAttributeImage(item.id);
                      }}
                      className="delete"
                    />
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </StyledParentAttributeTable>
  );
};
export const SaleInfo = ({
  addAttributeItem,
  removeAttributeItem,
  attrGroupData,
  addAttributeGroup,
  removeAttributeGroup,
  getChildAttrs,
  getProductInputs,
  onSelectDropdown,
  applyAll,
  register,
  watch,
  control,
  errors,
  uploadAttributeImage,
  deleteAttributeImage,
}: SaleInfoProps) => {
  const parentAttrs = attrGroupData.filter((item) => item.key === 'parent')?.[0];
  const childAttrs = getChildAttrs();
  const buyingPriceInputs = getProductInputs(attrGroupData, 'bp');
  const sellingPriceInputs = getProductInputs(attrGroupData, 'sp');
  const quantityInputs = getProductInputs(attrGroupData, 'quantity');
  const codeInputs = getProductInputs(attrGroupData, 'code');
  const rewardCheckBoxes = getProductInputs(attrGroupData, 'reward');
  const percentInputs = getProductInputs(attrGroupData, 'deduct_percent');
  return (
    <StyledSaleInfo numRows={childAttrs?.length || 1} numCols={attrGroupData.length + DEFAULT_COLS}>
      <div className="quantity-wrapper">
        <div className="quantity-block">
          <ShareInput
            required
            register={register}
            inputDefaultStyle="preTab"
            containerClassName="input-mr"
            className="input-mb"
            name="buying_price"
            label="Giá mua"
            prefix="đ"
            placeholder="Nhập giá"
            isNumericFormat
            control={control}
            errors={errors['buying_price']?.message}
          />
          <ShareInput
            required
            register={register}
            inputDefaultStyle="preTab"
            containerClassName="input-mr"
            className="input-mb"
            name="selling_price"
            label="Giá bán"
            prefix="đ"
            placeholder="Nhập giá"
            isNumericFormat
            control={control}
            errors={errors['selling_price']?.message}
          />
          <ShareInput
            required
            register={register}
            inputDefaultStyle="preTab"
            containerClassName="input-mr"
            className="input-mb"
            name="quantity"
            label="Số lượng"
            placeholder="Nhập số lượng"
            errors={errors['quantity']?.message}
          />
          <ShareInput
            className="input-mb"
            name="deduct_percent"
            containerClassName="input-mr"
            register={register}
            label="Chiết khấu (%)"
            min={0}
            max={100}
            step={0.1}
            type="number"
            // required
            placeholder="Nhập chiết khấu"
            // errors={errors['deduct_percent']?.message}
          />
          {!attrGroupData.length && (
            <>
              <ShareInput
                required
                register={register}
                inputDefaultStyle="preTab"
                containerClassName="input-mr"
                className="input-mb"
                name="code"
                label="Mã sản phẩm"
                placeholder="Nhập mã sản phẩm"
                errors={errors['code']?.message}
              />
            </>
          )}
          <TableCheckBox
            name="reward"
            register={register}
            control={control}
            className="chk-box"
            label="Ghi nhận doanh số thưởng"
          />
        </div>
        {attrGroupData.length > 0 && (
          <SharedButton
            onClick={applyAll}
            className="apply-btn"
            typeHtml="button"
            text={'Áp dụng tất cả'}
            backgroundColor={MAIN_THEME_DATA.mainColor}
            btnStyle="basic"
          />
        )}
      </div>
      {attrGroupData.map((val) => {
        return (
          <AttributeGroupItem
            watch={watch}
            onSelectDropdown={onSelectDropdown}
            dropdown={val.dropdown}
            key={val.key}
            attrKey={val.key}
            data={val.data}
            register={register}
            onAddItem={() => {
              addAttributeItem(val.key);
            }}
            onDeleteItem={(item) => {
              removeAttributeItem(item, val.key);
            }}
            onCancel={() => {
              removeAttributeGroup(val.key, val);
            }}
            errors={errors}
          />
        );
      })}
      {attrGroupData.length < LIMIT_ATTRIBUTE_GROUP && (
        <div onClick={addAttributeGroup} className="repeater-add">
          <div className="text-block">
            <PlusIcon size={24} color={MAIN_THEME_DATA.mainColor} />
            <span>Thêm nhóm phân loại hàng</span>
          </div>
        </div>
      )}
      {attrGroupData.length > 0 && (
        <div className="table-wrapper">
          <table>
            <tr>
              <th className="parent-td-th">Ảnh</th>
              {attrGroupData.map((item) => {
                return (
                  <>
                    {/*@ts-ignore*/}
                    <th className="parent-td-th">{ATTRIBUTES[watch(item.key)] || 'Phân loại'}</th>
                  </>
                );
              })}
              <th className="parent-td-th">
                Giá mua<span className="red">*</span>
              </th>
              <th className="parent-td-th">
                Giá bán<span className="red">*</span>
              </th>
              <th className="parent-td-th">
                Số lượng<span className="red">*</span>
              </th>
              <th className="parent-td-th">
                Mã sản phẩm<span className="red">*</span>
              </th>
              <th className="parent-td-th">Chiết khấu (%)</th>
              <th className="parent-td-th">Ghi nhận doanh số thưởng</th>
            </tr>
            <tr>
              <td className="parent-td-th">
                <ImageTable
                  deleteAttributeImage={deleteAttributeImage}
                  uploadAttributeImage={uploadAttributeImage}
                  itemHeight={`${(childAttrs?.length / parentAttrs?.data?.length) * 4}rem`}
                  data={parentAttrs?.data}
                />
              </td>
              <td className="parent-td-th">
                <AttributeTable
                  itemHeight={`${(childAttrs?.length / parentAttrs?.data?.length) * 4}rem`}
                  watch={watch}
                  data={parentAttrs?.data || []}
                />
              </td>
              {childAttrs?.length > 0 && (
                <td className="parent-td-th">
                  <AttributeTable itemHeight={'1rem'} watch={watch} data={childAttrs} />
                </td>
              )}
              <td className="parent-td-th">
                <ProductInputTable data={buyingPriceInputs} register={register} control={control} />
              </td>
              <td className="parent-td-th">
                <ProductInputTable
                  data={sellingPriceInputs}
                  register={register}
                  control={control}
                />
              </td>
              <td className="parent-td-th">
                <ProductInputTable data={quantityInputs} register={register} control={control} />
              </td>
              <td className="parent-td-th">
                <ProductInputTable
                  isCodeInput
                  data={codeInputs}
                  register={register}
                  control={control}
                />
              </td>
              <td className="parent-td-th">
                <ProductInputTable
                  inputType="number"
                  data={percentInputs}
                  register={register}
                  control={control}
                />
              </td>
              <td className="parent-td-th">
                <ProductInputTable
                  isCheckBox
                  data={rewardCheckBoxes}
                  register={register}
                  control={control}
                />
              </td>
            </tr>
          </table>
        </div>
      )}
    </StyledSaleInfo>
  );
};
const StyledAttributeItem = styled.div`
  background-color: rgb(250, 250, 250);
  padding: 2rem;
  position: relative;
  margin: 1.5rem 0rem;
  .inner-input .prefix {
    font-size: 1.4rem;
    color: black;
  }
  .input-block {
    width: 70%;
  }
  .delete-attr {
    position: absolute;
    top: 3%;
    width: fit-content;
    right: 0.5%;
    cursor: pointer;
  }
  .title-attr {
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
  }
  .class-attr {
    margin-bottom: 0.5rem;
  }
`;
const StyledParentAttributeTable = styled.div<{
  numRows: number;
}>`
  .delete {
    position: absolute;
    top: -15px;
    right: -15px;
  }
  .upload__image {
    width: 7.8rem;
    height: 7.8rem;
  }
  .attribute-text {
    font-size: 2rem;
  }
  tr:not(:last-child) {
    border-bottom: 1px solid #dddddd;
  }
  tr {
    /* height: ${(p) => `calc(100% / ${p.numRows})`}; */
    height: 1rem;
  }
  height: 100%;
`;
const StyledSaleInfo = styled.div<{
  numCols: number;
  numRows: number;
}>`
  .chk-item {
    justify-content: center;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
  .red {
    color: red;
  }
  .quantity-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .apply-btn {
      width: fit-content;
      padding: 0.9rem 1.2rem;
      margin-bottom: 1rem;
      margin-left: 2rem;
    }
    .quantity-block {
      width: 90%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .chk-box {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .table-wrapper {
    margin-top: 2rem;
    table {
      width: 100%;
      height: ${(p) => {
        return p.numRows === 1 ? `100%` : `${p.numRows * 8}rem`;
      }};
      min-height: 5rem;
    }
    .parent-td-th {
      border: 1px solid #dddddd;
      text-align: center;
      // padding: 8px;
      width: ${(p) => `${100 / p.numCols}%`};
      height: 5rem;
    }
    .table-input {
      outline: none;
      height: 100%;
      width: 90%;
      font-size: 2rem;
      resize: none;
    }
  }
  .input-mb {
    margin-bottom: 1rem;
  }
  .input-mr {
    margin-right: 2rem;
  }
  .repeater-add {
    cursor: pointer;
    height: 4rem;
    border: 1px dashed ${MAIN_THEME_DATA.mainColor};
    width: 100%;
    border-radius: 0.6rem;
    margin-top: 1.5rem;
    .text-block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        font-weight: bold;
        margin-left: 1rem;
        font-size: larger;
        color: ${MAIN_THEME_DATA.mainColor};
      }
    }
  }
`;
