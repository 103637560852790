import { Image } from '@interfaces';
export interface PromotionColumn {
  key: React.Key;
  no: number;
  name: string;
  code: string;
  time: string;
  discount_percent: number;
  onEdit: () => void;
  onActivate: () => void;
  showProductList: () => void;
  activated: boolean;
}
export enum PromotionType {
  AMOUNT = 0,
  MONEY,
}
export interface PromotionPayload {
  name: string;
  code: string;
  description: any;
  start_day: string;
  end_day: string;
  discount_percent?: number;
  type: PromotionType;
}
export interface PromotionResponse {
  content: Promotion[];
  amount: number;
}

export interface Promotion {
  id: string;
  name: string;
  code: string;
  description: string;
  start_day: number;
  end_day: number;
  discount_percent: number;
  money_deducted: number;
  coin: number;
  type: PromotionType;
  activated: boolean;
  promotion_items: PromotionItem[];
}
export interface PromotionData {
  count: number;
  limit: number;
  data: PromotionColumn[];
}
export interface PromotionalProductPayload {
  product_ids: string[];
  promotion_id: string;
}

export interface PromotionalProduct {
  product_id: string;
  product_name: string;
  promotion_id: string;
  image?: Image;
  warehouse_name: string;
}
export interface PromotionalProductResponse {
  content: PromotionalProduct[];
  amount: number;
}
export interface PromotionItem {
  id: string;
  promotion_id: string;
  condition_amount: number;
  discount_percent: number;
}
