import { Tabs } from 'antd';
import styled from 'styled-components';

import { dateOfBirthFormat, MAIN_THEME_DATA } from '@configs';
import moment from 'moment';
import {
  LastVisitIcon,
  PercentMathIcon,
  PointIcon,
  RebatePointIcon,
  SpentIcon,
  UsedPointIcon,
  VisitIcon,
} from '../Icon';
import HeadProfile from './HeadProfile';

interface IProps {
  data?: any;
  isShowPhone?: boolean;
  toggleShowPhone?: () => void;
}

export const MemberProfileContent = (props: IProps) => {
  const { data } = props;
  return (
    <StyledMemberProfile>
      <div className="head__section pt-4 pb-3">
        <HeadProfile {...props} />
        <div className="details-profile">
          <Tabs
            defaultActiveKey="1"
            // centered
          >
            <Tabs.TabPane tab="Info" key="1">
              <div className="member-info flex items-center">
                <div className="flex-1">
                  <div className="info-items">
                    <p className="label">Name:</p>
                    <p className="value">{data?.name}</p>
                  </div>
                  <div className="info-items">
                    <p className="label">Address:</p>
                    <p className="value">{data?.address}</p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="info-items">
                    <p className="label">Gender:</p>
                    <p className="value capitalize">{data?.gender}</p>
                  </div>
                  <div className="info-items">
                    <p className="label">Date of birth:</p>
                    <p className="value">
                      {moment(Number(data?.date_of_birth)).format(dateOfBirthFormat)}
                    </p>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Dashboard" key="2">
              <div className="member-dashboard pt-5">
                <div className="flex items-center pb-5">
                  <div className="items flex-1">
                    <SpentIcon />
                    <p className="label">Total spent:</p>
                    <p className="value">${data?.memberLink?.total_spend || 0}</p>
                  </div>
                  <div className="items flex-1">
                    <PointIcon />
                    <p className="label">Total point:</p>
                    <p className="value">{data?.memberLink?.total_points || 0}</p>
                  </div>
                  <div className="items flex-1">
                    <UsedPointIcon />
                    <p className="label">Total used:</p>
                    <p className="value">${data?.memberLink?.total_used || 0}</p>
                  </div>
                  <div className="items flex-1">
                    <RebatePointIcon />
                    <p className="label">Total rebate:</p>
                    <p className="value">${data?.memberLink?.total_rebate || 0}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="items flex-1">
                    <PercentMathIcon />
                    <p className="label">Profile match:</p>
                    <p className="value">{data?.memberLink?.profile_match || 0}%</p>
                  </div>
                  <div className="items flex-1">
                    <RebatePointIcon />
                    <p className="label">Cashback:</p>
                    <p className="value">{data?.memberLink?.cashback || 0}</p>
                  </div>
                  <div className="items flex-1">
                    <VisitIcon />
                    <p className="label">Total visit:</p>
                    <p className="value">{data?.memberLink?.total_visits || 0}</p>
                  </div>
                  <div className="items flex-1">
                    <LastVisitIcon />
                    <p className="label">Last visit:</p>
                    <p className="value">
                      {!data?.memberLink?.last_visit
                        ? '---'
                        : moment(Number(data?.memberLink?.last_visit)).format(dateOfBirthFormat)}
                    </p>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </StyledMemberProfile>
  );
};

const StyledMemberProfile = styled.div`
  .head__section {
    position: relative;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    box-shadow: 0px 3px 20px #0000000b;
    .head-profile {
      .tier-text {
        font-weight: 700;
        color: ${MAIN_THEME_DATA.mainColor};
      }
      .email-text,
      .phone-text {
        font-weight: 500;
        color: ${(p) => p.theme.colors.secondary};
      }
      .edit-member-profile__btn {
        width: fit-content;
        padding: 0.3rem 1.8rem;
        border-radius: 0.5rem;
      }
      .days-left-birthday {
        margin-top: 0.6rem;
        .dl-label {
          margin-bottom: 0;
          font-size: 1.2;
          font-weight: 500;
          color: ${(p) => p.theme.colors.note};
        }
        .is-birth {
          font-weight: 600;
          color: ${(p) => p.theme.colors.highlight};
        }
      }
    }
    .details-profile {
      .ant-tabs-nav {
        padding: 0 3.5rem;
        .ant-tabs-nav-list {
          /* width: 100%; */
        }

        .ant-tabs-tab {
          /* width: 100%; */
          &:hover {
            color: ${MAIN_THEME_DATA.mainColor};
          }

          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            /* color: ${MAIN_THEME_DATA.mainColor}; */
          }
        }
      }

      .ant-tabs-content-holder {
        padding: 0 3.5rem;
      }
    }

    .member-info {
      .info-items {
        .label {
          font-size: 1.6rem;
          font-weight: 600;
          color: ${(p) => p.theme.colors.text};
        }
        .value {
          padding-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: 500;
          color: ${(p) => p.theme.colors.subText};
        }
      }
    }

    .member-dashboard {
      .items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .label {
          margin-top: 0.6rem;
          text-align: center;
          font-size: 1.8rem;
          font-weight: 700;
          color: ${(p) => p.theme.colors.text};
        }
        .value {
          text-align: center;
          padding-bottom: 1rem;
          font-size: 2.3rem;
          font-weight: 600;
          color: ${(p) => p.theme.colors.subText};
        }
      }
    }
  }
`;
