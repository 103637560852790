import { Spin } from 'antd';
import { useEffect } from 'react';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';

import { authAPI } from '@api';
import { AppRoute, AppToast, AuthRoute } from '@components';
import { enumThemeMode } from '@configs';
import { IRoute, RootResponse } from '@interfaces';
import { DefaultLayout } from '@layouts';
import { themes, useTheme } from '@theme';
import {
  logout,
  selectAppLoading,
  selectAuth,
  selectThemeMode,
  setVersion,
  useAppDispatch,
  useAppSelector,
} from 'src/redux';
import { authRoutes, routes } from 'src/routes';

interface IProps {
  setTheme: (theme: any) => void;
}
export const ModuleMain = (props: IProps) => {
  const { setTheme } = props;
  const { setCurrentTheme } = useTheme();
  const themeMode = useAppSelector(selectThemeMode);
  const appLoading = useAppSelector(selectAppLoading);
  const { version } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const checkVersion = async () => {
    try {
      const response: RootResponse<string> = await authAPI.checkVersion();
      if (!version || version !== response.data) {
        dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
      }
      dispatch(setVersion(response.data));
    } catch (error) {}
  };
  useEffect(() => {
    checkVersion();
    updateTheme();
  }, [themeMode]);

  const updateTheme = () => {
    if (themeMode === enumThemeMode.LIGHT) {
      setTheme(themes.theme.light);
    } else {
      setTheme(themes.theme.dark);
    }
  };

  // DEV: Update theme when dev
  // if (process.env.NODE_ENV === 'development') {
  useEffect(() => {
    if (themeMode === enumThemeMode.LIGHT) {
      setTheme(themes.theme.light);
      setCurrentTheme(themes.theme.light);
    } else {
      setTheme(themes.theme.dark);
      setCurrentTheme(themes.theme.dark);
    }
  }, []);
  // }

  return (
    <Spin className="app-loading" spinning={appLoading} size="large">
      <BrowserRouter>
        <Routes>
          {/* Main Route */}
          <Route path="/" element={<AppRoute />}>
            {routes.map((route: IRoute, index: number) => {
              const Page = route.page;
              const Layout = route.layout || DefaultLayout;
              if (route.auth) return;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout {...route}>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          </Route>
          {/* Auth Route */}
          <Route path="/" element={<AuthRoute />}>
            {authRoutes.map((route: IRoute, index: number) => {
              const Page = route.page;
              const Layout = route.layout || DefaultLayout;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout {...route}>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          </Route>
        </Routes>
        <AppToast />
      </BrowserRouter>
    </Spin>
  );
};
