import { PromotionPayload, PromotionalProductPayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const promotionAPI = {
  createPromotion: (body: PromotionPayload) => {
    const url = '/promotions';
    return ApiClient.post(url, body);
  },
  getPromotions: (params?: DefaultApiParams) => {
    const url = '/promotions';
    return ApiClient.get(url, { params });
  },
  getPromotion: (id: string) => {
    const url = `/promotions/${id}`;
    return ApiClient.get(url);
  },
  updatePromotion: (body: PromotionPayload, id: string) => {
    const url = `/promotions/${id}`;
    return ApiClient.put(url, body);
  },
  addPromotionalProducts: (body: PromotionalProductPayload) => {
    const url = `/promotions/add-product`;
    return ApiClient.post(url, body);
  },
  deletePromotionalProducts: (promotionId: string, productId: string) => {
    const url = `/promotions/${promotionId}/remove-product?product_id=${productId}`;
    return ApiClient.post(url);
  },
  getPromotionalProducts: (id: string, params?: DefaultApiParams) => {
    const url = `/promotions/${id}/product`;
    return ApiClient.get(url, { params });
  },
  activatePromotion: (id: string, value: boolean) => {
    const url = `/promotions/${id}/active?status=${value}`;
    return ApiClient.put(url);
  },
  importPromotionProduct: (id: string, form: FormData) => {
    const url = `/promotions/${id}/import-product`;
    return ApiClient.post(url, form);
  },
};
