import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DefaultApiParams, categoriesAPI, productAPI, warehouseAPI } from '@api';
import {
  HideIcon,
  PopoverPopup,
  ProductListSection,
  SharedButton,
  SharedImage,
  TickBox,
  ViewIcon,
} from '@components';
import {
  DEFAULT_WAREHOUSE_FILTER_ITEM,
  MAIN_THEME_DATA,
  MAX_ITEM_PER_PAGE,
  NOT_UPDATE,
  PATH_CREATE_PRODUCT,
  PATH_PRODUCT,
} from '@configs';
import {
  AutocompleteItem,
  Category,
  CategoryListRoot,
  ChildColumn,
  Classification,
  ProductColumn,
  ProductData,
  ProductFilterDropdowns,
  ProductRoot,
  RootResponse,
  Warehouse,
  WarehouseRoot,
} from '@interfaces';
import {
  selectApp,
  setCurrentPage,
  setCurrentProductSearch,
  setCurrentWarehouse,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import { Table, TableColumnsType } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { debounce } from 'lodash';
import { styled } from 'styled-components';
import { useImmer } from 'use-immer';
const COLOR_ATTR_ID = '95cd46bb-0a07-44c1-ad83-fe30f75f1708';
const SIZE_ATTR_ID = 'b32d65a7-c18d-488a-ac80-a673b239fb20';

export const ProductModule = () => {
  const { currentPage, themeMode, currentWarehouse, currentProductSearch } =
    useAppSelector(selectApp);
  const [products, setProducts] = useImmer<ProductData>({
    limit: 0,
    count: 0,
    data: [],
    total_amount_remain: 0,
  });
  const [dropdownData, setDropdownData] = useImmer<ProductFilterDropdowns>({
    warehouses: [],
    categories: [],
  });
  const columns: ColumnsType<ProductColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        key: 'no',
        width: '3%',
        render: (text, record, index) => index + (currentPage || 0) * 10 + 1,
      },
      {
        title: 'Tên',
        render: (value: any) => {
          return <span className="banner-desc">{value?.name}</span>;
        },
      },
      {
        title: 'Kho',
        dataIndex: 'warehouse',
        key: 'warehouse',
      },
      {
        title: 'Ảnh bìa',
        dataIndex: 'cover',
        key: 'cover',
        render: (value: any) => {
          return <SharedImage src={value} />;
        },
      },
      { title: 'Danh mục', dataIndex: 'category', key: 'category' },
      { title: 'Hàng tồn', dataIndex: 'remain', key: 'remain' },
      { title: 'Đã bán', dataIndex: 'sold_quantity', key: 'sold_quantity' },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        width: '26%',
        render: (value) => {
          const isActive = value.activated;
          return (
            <div className="flex items-center justify-center">
              <div className="flex items-center lg:justify-center text-black table-actions-bt">
                <SharedButton
                  textColor="white"
                  // backgroundColor={MAIN_THEME_DATA.mainColor}
                  className="edit-btn btn-edit table-actions-btn"
                  prevIcon={<TickBox color="white" />}
                  text="Sửa"
                  onClick={() => value.onEdit(value?.id)}
                />
              </div>
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">
                      {isActive
                        ? `Bạn có muốn ẩn sản phẩm này không? (Sản phẩm này sẽ không xuất hiện trên trang bán hàng)`
                        : 'Bạn có muốn hiển thị sản phẩm này không?'}
                    </p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onHide();
                }}
              >
                <SharedButton
                  className={`btn-action ${isActive ? `btn-delete` : `btn-edit`}`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={isActive ? <HideIcon color={'white'} /> : <ViewIcon color={'white'} />}
                  textColor="white"
                  text={isActive ? 'Ẩn' : 'Hiển thị'}
                />
              </PopoverPopup>
            </div>
          );
        },
      },
    ];
  }, [currentPage]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [payload, setPayload] = useState<DefaultApiParams>({
    page: currentPage || 0,
    size: 10,
    keyword: currentProductSearch ?? '',
    warehouse_id: currentWarehouse?.item?.id !== '' ? currentWarehouse?.item?.id : null,
  });
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [dataChange, setDataChange] = useState<number>(0);

  const onCreate = () => {
    navigate(PATH_CREATE_PRODUCT);
  };

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const onDataChange = (no: number) => {
    setDataChange(no);
  };
  const convertData = (input: Classification[]) => {
    const extractedData: ChildColumn[] = [];
    input.forEach((item) => {
      const { value, sub_classifications } = item;
      const productItem = item?.product_item;
      if (productItem) {
        extractedData.push({
          parent: item.attribute_id === COLOR_ATTR_ID ? item.value : NOT_UPDATE,
          child: item.attribute_id === SIZE_ATTR_ID ? item.value : NOT_UPDATE,
          b_price: productItem.import_price.toLocaleString('it-IT', {
            style: 'currency',
            currency: 'VND',
          }),
          s_price: productItem.selling_price.toLocaleString('it-IT', {
            style: 'currency',
            currency: 'VND',
          }),
          quantity: productItem.quantity.toString(),
          code: productItem.code,
          sold_quantity: productItem.solded_quantity,
        });
      } else
        sub_classifications.forEach((subItem) => {
          const { value: val, product_item, attribute_id } = subItem;
          const { import_price, selling_price, quantity, code } = product_item;
          extractedData.push({
            parent: item.value,
            child: subItem.value,
            b_price: import_price.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }),
            s_price: selling_price.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }),
            quantity: quantity.toString(),
            code,
            sold_quantity: subItem.product_item.solded_quantity,
          });
        });
    });
    return extractedData;
  };
  const getRemainProduct = (classifications: Classification[]) => {
    let res: number[] = [];
    const productItems = classifications
      .map((item) => item.product_item)
      .filter((item) => item !== null);
    if (productItems.length) {
      res = productItems.map((item) => (item ? item.quantity : 0));
    } else {
      res = classifications.flatMap((classification) =>
        classification.sub_classifications.map(
          (subClassification) => subClassification.product_item.quantity,
        ),
      );
    }
    return res.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };
  const getDropdownData = async () => {
    try {
      dispatch(setTableLoading(true));
      const warehouses: WarehouseRoot = await warehouseAPI.getWarehouses({ all: true });
      const categories: CategoryListRoot = await categoriesAPI.getCategories({ all: true });
      setDropdownData((draft) => {
        draft.warehouses = warehouses.data.warehouse_response.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
        draft.categories = categories.data.categories.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const getData = useCallback(async () => {
    try {
      dispatch(setTableLoading(true));
      const newPayload = { ...payload, keyword: payload.keyword?.trim() };
      if (!payload.warehouse_id) {
        delete newPayload.warehouse_id;
      }
      const res: ProductRoot = await productAPI.getProducts(newPayload);
      const data = res.data?.products;
      const newResponse: ProductColumn[] = data.map((item, index) => {
        return {
          key: item.id,
          no: index + 1,
          name: item.name,
          cover: item.images?.[0]?.url,
          warehouse: item.warehouse.name,
          category: item.categories.map((item) => item.name).join(', '),
          remain: item.total_quantity || 0,
          sold_quantity: item?.solded_quantity || 0,
          onEdit: () => {
            onEdit(item.id);
          },
          onHide: () => {
            updateProductStatus(!item.activated, item.id);
          },
          childColData: [],
          parentAttr: '',
          activated: item.activated,
        };
      });
      setProducts({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.amount,
        data: newResponse,
        total_amount_remain: res.data.total_amount_remain,
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setTableLoading(false));
    }
  }, [payload]);
  const onExpand = async (record: any) => {
    try {
      dispatch(setLoading(true));
      const res: RootResponse<Classification[]> = await productAPI.getClassification(record.key);
      setProducts((d) => {
        const foundIndex = d.data.findIndex((item) => item.key === record.key);
        if (foundIndex !== -1) {
          d.data[foundIndex].childColData =
            res.data.length > 0
              ? convertData(res.data)
              : ([
                  {
                    code: record.code,
                    parent: NOT_UPDATE,
                    child: NOT_UPDATE,
                    b_price: (record?.import_price || 0).toLocaleString('it-IT', {
                      style: 'currency',
                      currency: 'VND',
                    }),
                    s_price: (record?.selling_price || 0).toLocaleString('it-IT', {
                      style: 'currency',
                      currency: 'VND',
                    }),
                    quantity: record?.quantity?.toString() || 0,
                    sold_quantity: record?.solded_quantity || 0,
                  },
                ] as ChildColumn[]);
          d.data[foundIndex].parentAttr = res.data.length > 0 ? res.data?.[0]?.attribute_id : '';
        }
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onEdit = (id: string) => {
    navigate(PATH_PRODUCT + `/edit/${id}`);
  };
  const onDelete = (id: number | string) => {
    try {
      toast.success('Delete successfully!', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
      updateNewData();
    } catch (error) {}
  };

  const onNavigateToCreatePage = () => {};

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
    dispatch(setCurrentPage(Number(page)));
  };
  const updateNewData = () => {
    getData();
  };

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, size });
  };
  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value, page: 0 });
    dispatch(setCurrentProductSearch(value));
  }, 600);
  const onChangeWarehouse = (item: AutocompleteItem<Warehouse>) => {
    if (!item.value) {
      const newPayload = {
        ...payload,
      };
      delete newPayload.warehouse_id;
      setPayload({ ...newPayload });
      dispatch(
        setCurrentWarehouse({
          label: '',
          value: '',
          item: DEFAULT_WAREHOUSE_FILTER_ITEM,
        }),
      );
    } else {
      setPayload({ ...payload, warehouse_id: item.item.id });
      dispatch(setCurrentWarehouse(item));
    }
  };
  const onChangeCategory = (item: AutocompleteItem<Category>) => {
    if (!item.value) {
      const newPayload = {
        ...payload,
      };
      delete newPayload.category_id;
      setPayload({ ...newPayload });
    } else setPayload({ ...payload, category_id: item.item.id });
  };
  const updateProductStatus = async (status: boolean, id: string) => {
    try {
      dispatch(setLoading(true));
      const res = await productAPI.updateProductStatus(status, id);
      await getData();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);
  useEffect(() => {
    getData();
  }, [payload]);
  const renderChildRow = (record: ProductColumn) => {
    const columns: TableColumnsType<any> =
      record.parentAttr === COLOR_ATTR_ID
        ? [
            { title: 'Mã sản phẩm', dataIndex: 'code', key: 'code' },
            { title: 'Màu', dataIndex: 'parent', key: 'parent' },
            { title: 'Size', dataIndex: 'child', key: 'child' },
            { title: 'Giá mua', dataIndex: 'b_price', key: 'b_price' },
            { title: 'Giá bán', dataIndex: 's_price', key: 's_price' },
            { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity' },
            { title: 'Đã bán', dataIndex: 'sold_quantity', key: 'sold_quantity' },
          ]
        : [
            { title: 'Mã sản phẩm', dataIndex: 'code', key: 'code' },
            { title: 'Size', dataIndex: 'parent', key: 'parent' },
            { title: 'Màu', dataIndex: 'child', key: 'child' },
            { title: 'Giá mua', dataIndex: 'b_price', key: 'b_price' },
            { title: 'Giá bán', dataIndex: 's_price', key: 's_price' },
            { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity' },
            { title: 'Đã bán', dataIndex: 'sold_quantity', key: 'sold_quantity' },
          ];

    if (!record.childColData.length) return <></>;
    return (
      <StyledTableWrapper>
        <Table
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
          bordered={false}
          columns={columns}
          dataSource={record.childColData}
          pagination={false}
        />
      </StyledTableWrapper>
    );
  };
  return (
    <ProductListSection
      productFilterData={dropdownData}
      currentPage={payload?.page}
      products={products}
      onCreate={onCreate}
      onPageChange={handleChangePage}
      updateData={updateNewData}
      onCloseCreateModal={onCloseCreateModal}
      onDataChange={onDataChange}
      onChangePageSize={handleChangePageSize}
      renderChildRow={renderChildRow}
      columns={columns}
      onSearch={onSearch}
      onChangeWarehouse={onChangeWarehouse}
      onChangeCategory={onChangeCategory}
      currentWarehouse={currentWarehouse}
      onExpand={onExpand}
    />
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
`;
const StyledTableWrapper = styled.div`
  .ant-table-cell {
    padding: 2rem;
  }
  .ant-table table {
    border-radius: 8px 8px 0 0;
  }
  .ant-table-content {
    padding: 2rem;
    background-color: #fbfbfb;
  }
`;
