import {
  AppModal,
  SharedAutocomplete,
  SharedButton,
  SharedCheckbox,
  SharedToggle,
  ShareInput,
  ShareTimeSelect,
} from '@components';
import { MAIN_THEME_DATA, OPENING_TYPE, ruleTimeFormat } from '@configs';
import {
  Feature,
  AutocompleteData,
  SelectedAddress,
  AutocompleteItem,
  CreateWarehousePayload,
  AddressItem,
} from '@interfaces';
import { AddressAutofill } from '@mapbox/search-js-react';
import { themes, useTheme } from '@theme';
import { capitalizeFirstLetter, LogApp } from '@utils';
import { Radio, RadioChangeEvent } from 'antd';
import moment from 'moment';
import React, { BaseSyntheticEvent } from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import styled from 'styled-components';
interface WarehouseFormProps {
  register: UseFormRegister<FieldValues>;
  onCreateWarehouse: (
    e?: BaseSyntheticEvent<CreateWarehousePayload, any, any> | undefined,
  ) => Promise<void>;
  errors: FieldErrors<FieldValues>;
  goBack: () => void;
  onSelectProvince: (value: string) => void;
  onSelectDistrict: (value: string) => void;
  onSelectWard: (value: string) => void;
  onModalInteract: (value: boolean, id: string) => void;
  autocompleteData: AutocompleteData;
  selectedAddress?: SelectedAddress;
  modalVisible?: boolean;
  addressErrors?: any;
  isEdit?: boolean;
  defaultAddress: any;
  onCloseModal: () => void;
}
export const WarehouseForm = ({
  register,
  onCreateWarehouse,
  errors,
  goBack,
  autocompleteData,
  onSelectProvince,
  selectedAddress,
  onSelectDistrict,
  onSelectWard,
  modalVisible,
  onModalInteract,
  addressErrors,
  isEdit,
  defaultAddress,
  onCloseModal,
}: WarehouseFormProps) => {
  const { theme } = useTheme();
  return (
    <StyledWarehouseForm>
      {modalVisible && (
        <AppModal open={modalVisible}>
          {/* @ts-ignore */}
          <form onSubmit={onCreateWarehouse} className="modal">
            <h2>{isEdit ? 'Sửa' : 'Thêm'} thông tin kho</h2>
            <div className="warehouse-form">
              <div className="base-info-block">
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="name"
                  label="Tên kho"
                  placeholder="Nhập tên kho"
                  required
                  errors={errors['name']?.message}
                />
                <ShareInput
                  containerClassName="input-wrapper ml"
                  register={register}
                  name="code"
                  label="Mã kho"
                  placeholder="Nhập mã kho"
                  required
                  errors={errors['code']?.message}
                />
              </div>
              <div className="base-info-block">
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="phone_number"
                  label="Số điện thoại"
                  placeholder="Nhập số điện thoại"
                  required
                  errors={errors['phone_number']?.message}
                />
              </div>
              <div className="address-block">
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectProvince(value.item.code);
                  }}
                  containerClassName="input-wrapper address-input mx"
                  data={autocompleteData.provinces}
                  label={'Tỉnh/Thành phố:'}
                  placeholder="Nhập tỉnh/thành phố"
                  defaultValue={defaultAddress?.province?.name}
                  key={`${defaultAddress?.province?.code}:province`}
                  required
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  errors={addressErrors?.province_code}
                />
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectDistrict(value.item.code);
                  }}
                  containerClassName="input-wrapper address-input mx"
                  data={autocompleteData.districts}
                  label={'Quận/Huyện:'}
                  placeholder="Nhập quận/huyện"
                  defaultValue={defaultAddress?.district?.name}
                  key={`${defaultAddress?.district?.code}:district`}
                  required
                  errors={addressErrors?.district_code}
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <SharedAutocomplete
                  onSelect={(value) => {
                    onSelectWard(value.item.code);
                  }}
                  containerClassName="input-wrapper address-input"
                  data={autocompleteData.wards}
                  label={'Phường/Xã:'}
                  placeholder="Nhập phường/xã"
                  defaultValue={defaultAddress?.ward?.name}
                  key={`${defaultAddress?.ward?.code}:ward`}
                  required
                  errors={addressErrors?.ward_code}
                  filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
              <div className="base-info-block">
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="address_detail"
                  label="Địa chỉ chi tiết"
                  placeholder="Nhập địa chỉ chi tiết"
                  required
                  errors={errors['address_detail']?.message}
                />
              </div>
            </div>
            <div className="button-wrapper">
              <SharedButton
                onClick={() => {
                  onCloseModal();
                }}
                typeHtml="button"
                className="btn-save btn-cancel"
                backgroundColor="transparent"
                textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
                borderColor={
                  theme?.colors?.button?.border || themes.theme.light.colors.button.border
                }
                text={'Thoát'}
              />
              <SharedButton
                typeHtml="submit"
                // onClick={onCreateWarehouse}
                className="btn-save"
                textColor="white"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                text={'Lưu'}
              />
            </div>
          </form>
        </AppModal>
      )}
    </StyledWarehouseForm>
  );
};
const StyledWarehouseModal = styled.div``;
const StyledWarehouseForm = styled.div`
  .modal {
    width: 100rem;
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .input-wrapper {
    margin-top: 1rem;
  }
  .ml {
    margin-left: 1rem;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    .btn-save {
      align-self: center;
      width: fit-content;
      padding: 1rem 2rem;
    }
    .btn-cancel {
      margin-right: 1rem;
    }
  }
  .warehouse-form {
    .base-info-block {
      display: flex;
      flex-direction: row;
    }
    .address-block {
      display: flex;
      flex-direction: row;
      .mx {
        margin-right: 1rem;
      }
      .address-input {
        flex: 1;
      }
      .sub-address-input {
        flex: 1;
      }
    }
    .time-picker {
      padding: 0.6rem 1.2rem;
      /* height: 100%;
    width: 100%; */
      box-shadow: none !important;
      outline: none !important;
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    .dropdown {
      margin-top: 1rem;
      .ant-select-selector {
        padding: 0.4rem !important;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 38px;
      padding: 0 11px;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .branch-list-block {
      display: flex;
      justify-content: center;
      .branch-list-wrapper {
        h4 {
          margin-bottom: 1rem;
        }
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        background-color: #f1f5f9;
        padding: 1rem 0rem;
        .selected-list {
          width: 40%;
        }
        .available-list {
          width: 40%;
        }
        .move-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .move-btn {
            padding: 0.8rem 1rem;
            width: fit-content;
          }
        }
      }
    }
    .opening-hour-block {
      width: 50%;
      display: flex;
      flex-direction: column;
      .checkbox-inner {
        min-width: 10rem;
        /* width: 20%; */
      }
      .opening-hour-item {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
      .custom-opening {
        width: 100%;
        .custom-opening-item {
          display: flex;
          flex-direction: row;
          margin-top: 1rem;
          .title {
            width: 20%;
          }
          .actions {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .custom-opening-hour-item {
              display: flex;
              flex-direction: row;
              width: 70%;
            }
          }
        }
      }
    }
  }
`;
