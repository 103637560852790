import { IConCancel } from '@components';
import { ProductImage } from '@interfaces';
import { UploadImageModule } from '@modules';
import { useTheme } from '@theme';
import styled from 'styled-components';
interface ProductImagesProps {
  productImages: ProductImage[];
  changeSelectedImage?: (value: any, index: number) => void;
  deleteImage: (image: ProductImage) => void;
  uploadImageRef: any;
}
export const ProductImages = ({
  productImages,
  changeSelectedImage,
  deleteImage,
  uploadImageRef,
}: ProductImagesProps) => {
  const { theme } = useTheme();
  return (
    <StyledProductImages>
      {productImages.map((item, index) => {
        return (
          <div key={item.key} className="image-item">
            <UploadImageModule
              ref={uploadImageRef}
              typeUpload="image"
              defaultUrl={item?.url}
              onUploadEnd={(file) => {
                changeSelectedImage?.(file, index);
              }}
            />
            <p className="mt-3">Ảnh số {index + 1}</p>
            {(item.file || item.url) && (
              <IConCancel
                onClick={() => {
                  deleteImage(item);
                }}
                className="delete"
              />
            )}
          </div>
        );
      })}
    </StyledProductImages>
  );
};
const StyledProductImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  flex-wrap: wrap;
  .image-item {
    position: relative;
    width: calc(100% / 7);
    .delete {
      position: absolute;
      top: -15px;
      right: -15px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
