import { DefaultApiParams, categoriesAPI, productAPI } from '@api';
import {
  CategoryData,
  CategoryTable,
  HideIcon,
  PopoverPopup,
  SharedButton,
  TickBox,
  ViewIcon,
} from '@components';
import { MAIN_THEME_DATA, MAX_ITEM_PER_PAGE } from '@configs';
import {
  Category,
  CategoryListRoot,
  CreateCategoryRoot,
  ProductImage,
  ProductImageRoot,
  SubCategoryColumn,
} from '@interfaces';
import {
  selectApp,
  setCurrentPage,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import { TableColumnsType } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên danh mục'),
});
export type ModalState = {
  id: string;
  open: boolean;
};
export const CategoryModule = () => {
  const { register, handleSubmit, setValue, reset, getValues, formState, control } =
    useForm<FieldValues>({
      // resolver: yupResolver(schema),
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_category',
  });
  const dispatch = useAppDispatch();
  const { themeMode, currentPage } = useAppSelector(selectApp);

  const navigate = useNavigate();
  const [categories, setCategories] = useImmer<CategoryData>({
    limit: 10,
    count: 1,
    data: [],
  });
  const [payload, setPayload] = useState<DefaultApiParams>({
    page: currentPage || 0,
    size: 10,
    keyword: '',
  });

  const [modalState, setModalState] = useState<ModalState>({
    id: '',
    open: false,
  });
  const isEdit = modalState.id !== '';
  const onModalInteract = (value: ModalState) => {
    setModalState(value);
  };
  const onOpenCreateModal = () => {
    onModalInteract({ open: true, id: '' });
    reset({
      name: '',
    });
    setLogo((d) => {
      d.url = '';
      d.file = null;
    });
  };
  const uploadImageRef = useRef<any>(null);
  const [logo, setLogo] = useImmer<ProductImage>({
    id: '',
    file: null,
    url: '',
    name: '',
    index: 0,
  });
  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: 'STT',
        // dataIndex: 'no',
        // key: 'no',
        render: (text, record, index) => index + currentPage * Number(payload?.size) + 1,
        width: '10%',
      },
      { title: 'Tên danh mục', dataIndex: 'categoryName', key: 'categoryName' },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        render: (value: any) => {
          const isActive = value.activated;
          return (
            <StyledEditBtn>
              <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-edit  mr-3"
                prevIcon={<TickBox color="white" />}
                text="Sửa"
                onClick={() => {
                  value.onEdit();
                }}
              />
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">
                      {isActive
                        ? `Bạn có muốn ẩn danh mục này không? (Danh mục này sẽ không xuất hiện trên trang bán hàng)`
                        : 'Bạn có muốn hiển thị danh mục này không?'}
                    </p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onChangeStatus();
                }}
              >
                <SharedButton
                  className={`edit-btn ${isActive ? `btn-delete` : `btn-edit`}  mr-3`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={
                    isActive ? (
                      <HideIcon color={'white'} size={16} />
                    ) : (
                      <ViewIcon size={16} color={'white'} />
                    )
                  }
                  textColor="white"
                  text={isActive ? 'Ẩn' : 'Hiển thị'}
                />
              </PopoverPopup>
              {/* <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">Bạn có muốn xoá danh mục này không?</p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onDelete();
                }}
              >
                <SharedButton
                  className={`edit-btn btn-delete`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={<Trash size={16} color={'white'} />}
                  textColor="white"
                  text={'Xoá'}
                />
              </PopoverPopup> */}
            </StyledEditBtn>
          );
        },
      },
    ];
  }, [currentPage]);

  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value === '' ? null : value.trim(), page: 0 });
  }, 500);
  const onEdit = (item: Category) => {
    setLogo((d) => {
      if (item.image) d.url = item.image.url;
      else {
        d.url = '';
        d.file = null;
      }
    });
    onModalInteract({ open: true, id: item.id });
    reset({ name: item.name });
    item.sub_categories.forEach((val) => {
      append({ id: val.id, value: val.name });
    });
  };
  const onPageChange = (page: number) => {
    dispatch(setCurrentPage(Number(page)));
    setPayload({ ...payload, page });
  };
  const getCategories = useCallback(
    async (needTableLoading = true) => {
      try {
        if (needTableLoading) dispatch(setTableLoading(true));
        setCategories({ ...categories, data: [] });
        const res: CategoryListRoot = await categoriesAPI.getCategories({
          ...payload,
          keyword: payload.keyword === '' ? null : payload.keyword,
        });
        const newResponse: SubCategoryColumn[] = res.data.categories.map((item, index) => {
          return {
            key: item?.id,
            no: index + 1,
            categoryName: item.name,
            onEdit: () => {
              onEdit(item);
            },
            onDelete: () => {
              onDeleteParentCategory(item.id);
            },
            onChangeStatus: () => {
              updateCategoryStatus(!item.activated, item.id);
            },
            childColData:
              item?.sub_categories?.map((val, pos) => {
                return {
                  no: pos + 1,
                  categoryName: val.name,
                };
              }) || [],
            activated: item.activated,
          };
        });
        setCategories({
          limit: MAX_ITEM_PER_PAGE,
          count: res.data.amount,
          data: newResponse,
        });
      } catch (error) {
        LogApp(error, 'kuku');
      } finally {
        if (needTableLoading) dispatch(setTableLoading(false));
      }
    },
    [payload.keyword, payload.page, payload.size],
  );

  const handleChangePageSize = (size: number) => {
    setPayload({ ...payload, size });
  };
  const updateCategoryStatus = async (status: boolean, id: string) => {
    try {
      dispatch(setLoading(true));
      const res = await categoriesAPI.updateCategoryStatus(status, id);
      await getCategories();
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    getCategories();
  }, [payload.keyword, payload.page, payload.size]);
  // useCancelRequest(getTiers, () => LogApp(true));

  const onCreateCategory = handleSubmit(async (data) => {
    const sub_categories = data.sub_category?.map((item: any) => {
      if (isEdit)
        return {
          id: item.id,
          name: item.value,
          parent_id: modalState.id,
        };
      return {
        name: item.value,
      };
    });
    try {
      dispatch(setLoading(true));
      const payload = {
        id: modalState.id,
        name: data.name,
        sub_categories,
        image_id: logo.id,
      };
      const res: CreateCategoryRoot = !isEdit
        ? await categoriesAPI.createCategory(payload)
        : await categoriesAPI.updateCategory(modalState.id, payload);
      await getCategories(false);
      onModalInteract({
        id: '',
        open: false,
      });
      toast.success(res.message, {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  });
  const renderChildRow = (record: SubCategoryColumn) => {
    const columns: TableColumnsType<any> = [
      { title: 'STT', dataIndex: 'no', key: 'no' },
      { title: 'Tên', dataIndex: 'categoryName', key: 'categoryName' },
    ];
    if (!record.childColData.length) return <></>;
    return (
      <StyledTableWrapper>
        <Table
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
          bordered={false}
          columns={columns}
          dataSource={record.childColData}
          pagination={false}
        />
      </StyledTableWrapper>
    );
  };
  const onDeleteCategory = async (index: number) => {
    try {
      const res = await categoriesAPI.deleteCategory(getValues(`sub_category.${index}.id`));
      remove(index);
      getCategories(false);
    } catch (error) {}
  };
  const onDeleteParentCategory = async (id: string) => {
    try {
      const res = await categoriesAPI.deleteCategory(id || '');
      getCategories(false);
    } catch (error) {}
  };
  const uploadCategoryLogo = async (file: any) => {
    try {
      const logo = new FormData();
      if (file !== null) {
        logo.append('files', file);
        const res: ProductImageRoot = await productAPI.uploadImages(logo);
        setLogo({
          url: res.data.at(0)?.url ?? '',
          file,
          name: '',
          id: res.data.at(0)?.id ?? '',
          index: 0,
        });
      }
    } catch (error) {
      LogApp(error);
    }
  };
  const deleteLogo = () => {
    setLogo({
      url: '',
      file: null,
      name: '',
      //@ts-ignore
      id: null,
      index: 0,
    });
  };
  return (
    <>
      <CategoryTable
        onSearch={onSearch}
        columns={columns}
        categories={categories}
        currentPage={payload.page}
        onPageChange={onPageChange}
        onChangePageSize={handleChangePageSize}
        onModalInteract={onModalInteract}
        modalOpened={modalState.open}
        register={register}
        errors={formState.errors}
        onCreateCategory={onCreateCategory}
        onOpenCreateModal={onOpenCreateModal}
        isEdit={isEdit}
        fields={fields}
        append={append}
        remove={remove}
        renderChildRow={renderChildRow}
        onDeleteCategory={onDeleteCategory}
        uploadCategoryLogo={uploadCategoryLogo}
        uploadImageRef={uploadImageRef}
        logo={logo}
        deleteLogo={deleteLogo}
      />
    </>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
const StyledEditBtn = styled.div`
  display: flex;
  justify-content: center;
  .edit-btn {
    padding: 0.8rem 1.8rem;
    width: fit-content;
    align-self: center;
  }
`;
const StyledTableWrapper = styled.div`
  .ant-table-cell {
    padding: 2rem;
  }
  .ant-table table {
    border-radius: 8px 8px 0 0;
  }
  .ant-table-content {
    padding: 2rem;
    background-color: #fbfbfb;
  }
`;
