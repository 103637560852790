import { ShareInput, SharedButton } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import moment from 'moment';
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { styled } from 'styled-components';
interface OrderInfoProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  updateAddon: () => void;
  syncOrder: () => void;
}
export const OrderInfo = (props: OrderInfoProps) => {
  const { control, register, setValue, getValues, updateAddon, syncOrder } = props;
  return (
    <StyledOrderInfo>
      <SharedButton
        onClick={syncOrder}
        text={'Đồng bộ đơn hàng'}
        className="sync-btn"
        backgroundColor={MAIN_THEME_DATA.mainColor}
        btnStyle="basic"
      />
      <div className="code-block">
        <ShareInput
          register={register}
          containerClassName="inp-wrapper input-mr"
          name="code"
          label="Mã đơn hàng"
          disabled
        />
        <ShareInput
          register={register}
          containerClassName="inp-wrapper"
          name="status"
          label="Trạng thái đơn hàng"
          disabled
        />
      </div>

      <div className="money-block">
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          control={control}
          isNumericFormat
          containerClassName="inp-wrapper"
          name="total_money"
          label="Tổng tiền phải trả"
          disabled
        />
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          control={control}
          isNumericFormat
          containerClassName="inp-wrapper"
          name="money_deducted"
          label="Tổng tiền chiết khấu (Chiết khấu + Khuyến mãi)"
          disabled
        />
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          control={control}
          isNumericFormat
          containerClassName="inp-wrapper"
          name="money_paid"
          label="Đã trả"
          disabled
        />
      </div>
      <div className="debt-block">
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          containerClassName="inp-wrapper"
          control={control}
          isNumericFormat
          name="money_debt"
          label="Còn nợ"
          disabled
        />
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          containerClassName="inp-wrapper"
          control={control}
          isNumericFormat
          name="total_money_debt"
          label={`Tổng nợ cho đến ngày ${moment().format('DD/MM/YYYY')}`}
          disabled
        />
        <ShareInput
          prefix="đ"
          inputDefaultStyle="preTab"
          containerClassName="inp-wrapper"
          control={control}
          isNumericFormat
          name="total_money_reward_cumulative"
          label={`Tổng tiền thưởng tích lũy tháng ${moment().month() + 1}`}
          disabled
        />
        <div className="add-on">
          <ShareInput
            prefix="đ"
            inputDefaultStyle="preTab"
            // containerClassName="inp-wrapper"
            control={control}
            isNumericFormat
            name="money_addon"
            label={`Phí vận chuyển (tính thành công nợ)`}
          />
          <SharedButton
            onClick={() => {
              updateAddon();
            }}
            backgroundColor={MAIN_THEME_DATA.mainColor}
            btnStyle="basic"
            text={'Cập nhật'}
          />
        </div>
      </div>
      <textarea placeholder="Ghi chú" {...register('note')} />
    </StyledOrderInfo>
  );
};
const StyledOrderInfo = styled.div`
  .sync-btn {
    margin-bottom: 1rem;
    width: 15rem;
  }
  textarea {
    width: 100%;
    border-width: 1px;
    margin-top: 2rem;
    padding: 1rem 0 0 1rem;
    height: 9.6rem;
    background-color: #fafafa;
  }
  .inner-input {
    font-weight: 500;
  }
  .input-mr {
    margin-right: 1rem;
  }
  .code-block {
    display: flex;
    .inp-wrapper {
      width: 50%;
    }
  }

  .money-block {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .inp-wrapper {
      width: 30%;
    }
  }
  .debt-block {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .inp-wrapper {
      width: 20%;
    }
  }
  .add-on {
    width: 30%;
    display: flex;
    align-items: flex-end;
    .btn {
      padding: 1rem 0;
      width: 50%;
      margin-left: 1rem;
    }
  }
`;
