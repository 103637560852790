import { DefaultApiParams, warehouseAPI } from '@api';
import { PopoverPopup, SharedButton, TickBox, Trash, WarehouseTable } from '@components';
import { INITIAL_PAGE, MAIN_THEME_DATA, MAX_ITEM_PER_PAGE } from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateWarehouseResponse,
  CreateWarehouseRoot,
  WarehouseColumn,
  WarehouseData,
  WarehouseRoot,
} from '@interfaces';
import { selectApp, setCurrentPage, setTableLoading, useAppDispatch, useAppSelector } from '@redux';
import { LogApp } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import * as yup from 'yup';
const schema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên kho'),
  code: yup.string().required('Vui lòng nhập mã kho'),
  phone_number: yup.string().required('Vui lòng nhập số điện thoại'),
  address_detail: yup.string().required('Vui lòng nhập địa chỉ chi tiết'),
});
export const WarehouseModule = () => {
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const navigate = useNavigate();
  const [defaultAddress, setDefaultAddress] = useImmer({
    province: {
      code: '',
      name: '',
    },
    district: {
      code: '',
      name: '',
    },
    ward: {
      code: '',
      name: '',
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      code: '',
      phone_number: '',
      address_detail: '',
    },
  });
  const [warehousePayload, setWarehousePayload] = useState<DefaultApiParams>({
    page: currentPage || INITIAL_PAGE,
    keyword: '',
    size: 10,
  });
  const [modalData, setModalData] = useState({
    open: false,
    id: '',
  });
  const [warehouses, setWarehouses] = useImmer<WarehouseData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const onModalInteract = (value: boolean, id: string) => {
    setModalData({
      open: value,
      id,
    });
  };
  const isEdit = modalData.id !== '';
  const dispatch = useAppDispatch();
  const columns: ColumnsType<WarehouseColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + currentPage * Number(warehousePayload?.size) + 1,
      },
      { title: 'Mã kho', dataIndex: 'warehouse_code', key: 'warehouse_code' },
      { title: 'Tên', dataIndex: 'name', key: 'name' },
      { title: 'Địa chỉ', dataIndex: 'address', key: 'address' },
      { title: 'Số điện thoại', dataIndex: 'phone', key: 'phone' },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        render: (value: any) => {
          return (
            <StyledEditBtn>
              <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-edit"
                prevIcon={<TickBox color="white" />}
                text="Sửa"
                onClick={() => {
                  value.onEdit();
                }}
              />
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">Bạn có muốn xoá kho này không?</p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {
                  value.onHide();
                }}
              >
                <SharedButton
                  className="edit-btn btn-delete mx-3"
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={<Trash color={'white'} />}
                  textColor="white"
                  text="Ẩn"
                />
              </PopoverPopup>
            </StyledEditBtn>
          );
        },
      },
    ];
  }, [currentPage]);
  const getWarehouses = async () => {
    try {
      dispatch(setTableLoading(true));
      setWarehouses({ ...warehouses, data: [] });
      const res: WarehouseRoot = await warehouseAPI.getWarehouses(warehousePayload);
      const newResponse: WarehouseColumn[] = res.data.warehouse_response.map((item, index) => {
        LogApp(item.address_response?.detail, 'newResp');
        return {
          no: index + 1,
          key: item.id,
          name: item.name,
          warehouse_code: item.code,
          address: `${item.address_response?.detail}, ${item.address_response.wards.ward_name}, ${item.address_response.districts.district_name}, ${item.address_response.provinces.province_name}`,
          phone: item.phone_number,
          onEdit: () => {
            onEdit(item.id);
          },
          onHide: () => {
            onHide(item.id);
          },
        };
      });
      setWarehouses({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.count_warehouse,
        data: newResponse,
      });
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const onPageChange = (num: number) => {
    dispatch(setCurrentPage(num));
    setWarehousePayload({ ...warehousePayload, page: num });
  };
  const onLimitChange = (num: number) => {
    setWarehousePayload({ ...warehousePayload, size: num });
  };
  const onSearch = debounce((value: string) => {
    setWarehousePayload({ ...warehousePayload, keyword: value, page: INITIAL_PAGE });
  }, 500);
  const onEdit = async (id: string) => {
    try {
      const res: CreateWarehouseRoot = await warehouseAPI.getWarehouse(id);
      reset({
        name: res.data.name,
        phone_number: res.data.phone_number,
        address_detail: res.data.address_response.detail,
        code: res.data.code,
      });
      setDefaultAddress((draft) => {
        draft.province = {
          code: res.data.address_response.provinces.code,
          name: res.data.address_response.provinces.province_name,
        };
        draft.district = {
          code: res.data.address_response.districts.code,
          name: res.data.address_response.districts.district_name,
        };
        draft.ward = {
          code: res.data.address_response.wards.code,
          name: res.data.address_response.wards.ward_name,
        };
      });
      onModalInteract(true, id);
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const onHide = async (id: string) => {
    try {
      const res = await warehouseAPI.deleteWarehouse(id);
      setWarehouses((draft) => {
        const foundIndex = draft.data.findIndex((item) => item.key === res.id);
        draft.data.splice(foundIndex, 1);
      });
      onModalInteract(false, '');
    } catch (error) {}
  };
  const onSuccess = (res: CreateWarehouseResponse) => {
    setWarehouses((draft) => {
      onModalInteract(false, '');
      if (!isEdit)
        draft.data.push({
          key: res.id,
          no: warehouses.data.length + 1,
          name: res.name,
          onEdit: () => {
            onEdit(res.id);
          },
          warehouse_code: res.code,
          address: res.address_response.detail,
          phone: res.phone_number,
          onHide: () => {
            onHide(res.id);
          },
        });
      else {
        const foundIndex = draft.data.findIndex((item) => item.key === res.id);
        draft.data[foundIndex].name = res.name;
        draft.data[foundIndex].warehouse_code = res.code;
        draft.data[foundIndex].address = res.address_response.detail;
        draft.data[foundIndex].phone = res.phone_number;
      }
    });
  };
  const onOpenCreateForm = () => {
    onModalInteract(true, '');
    reset({
      name: '',
      phone_number: '',
      address_detail: '',
      code: '',
    });
  };

  useEffect(() => {
    getWarehouses();
  }, [warehousePayload]);
  return (
    <WarehouseTable
      columns={columns}
      warehouses={warehouses}
      currentPage={warehousePayload.page}
      onPageChange={onPageChange}
      onSearch={onSearch}
      limit={warehousePayload.size}
      onLimitChange={onLimitChange}
      onSuccess={onSuccess}
      onModalInteract={onModalInteract}
      modalVisible={modalData.open}
      handleSubmit={handleSubmit}
      register={register}
      errors={errors}
      isEdit={isEdit}
      onOpenCreateForm={onOpenCreateForm}
      defaultAddress={defaultAddress}
      currentId={modalData.id}
    />
  );
};
const StyledEditBtn = styled.div`
  display: flex;
  justify-content: center;
  .edit-btn {
    padding: 0.8rem 1.8rem;
    width: fit-content;
    align-self: center;
  }
`;
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
