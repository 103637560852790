import { ICategoryParams } from '@interfaces';
import { LogApp } from '@utils';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const categoriesAPI = {
  getCategories: (params?: DefaultApiParams) => {
    const url = `/admin/categories`;
    LogApp(params);
    return ApiClient.get(url, { params });
  },
  createCategory: (body: ICategoryParams) => {
    const url = '/admin/categories';
    return ApiClient.post(url, body);
  },
  deleteCategory: (id: string) => {
    const url = `/admin/categories/${id}`;
    return ApiClient.delete(url);
  },
  updateCategory: (id: string, body: ICategoryParams) => {
    const url = `/admin/categories/${id}`;
    return ApiClient.put(url, body);
  },
  updateCategoryStatus: (status: boolean, id: string) => {
    const url = `/admin/categories/${id}/active?status=${status}`;
    return ApiClient.put(url);
  },
};
