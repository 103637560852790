import { Col, Collapse, Row } from 'antd';
import styled from 'styled-components';

import {
  PlusIcon,
  PopoverPopup,
  SharedButton,
  SharedCheckbox,
  ShareInput,
  ShareRangeDateSelect,
  ShareTextEditor,
  Trash,
} from '@components';
import { MAIN_THEME_DATA, MAT_SM_SCREEN_WIDTH_MIN } from '@configs';
import { themes, useTheme } from '@theme';
import { generateRandomId } from '@utils';
import { EditorState } from 'draft-js';
import moment from 'moment';
import {
  FieldArrayWithId,
  FieldErrorsImpl,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
const { Panel } = Collapse;
interface IProps {
  isEdit?: boolean;
  editorRef: any;
  htmlDes: EditorState;
  errors: Partial<FieldErrorsImpl<any>>;
  watch: UseFormWatch<any>;
  onSubmit?: () => void;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  setIsAmountPromotion: (v: boolean) => void;
  fields: FieldArrayWithId<FieldValues, any, any>[];
  append: UseFieldArrayAppend<FieldValues, any>;
  remove: UseFieldArrayRemove;
  onDeletePromotionItem: (index: number) => void;
}
export const CreatePromotion = (props: IProps) => {
  const {
    isEdit,
    editorRef,
    htmlDes,
    register,
    setValue,
    getValues,
    onSubmit,
    errors,
    watch,
    setIsAmountPromotion,
    fields,
    append,
    remove,
    onDeletePromotionItem,
  } = props;
  const { theme } = useTheme();
  const navigate = useNavigate();
  return (
    <StyledProductSection onSubmit={onSubmit}>
      <div>
        <StyledRow gutter={[20, 16]}>
          <Col className="gutter-row name-type-col name-type-col-left" md={24} lg={24} xl={24}>
            <StyledDetailInfo>
              <div className="form">
                <SharedCheckbox
                  checked={watch('isChecked')}
                  className="mb-2"
                  text="Khuyến mãi theo số lượng"
                  onChange={(v: any) => {
                    setValue('isChecked', v.target.checked);
                    setIsAmountPromotion(v.target.checked);
                  }}
                />
                <ShareInput
                  className="input-mb"
                  name="code"
                  register={register}
                  label="Mã khuyến mãi"
                  required
                  placeholder="Nhập mã khuyến mãi"
                  errors={errors['code']?.message}
                />
                <ShareInput
                  className="input-mb"
                  name="name"
                  register={register}
                  label="Tiêu đề"
                  required
                  placeholder="Nhập tiêu đề"
                  errors={errors['name']?.message}
                />
                <>
                  {!watch('isChecked') ? (
                    <ShareInput
                      type="number"
                      className="input-mb"
                      name="discount_percent"
                      register={register}
                      label="Khuyến mãi (%)"
                      min={0}
                      max={100}
                      step={0.1}
                      required
                      placeholder="Nhập chiết khấu"
                      errors={errors['discount_percent']?.message}
                    />
                  ) : (
                    <div className="promotions">
                      {fields
                        .filter((item) => !item.deleted)
                        .map((item, index) => {
                          return (
                            <div key={item.id} className="promotion-item">
                              <h4>Mốc thứ {index + 1}</h4>
                              <div className="input-wrapper">
                                <ShareInput
                                  type="number"
                                  step={1}
                                  containerClassName="mr"
                                  key={item.id}
                                  name={`promotion_items.${index}.condition_amount`}
                                  register={register}
                                  label={`Số lượng đạt điều kiện`}
                                  placeholder={`Số lượng`}
                                  required
                                  errors={
                                    //@ts-ignore
                                    errors?.promotion_items?.[index]?.condition_amount?.message
                                  }
                                />
                                <ShareInput
                                  type="number"
                                  step={0.01}
                                  key={item.id}
                                  name={`promotion_items.${index}.discount_percent`}
                                  register={register}
                                  label="Phần trăm khuyến mãi"
                                  placeholder="Phần trăm"
                                  required
                                  errors={
                                    //@ts-ignore
                                    errors?.promotion_items?.[index]?.discount_percent?.message
                                  }
                                />
                                {index !== 0 && (
                                  <Trash
                                    onClick={() => {
                                      remove(index);
                                      onDeletePromotionItem(index);
                                    }}
                                    className="delete"
                                    color="red"
                                    size={50}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      <div
                        onClick={() => {
                          append({ condition_amount: '', discount_percent: '' });
                        }}
                        className="add-btn"
                      >
                        <div className="text-block">
                          <PlusIcon size={24} color={MAIN_THEME_DATA.mainColor} />
                          <span>Thêm khuyến mãi</span>
                        </div>
                      </div>
                    </div>
                  )}
                </>

                <ShareRangeDateSelect
                  key={generateRandomId()}
                  defaultValue={
                    getValues('start_day') &&
                    getValues('end_day') && [
                      moment(getValues('start_day'), 'yyyy-MM-DD'),
                      moment(getValues('end_day'), 'yyyy-MM-DD'),
                    ]
                  }
                  containerClassName="w-full"
                  required
                  label="Thời hạn"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format={'yyyy-MM-DD'}
                  onChange={(date, dateString) => {
                    setValue('start_day', dateString[0]);
                    setValue('end_day', dateString[1]);
                  }}
                  inputClassName="date-range"
                  errors={errors['start_day']?.message || errors['end_day']?.message}
                />
                <StyledTextArea>
                  <p className="title">Mô tả</p>
                  <div className="editor-wrapper">
                    <ShareTextEditor
                      className="editorClassName"
                      wrapperClassName="wrapperClassName"
                      ref={editorRef}
                      key={`htmlDes:${htmlDes}`}
                      value={htmlDes}
                    />
                  </div>
                </StyledTextArea>
              </div>
            </StyledDetailInfo>
          </Col>
        </StyledRow>
        <div className="bottom-content">
          <div className="form__actions">
            <SharedButton
              onClick={() => {
                navigate(-1);
              }}
              text="Huỷ"
              className="cancel__btn"
              backgroundColor="transparent"
              textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
              borderColor={theme?.colors?.button?.border || themes.theme.light.colors.button.border}
              btnStyle="basic"
            />
            <SharedButton
              typeHtml="submit"
              text={'Lưu'}
              className="create-rule__button"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              btnStyle="basic"
            />
            {
              <PopoverPopup
                content={
                  <StyledConfirmPopup>
                    <h5 className="text-center items-center mb-2 text-current text-base">
                      Thông báo
                    </h5>
                    <p className="text-sm">Bạn có muốn xoá sản phẩm này không?</p>
                  </StyledConfirmPopup>
                }
                isHaveConfirmButton
                onConfirm={() => {}}
              >
                {/* {isEdit && (
                  <SharedButton
                    text="Xoá"
                    className="create-rule__button btn-delete"
                    backgroundColor={MAIN_THEME_DATA.mainColor}
                    btnStyle="basic"
                  />
                )} */}
              </PopoverPopup>
            }
          </div>
        </div>
      </div>
    </StyledProductSection>
  );
};
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
`;
const StyledProductSection = styled.form`
  width: 100%;
  .form-section {
    position: relative;
    padding: 3.2rem 0 0;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
  }

  .section-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 1.6rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;
    .box-content {
      padding: 2.6rem;
      .head {
        padding-bottom: 2.3rem;
        .box-title {
          font-size: 1.6rem;
        }
      }
      .main_form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .form-field {
          display: flex;
          flex-direction: column;
          margin-bottom: 2.6rem;
          &:last-child {
            margin-bottom: 0;
          }
          .right-field {
            width: 100%;
          }
          .label {
            margin-right: 2.6rem;
            .label-text {
              font-size: 1.4rem;
              font-weight: 500;
              margin-bottom: 0.6rem;
              color: ${({ theme }: any) => theme?.colors?.text};
            }
            .label-desc {
              font-size: 1.3rem;
              font-weight: 400;
              color: ${({ theme }: any) => theme?.colors?.secondary};
            }
          }
          .value-text {
            font-size: 1.4rem;
            font-weight: 500;
            color: ${({ theme }: any) => theme?.colors?.secondary};
          }
          @media (min-width: 1280px) {
            flex-direction: row;
            align-items: flex-start;
            .label {
              width: 43%;
            }
          }
        }

        .cannot-changed-field {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .shared-input {
          margin-bottom: 0.8rem;
        }
        .inner-input,
        .ant-picker,
        input,
        .ant-select-selector {
          @media (min-width: 768px) {
            height: 4rem;
          }
        }
      }
    }
  }
  .edit_form {
    padding: 0 0 1.2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .shared-input,
    .ant-select {
      margin-bottom: 2rem;
    }

    @media (min-width: 768px) {
      .name-type-col-left {
        padding-right: 5rem;
      }
      .mob-row {
        margin-bottom: 3.2rem;
      }
    }

    @media (min-width: 1200px) {
      .name-type-col {
        max-width: calc((100% * 2 / 3 - 11.2rem) / 2);
      }
      .name-type-col-left {
        padding-right: 0;
        margin-right: 4.2rem;
      }
      .spent-get-col {
        padding-right: 1.5rem;
      }
      .duration-col {
        padding-left: 1.5rem;
      }
    }
  }

  .right-box {
    background: ${(p) => p.theme.colors.bgSection};
    border-radius: 0.6rem;
    padding: 2rem 2.2rem;
    box-shadow: 0px 3px 20px #0000000b;

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 0.8rem;
      font-size: 1.6rem;
      font-weight: 500;
    }

    .upload__image {
      width: 100%;
      height: auto;
    }
  }
  .bottom-content {
    width: 100%;
    display: flex;
    margin-top: 3rem;
    align-items: center;
    justify-content: center;
    .form__actions {
      display: flex;
      width: 100%;
      .btn {
        width: calc((100% - 2rem) / 2);
        margin-right: 2rem;
      }
      @media (min-width: 1280px) {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 23rem;
      }

      @media (max-width: 820px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;
        .btn {
          width: 100%;
          &:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`;

const StyledRow = styled((props: any) => <Row {...props} />)`
  margin-bottom: 1.4rem;
  @media (min-width: ${MAT_SM_SCREEN_WIDTH_MIN}) {
    margin-bottom: 4.2rem;
    &:first-child {
      margin-bottom: 5.6rem;
    }
  }
  width: 100%;
`;

const StyledTextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  .title {
    margin-bottom: 0.8rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    color: #303030;
  }
  .text-area-wrapper {
    resize: none;
    height: 10rem;
    padding-left: 1.2rem;
    padding-top: 0.8rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.6rem;
    outline: none;
  }
`;
const StyledDetailInfo = styled.div`
  .dropdown {
    margin-top: 1rem;
    .ant-select-selector {
      padding: 0.4rem !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 38px;
    padding: 0 11px;
  }
  .add-text {
    color: ${MAIN_THEME_DATA.mainColor};
    cursor: pointer;
  }
  .form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 140rem;
    .promotions {
      width: 60%;
      margin-bottom: 1rem;
      .promotion-item {
        .input-wrapper {
          display: flex;
          margin: 0.5rem 0 1rem 1rem;
          align-items: flex-end;
          .mr {
            margin-right: 5rem;
          }
          .delete {
            margin-left: 1rem;
            height: 3.8rem;
            cursor: pointer;
          }
        }
      }
      .add-btn {
        cursor: pointer;
        height: 4rem;
        border: 1px dashed ${MAIN_THEME_DATA.mainColor};
        width: 100%;
        border-radius: 0.6rem;
        margin-top: 1.5rem;
        .text-block {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          span {
            font-weight: bold;
            margin-left: 1rem;
            font-size: larger;
            color: ${MAIN_THEME_DATA.mainColor};
          }
        }
      }
    }

    .date-range {
      height: 3.8rem;
    }
    .input-ctn {
      width: 30rem;
      margin-right: 5rem;
    }
    .input-mb {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
const StyledProductList = styled.div`
  width: 100%;
  position: relative;
  .search__box {
    width: 25%;
    .search__input {
      height: 4.5rem;
    }
  }
  .filter-block {
    display: flex;
    .filter-wrapper {
      width: 25%;
      margin-left: 1rem;
      .ant-select-selector {
        height: 4.5rem !important;
      }
    }
  }
  .cancel {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .product-block {
    height: 50rem;
    .list {
      margin-top: 2rem;
      overflow: auto;
      padding: 0 1.6rem;
      width: 100%;
      border: 1px solid rgba(140, 140, 140, 0.35);
      .item-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right-block {
          display: flex;
        }
        .left-block {
          display: flex;
        }
        .price {
          display: inline-block;
          margin-right: 1rem;
          font-size: 1.7rem;
        }
        .item-info {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .product-name {
            font-size: 1.7rem;
            margin-bottom: 1rem;
          }
          .classification-wrapper {
            display: flex;
            align-items: center;
            .classification-block {
              display: flex;
              margin-left: 1rem;
            }
          }
        }
        .img-ctn {
          width: 9.6rem;
          aspect-ratio: 1;
          border-radius: 8px;
          img {
            border-radius: 8px;
          }
        }
      }
    }
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .submit-btn {
      padding: 0.8rem 1.2rem;
      height: fit-content;
      align-self: center;
    }
    .inp-wrapper {
      width: 40%;
    }
  }
  @media (max-width: 1280px) {
    width: 70rem;
  }
  @media (max-width: 820px) {
    width: 50rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    .btn-save {
      align-self: center;
      width: fit-content;
      padding: 1rem 2rem;
    }
    .btn-cancel {
      margin-right: 1rem;
    }
  }
`;
