import { PATH_CUSTOMERS, PATH_SELLER, ROLES } from '@configs';
import { selectAuth, useAppSelector } from '@redux';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useAdminAuthorization = () => {
  const { role } = useAppSelector(selectAuth);
  const isAdmin = role === ROLES.ADMIN;
  const [authorized, setAuthorized] = useState(isAdmin);
  const location = useLocation();
  const pathname = location.pathname;
  useLayoutEffect(() => {
    if (pathname.includes(PATH_SELLER) || pathname.includes(PATH_CUSTOMERS)) {
      setAuthorized(isAdmin);
    } else {
      setAuthorized(true);
    }
  }, [location.pathname]);
  return {
    authorized,
  };
};
