import { ShareInput } from '@components';
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { styled } from 'styled-components';
interface CustomerInfoProps {
  control: Control<any, any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}
export const CustomerInfo = (props: CustomerInfoProps) => {
  const { control, register, setValue, getValues } = props;
  return (
    <StyledCustomerInfo>
      <div className="info-block">
        <h2 className="title">Khách hàng</h2>
        <div className="input-address-wrapper">
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="customer_name"
            label="Tên khách hàng"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="customer_phone"
            label="Số điện thoại"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="customer_address"
            label="Địa chỉ chi tiết"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="customer_level_name"
            label="Nhóm khách hàng"
            disabled
          />
        </div>
      </div>
      <div className="info-block m-top-2">
        <h2 className="title">Địa chỉ giao hàng</h2>
        <div className="input-address-wrapper">
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="from_ward"
            label="Phường/Xã"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="from_district"
            label="Quận/Huyện"
            disabled
          />
          <ShareInput
            containerClassName="inp-wrapper"
            register={register}
            name="from_city"
            label="Tỉnh/Thành phố"
            disabled
          />
        </div>
      </div>
      <div className="info-block m-top-2">
        <h2 className="title">Địa chỉ nhận hàng</h2>
        <div className="input-address-wrapper">
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="to_ward"
            label="Phường/Xã"
            disabled
          />
          <ShareInput
            register={register}
            containerClassName="inp-wrapper"
            name="to_district"
            label="Quận/Huyện"
            disabled
          />
          <ShareInput
            containerClassName="inp-wrapper"
            register={register}
            name="to_city"
            label="Tỉnh/Thành phố"
            disabled
          />
        </div>
      </div>
    </StyledCustomerInfo>
  );
};
const StyledCustomerInfo = styled.div`
  .inner-input {
    font-weight: 500;
  }
  .input-mr {
    margin-right: 1rem;
  }
  .m-top-2 {
    margin-top: 2rem;
  }
  .code-block {
    display: flex;
    .inp-wrapper {
      width: 50%;
    }
  }

  .info-block {
    .title {
      font-size: 1.6rem;
    }
    .input-address-wrapper {
      display: flex;
      /* justify-content: space-between; */
      margin-top: 1rem;
      padding-left: 1rem;
      .inp-wrapper {
        width: 30%;
        margin-right: 2rem;
      }
    }
  }
`;
