import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMerchantInfo, RootResponse } from '@interfaces';
import { RootState } from '.';
import { authAPI } from '@api';

interface IAuth {
  accessToken?: string;
  refreshToken?: string;
  accountInfo?: IMerchantInfo;
  forgotEmail?: string;
  verifyMailHash?: string;
  resetPassHash?: string;
  role: string;
  warehouse_id: string;
  username: string;
  userId: string;
  version: string;
}

const initialState: IAuth = {
  accessToken: '',
  refreshToken: undefined,
  accountInfo: undefined,
  verifyMailHash: undefined,
  resetPassHash: undefined,
  forgotEmail: undefined,
  role: '',
  warehouse_id: '',
  username: '',
  userId: '',
  version: '',
};
const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setForgotEmail: (state, action: PayloadAction<string>) => {
      state.forgotEmail = action.payload;
    },
    setAccountInfo: (state, action: PayloadAction<any>) => {
      state.accountInfo = action.payload;
    },
    setVerifyMailHash: (state, action: PayloadAction<string>) => {
      state.verifyMailHash = action.payload;
    },
    setResetPassHash: (state, action: PayloadAction<string>) => {
      state.resetPassHash = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setWarehouseId: (state, action: PayloadAction<string>) => {
      state.warehouse_id = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    logout: () => {
      return initialState;
    },
  },
});

export const {
  setAccessToken,
  setAccountInfo,
  setForgotEmail,
  setVerifyMailHash,
  setResetPassHash,
  logout,
  setRole,
  setWarehouseId,
  setUsername,
  setUserId,
  setVersion,
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
