import {
  CURRENT_THEME,
  DEFAULT_CUSTOMER_FILTER_ITEM,
  DEFAULT_SELLER_DETAIL,
  DEFAULT_SELLER_FILTER_ITEM,
  DEFAULT_WAREHOUSE_FILTER_ITEM,
  enumThemeMode,
} from '@configs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { themes } from '@theme';
import { setToLS } from '@utils';

import { RootState } from '.';
import {
  AutocompleteItem,
  CustomerFilterItem,
  CustomerItem,
  Seller,
  SellerItem,
  Warehouse,
} from '@interfaces';

interface IState {
  mobile: boolean;
  fullscreen: boolean;
  loading: boolean;
  appStateId: string;
  themeMode: enumThemeMode;
  currentPage: number;
  tableLoading: boolean;
  currentWarehouse: AutocompleteItem<Warehouse>;
  currentProductSearch: string;
  currentSeller: AutocompleteItem<SellerItem>;
  currentCustomer: AutocompleteItem<CustomerFilterItem>;
  currentOrderStatus: string;
  currentDateFilter: string[];
}

const initialState: IState = {
  mobile: false,
  fullscreen: false,
  loading: false,
  tableLoading: false,
  appStateId: '',
  themeMode: enumThemeMode.LIGHT,
  currentPage: 0,
  currentWarehouse: {
    label: '',
    value: '',
    item: DEFAULT_WAREHOUSE_FILTER_ITEM,
  },
  currentProductSearch: '',
  currentSeller: {
    label: '',
    value: '',
    item: DEFAULT_SELLER_FILTER_ITEM,
  },
  currentCustomer: {
    label: '',
    value: '',
    item: DEFAULT_CUSTOMER_FILTER_ITEM,
  },
  currentOrderStatus: '',
  currentDateFilter: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    resetApp: () => {
      return initialState;
    },
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTableLoading: (state, action: PayloadAction<boolean>) => {
      state.tableLoading = action.payload;
    },
    setFullscreen: (state) => {
      const element = document.querySelector('#root');
      //@ts-ignore
      const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
      //@ts-ignore
      element.requestFullScreen =
        //@ts-ignore
        element.requestFullScreen ||
        //@ts-ignore
        element.webkitRequestFullScreen ||
        //@ts-ignore
        element.mozRequestFullScreen ||
        function () {
          return false;
        };
      //@ts-ignore
      document.cancelFullScreen =
        //@ts-ignore
        document.cancelFullScreen ||
        //@ts-ignore
        document.webkitCancelFullScreen ||
        //@ts-ignore
        document.mozCancelFullScreen ||
        function () {
          return false;
        };
      //@ts-ignore
      isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();

      state.fullscreen = !isFullscreen;
    },
    setAppStateId: (state, action: PayloadAction<string>) => {
      state.appStateId = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<enumThemeMode>) => {
      state.themeMode = action.payload;
      if (action.payload === enumThemeMode.DARK) {
        setToLS(CURRENT_THEME, themes.theme.dark);
      } else {
        setToLS(CURRENT_THEME, themes.theme.light);
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentWarehouse: (state, action: PayloadAction<AutocompleteItem<Warehouse>>) => {
      state.currentWarehouse = action.payload;
    },
    setCurrentProductSearch: (state, action: PayloadAction<string>) => {
      state.currentProductSearch = action.payload;
    },
    setCurrentSeller: (state, action: PayloadAction<AutocompleteItem<SellerItem>>) => {
      state.currentSeller = action.payload;
    },
    setCurrentCustomer: (state, action: PayloadAction<AutocompleteItem<CustomerFilterItem>>) => {
      state.currentCustomer = action.payload;
    },
    setCurrentOrderStatus: (state, action: PayloadAction<string>) => {
      state.currentOrderStatus = action.payload;
    },
    setCurrentDateRange: (state, action: PayloadAction<string[]>) => {
      state.currentDateFilter = action.payload;
    },
    resetFilter: (state) => {
      state.currentCustomer = {
        label: '',
        value: '',
        item: DEFAULT_CUSTOMER_FILTER_ITEM,
      };
      (state.currentWarehouse = {
        label: '',
        value: '',
        item: DEFAULT_WAREHOUSE_FILTER_ITEM,
      }),
        (state.currentProductSearch = ''),
        (state.currentSeller = {
          label: '',
          value: '',
          item: DEFAULT_SELLER_FILTER_ITEM,
        });
      (state.currentCustomer = {
        label: '',
        value: '',
        item: DEFAULT_CUSTOMER_FILTER_ITEM,
      }),
        (state.currentOrderStatus = ''),
        (state.currentDateFilter = []);
    },
  },
});

export const {
  resetApp,
  setMobile,
  setFullscreen,
  setLoading,
  setAppStateId,
  setThemeMode,
  setCurrentPage,
  setTableLoading,
  setCurrentWarehouse,
  setCurrentProductSearch,
  setCurrentSeller,
  setCurrentCustomer,
  setCurrentDateRange,
  setCurrentOrderStatus,
  resetFilter,
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;
export const selectAppLoading = (state: RootState) => state.app.loading;
export const selectAppStateId = (state: RootState) => state.app.appStateId;
export const selectThemeMode = (state: RootState) => state.app.themeMode;

export default appSlice.reducer;
