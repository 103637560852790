import { AutocompleteItem } from './app';
import { FromAddress, ToAddress } from './order';
import { Image, Warehouse } from './product';
import { AddressItem, WarehouseItem } from './warehouse';

export interface MemberRoot {
  status: number;
  message: string;
  data: MemberResponse;
  timestamp: string;
}

export interface MemberResponse {
  users: Member[];
  count_search: number;
}

export interface Member {
  id: string;
  username: string;
  full_name: string;
  phone_number: string;
  email: string;
  dob: string;
}
export interface SellerColumn {
  key: React.Key;
  no: number;
  brand_name: string;
  full_name: string;
  phone_number: string;
  code: string;
  role: string;
  email: string;
  dob: string;
  status: number;
  onEdit: () => void;
  onHide: () => void;
}
export interface SellerData {
  count: number;
  limit: number;
  data: SellerColumn[];
}

export interface SellerRoot {
  status: number;
  message: string;
  data: SellerResponse;
  timestamp: string;
}

export interface SellerResponse {
  sellers: Seller[];
  count: number;
}

export interface Seller {
  id: string;
  brand_name: string;
  name: string;
  username: string;
  full_name: string;
  phone_number: string;
  code: string;
  email: string;
  role: string;
  dob: string;
  address: FromAddress;
  warehouse: Warehouse;
  status: number;
}
export interface SellerAutocompleteData {
  provinces: AutocompleteItem<AddressItem>[];
  districts: AutocompleteItem<AddressItem>[];
  wards: AutocompleteItem<AddressItem>[];
  warehouses: AutocompleteItem<WarehouseItem>[];
}
export interface CreateSellerPayload {
  brand_name: string;
  warehouse_id: string;
  username: string;
  password: string;
  confirm_password: string;
  full_name: string;
  phone_number: string;
  code: string;
  email: string;
  dob: string;
  address: CreateSellerAddress;
  accountant: boolean;
}

export interface CreateSellerAddress {
  province_code: string;
  district_code: string;
  ward_code: string;
  detail: string;
}
export interface CreateSellerRoot {
  status: number;
  message: string;
  data: Seller;
  timestamp: string;
}
export interface SellerDetailRoot {
  status: number;
  message: string;
  data: Seller;
  timestamp: string;
}

//customer
export interface CustomerRoot {
  status: number;
  message: string;
  data: CustomerResponse;
  timestamp: string;
}

export interface CustomerResponse {
  customers: Customer[];
  count: number;
}

export interface Customer {
  id: string;
  bonus_coin: number;
  status: number;
  seller: Seller;
  username: string;
  full_name: string;
  phone_number: string;
  email: string;
  dob: string;
  address?: ToAddress;
  image?: Image;
  code?: string;
  customer_level: CustomerLevel;
}
export interface CustomerColumn {
  key: React.Key;
  no: number;
  seller_name: string;
  full_name: string;
  phone_number: string;
  email: string;
  dob: string;
  level: string;
  code: string;
  status: number;
  bonus_coin: number;
  onEdit: () => void;
  onChangeStatus: () => void;
}
export interface CustomerData {
  count: number;
  limit: number;
  data: CustomerColumn[];
}
export interface UpdateAccountStatusPayload {
  status?: number;
  reason?: string | null;
  code?: string | null;
  money_beginning_period?: MoneyBeginningPeriod | null;
}
export interface MoneyBeginningPeriod {
  total_money_revenue: number;
  total_money_debt: number;
  total_money_reward: number;
}
export enum AccountStatus {
  REJECTED = -1,
  ACCEPTED = 1,
  DISABLE = 0,
  LOCKED = 2,
}

export interface CustomerLevel {
  id: string;
  level: number;
  name: string;
  min_revenue: number;
  max_revenue: number;
  max_debt: number;
  payment_period: number;
  deduct_percent: number;
  activated: boolean;
}
export interface CustomerLevel {
  id: string;
  level: number;
  name: string;
  payment_period: number;
  deduct_percent: number;
}
export interface UpdateCustomerPayload {
  seller_id: string;
  full_name: string;
  phone_number: string;
  dob: string;
  customer_type_id?: string;
  image_id: string;
  address?: {
    id: string;
    province_code: string;
    district_code: string;
    ward_code: string;
    detail: string;
  };
}
