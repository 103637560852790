import { CreateWarehousePayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const warehouseAPI = {
  getWarehouses: (params?: DefaultApiParams) => {
    const url = `/warehouses`;
    return ApiClient.get(url, { params: { ...params, keyword: params?.keyword?.trim() } });
  },
  getProvinces: (name?: string) => {
    const url = `/provinces?keyword=${name}&size=10&page=0&all=true`;
    return ApiClient.get(url);
  },
  getDistricts: (province_code: string, keyword?: string) => {
    const url = `/districts`;
    return ApiClient.post(url, { province_code, keyword });
  },
  getWards: (district_code: string, keyword?: string) => {
    const url = `/wards`;
    return ApiClient.post(url, { district_code, keyword });
  },
  createWarehouse: (payload: CreateWarehousePayload) => {
    const url = `/warehouses`;
    return ApiClient.post(url, payload);
  },
  updateWarehouse: (payload: CreateWarehousePayload, id: string) => {
    const url = `/warehouses/${id}`;
    return ApiClient.put(url, payload);
  },
  deleteWarehouse: (id: string) => {
    const url = `/warehouses/${id}`;
    return ApiClient.delete(url);
  },
  getWarehouse: (id: string) => {
    const url = `/warehouses/${id}`;
    return ApiClient.get(url);
  },
  getWarehouseItems: () => {
    const url = `/warehouses/items`;
    return ApiClient.get(url);
  },
};
