import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { ForgotPasswordForm } from '@components';
import { selectApp, selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import { toast } from 'react-toastify';

export const schema = yup.object().shape({
  password: yup.string().required('Vui lòng nhập mật khẩu'),
  new_password: yup
    .string()
    .required('Vui lòng nhập mật khẩu')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
      'Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.',
    ),
  confirm_password: yup
    .string()
    .required('Vui lòng xác nhận mật khẩu')
    .oneOf([yup.ref('new_password'), null], 'Mật khẩu không khớp'),
});

export const ForgotPasswordModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { themeMode } = useAppSelector(selectApp);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
  });

  const handleChangePassword = handleSubmit(async (value) => {
    try {
      dispatch(setLoading(true));
      await authAPI.changePassword(authState.userId, value);
      dispatch(setLoading(false));
      navigate(-1);
      toast.success('Đổi mật khẩu thành công!', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: themeMode,
      });
    } catch (error: any) {
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <ForgotPasswordForm
      register={register}
      redirectToLogin={() => {
        navigate(-1);
      }}
      errors={errors}
      handleChangePassword={handleChangePassword}
    />
  );
};
