import * as yup from 'yup';

export const ProductFormSchema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tên sản phẩm'),
  unit: yup.string().required('Vui lòng nhập đơn vị'),
  category_ids: yup.array().of(yup.string()).required('Vui lòng chọn ít nhất một danh mục'),
  warehouse_id: yup.string().required('Vui lòng chọn kho'),
  buying_price: yup.string().required('Vui lòng nhập giá mua'),
  selling_price: yup.string().required('Vui lòng nhập giá bán'),
  quantity: yup.string().required('Vui lòng nhập số lượng'),
});
