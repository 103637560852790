import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import {
  Pagination,
  SearchIcon,
  SharedAutocompleteV2,
  SharedButton,
  SharedTable,
  ShareInput,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  AutocompleteItem,
  Category,
  ProductColumn,
  ProductData,
  ProductFilterDropdowns,
  Warehouse,
} from '@interfaces';
import { selectApp, useAppSelector } from '@redux';
import { useState } from 'react';

interface IProps {
  products: ProductData;
  currentPage?: number;
  onCreate: () => void;
  onPageChange: (value: number) => void;
  updateData: () => void;
  onCloseCreateModal: () => void;
  onDataChange: (no: number) => void;
  onChangePageSize: (size: number) => void;
  renderChildRow: (record: any) => JSX.Element;
  columns: ColumnsType<ProductColumn>;
  productFilterData: ProductFilterDropdowns;
  onSearch: (text: string) => void;
  onChangeWarehouse: (item: AutocompleteItem<Warehouse>) => void;
  onChangeCategory: (item: AutocompleteItem<Category>) => void;
  currentWarehouse: AutocompleteItem<Warehouse>;
  onExpand: (record: string) => void;
}

export const ProductListSection = (props: IProps) => {
  const { currentProductSearch } = useAppSelector(selectApp);
  const {
    products,
    currentPage,
    onCreate,
    onPageChange,
    renderChildRow,
    columns,
    onSearch,
    productFilterData,
    onChangeWarehouse,
    onChangeCategory,
    currentWarehouse,
    onExpand,
  } = props;
  const [searchValue, setSearchValue] = useState(currentProductSearch ?? '');
  return (
    <StyledAnnouncementSection>
      <div className="head__actions">
        <div className="flex items-center">
          <div className="filter-block">
            <ShareInput
              containerClassName="search__box"
              placeholder="Tìm theo tên"
              className="search__input"
              defaultValue={searchValue as string}
              type="text"
              onChange={(e: any) => {
                setSearchValue(e.target.value);
                onSearch(e.target.value);
              }}
              prevIcon={<SearchIcon />}
            />
            <SharedAutocompleteV2
              defaultValue={currentWarehouse?.value ? [currentWarehouse] : undefined}
              name="Chọn kho"
              isClearable
              containerClassName="filter-wrapper"
              data={productFilterData.warehouses}
              placeholder="Chọn kho"
              onSelect={(v) => {
                onChangeWarehouse(v);
              }}
            />
            <SharedAutocompleteV2
              name="customer_level"
              isClearable
              containerClassName="filter-wrapper"
              data={productFilterData.categories}
              placeholder="Chọn danh mục"
              onSelect={(v) => {
                onChangeCategory(v);
              }}
            />
          </div>
          <span className="ml-3">Tổng số sản phẩm: {products.total_amount_remain}</span>
        </div>

        <SharedButton
          onClick={onCreate}
          className="create-button"
          backgroundColor={MAIN_THEME_DATA.mainColor}
          text="Thêm sản phẩm"
          btnStyle="pad"
        />
      </div>
      <SharedTable
        onExpandSuccess={(r) => {
          onExpand(r);
        }}
        renderChildRow={renderChildRow}
        columns={columns}
        dataSource={products.data}
      />
      <div className="bottom-pagination">
        <Pagination
          className="pagination pt-2"
          currentPage={currentPage}
          totalCount={products.count}
          pageSize={products.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
    </StyledAnnouncementSection>
  );
};

const StyledAnnouncementSection = styled.div`
  .head__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
    .filter-block {
      display: flex;
      justify-content: space-between;
      .filter-wrapper {
        width: 30rem;
        margin-left: 1rem;
        .css-13cymwt-control {
          height: 4.5rem !important;
        }
      }
    }
    .search__box {
      min-width: 20rem;
      width: fit-content;
      .search__input {
        height: 4.5rem;
      }
    }
    .create-button {
      height: 4.5rem;
      width: fit-content;
    }
  }
  .table-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-image {
    /* width: 9.6rem;
    height: 9.6rem; */
    padding: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .ant-image-img,
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .ant-image-placeholder {
      position: relative;
    }
  }
  .tiers-applied {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .tiers-item {
      padding: 0.3rem 0.6rem;
      background-color: ${(p) => p?.theme?.colors?.info};
      border-radius: 0.6rem;
      margin: 0.5rem 0.3rem;
      .tiers-name {
        font-size: 1.2rem;
        font-weight: 500;
        color: ${(p) => p?.theme?.colors?.textLight};
      }
    }
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
  .ant-table-cell:nth-child(5) {
    //applied tier column
    max-width: 16rem;
  }
  .btn-action {
    align-self: center;
    width: fit-content;
    padding: 0.8rem 1.8rem;
  }
  .btn-edit {
    margin: 0rem 1rem;
  }

  .banner-name {
    margin-bottom: 0;
    font-size: 1.3rem;
    /* font-weight: 500; */
  }
`;
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
  }
`;
