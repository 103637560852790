import { promotionAPI } from '@api';
import { CreatePromotion } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Promotion,
  PromotionItem,
  PromotionPayload,
  PromotionType,
  RootResponse,
} from '@interfaces';
import { setLoading, useAppDispatch } from '@redux';
import { LogApp, parsePercent } from '@utils';
import { convertToHTML } from 'draft-convert';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
const baseSchema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập tiêu đề'),
  code: yup.string().required('Vui lòng nhập mã khuyến mãi'),
  start_day: yup.string().required('Vui lòng nhập ngày bắt đầu'),
  end_day: yup.string().required('Vui lòng nhập ngày kết thúc'),
});
const percentSchema = yup.object().shape({
  ...baseSchema.fields,
  discount_percent: yup.string().required('Vui lòng nhập phần trăm khuyến mãi'),
});
const amountSchema = yup.object().shape({
  ...baseSchema.fields,
  promotion_items: yup.array().of(
    yup.object().shape({
      condition_amount: yup.string().required('Vui lòng nhập số lượng đạt điều kiện'),
      discount_percent: yup.string().required('Vui lòng nhập phần trăm khuyến mãi'),
    }),
  ),
});
export const CreatePromotionModule = ({ isEdit }: { isEdit?: boolean }) => {
  const [isAmountPromotion, setIsAmountPromotion] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(isAmountPromotion ? amountSchema : percentSchema),
    defaultValues: {
      name: '',
      code: '',
      start_day: '',
      end_day: '',
      discount_percent: '',
      type: '',
      isChecked: false,
      promotion_items: [{ condition_amount: '', discount_percent: '', deleted: false }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'promotion_items',
  });
  const editorRef = useRef<any>(null);
  const [htmlDes, setHtmlDes] = useState(EditorState.createEmpty());

  const handleSubmitForm = handleSubmit(async (value) => {
    const { name, code, start_day, end_day, discount_percent, isChecked, promotion_items } = value;
    try {
      dispatch(setLoading(true));
      const basePromotion: PromotionPayload = {
        name,
        code,
        description: convertToHTML(editorRef.current?.getCurrentContent()),
        start_day,
        end_day,
        type: isChecked ? PromotionType.AMOUNT : PromotionType.MONEY,
      };
      const moneyPromotion = {
        ...basePromotion,
        discount_percent,
      };
      const amountPromotion = {
        ...basePromotion,
        promotion_items: promotion_items.map((item: PromotionItem) => {
          return {
            ...item,
            discount_percent: parsePercent(String(item.discount_percent)),
          };
        }),
      };
      const payload = (isChecked ? amountPromotion : moneyPromotion) as PromotionPayload;
      const res = isEdit
        ? await promotionAPI.updatePromotion(payload, id || '')
        : await promotionAPI.createPromotion(payload);
      toast.success('Thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      navigate(-1);
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  });
  const getPromotion = async () => {
    try {
      dispatch(setLoading(true));
      const res: RootResponse<Promotion> = await promotionAPI.getPromotion(id || '');
      const {
        name,
        code,
        discount_percent,
        start_day,
        end_day,
        description,
        type,
        promotion_items,
      } = res.data;
      reset({
        name,
        code,
        start_day: moment(start_day).format('yyyy-MM-DD'),
        end_day: moment(end_day).format('yyyy-MM-DD'),
        discount_percent: discount_percent.toString(),
        type: type.toString(),
        promotion_items: promotion_items.map((item) => {
          return { ...item, deleted: false };
        }),
      });
      setValue('isChecked', Number(type) === PromotionType.AMOUNT);
      if (description) {
        const blocksFromHTML = convertFromHTML(description);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        setHtmlDes(editorState);
      }
    } catch (error) {
      LogApp(error, 'err');
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onDeletePromotionItem = (index: number) => {
    setValue(`promotion_items.${index}.deleted`, true);
  };
  useEffect(() => {
    if (isEdit) getPromotion();
  }, []);
  return (
    <CreatePromotion
      errors={errors}
      register={register}
      setValue={setValue}
      getValues={getValues}
      watch={watch}
      editorRef={editorRef}
      htmlDes={htmlDes}
      onSubmit={handleSubmitForm}
      setIsAmountPromotion={setIsAmountPromotion}
      fields={fields}
      append={append}
      remove={remove}
      onDeletePromotionItem={onDeletePromotionItem}
    />
  );
};
