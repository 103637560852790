import { IMAGES } from '@assets';
import {
  AppModal,
  IConCancel,
  Pagination,
  SearchIcon,
  SharedAutocompleteV2,
  SharedButton,
  SharedTable,
  ShareInput,
  ViewIcon,
  SharedAutocomplete,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import {
  AccountStatus,
  AddressItem,
  AutocompleteItem,
  Customer,
  CustomerData,
  CustomerLevel,
  FilterDataItem,
  Seller,
  SellerAutocompleteData,
  SellerItem,
  UpdateAccountStatusPayload,
  WarehouseItem,
} from '@interfaces';
import { useTheme } from '@theme';
import { LogApp, useUrlQuery } from '@utils';
import { Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { BaseSyntheticEvent } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import styled from 'styled-components';

interface IProps {
  memberData: CustomerData;
  tierFilterData?: FilterDataItem[];
  currentPage: number;
  onSearch: (value: string) => void;
  onPageChange: (value: number) => void;
  onDeny: () => void;
  onAccept: () => void;
  modalData: { currentId: string; opened: boolean };
  showExtraInput: { status: AccountStatus; shown: boolean };
  onClose: () => void;
  handleSubmit: (
    e?: BaseSyntheticEvent<UpdateAccountStatusPayload, any, any> | undefined,
  ) => Promise<void>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  filters: { customerLevels: AutocompleteItem<CustomerLevel>[] };
  onSelectCustomerType: (value: AutocompleteItem<CustomerLevel>) => void;
  onChangeCustomerType: (value: AutocompleteItem<CustomerLevel>) => void;
  onSellerChange: (value: AutocompleteItem<SellerItem>) => void;
  updateCustomerLevel: () => Promise<void>;
  updateCustomerInfo: () => Promise<void>;
  updateMoneyBeginningPeriod: () => Promise<void>;
  control: Control<any, any>;
  sellerData: AutocompleteItem<SellerItem>[];
  onSelectProvince: (value: string) => void;
  onSelectDistrict: (value: string) => void;
  onSelectWard: (value: string) => void;
  autocompleteData: SellerAutocompleteData;
  customer: Customer;
}

interface DataType {
  key: React.Key;
  no: number;
  tierName: string;
  condition: string;
  monthsOfStatus: string;
  mainTier: string;
  statusTier: boolean;
}

export const CustomerListSection = (props: IProps) => {
  const {
    memberData,
    currentPage,
    onSearch,
    onPageChange,
    modalData,
    onClose,
    register,
    errors,
    getValues,
    showExtraInput,
    onDeny,
    handleSubmit,
    onAccept,
    filters,
    onSelectCustomerType,
    onChangeCustomerType,
    updateCustomerLevel,
    control,
    sellerData,
    onSellerChange,
    onSelectDistrict,
    onSelectProvince,
    onSelectWard,
    autocompleteData,
    customer,
    updateCustomerInfo,
  } = props;
  LogApp(customer, 'cus');
  const STATUS_TEXT = {
    [AccountStatus.REJECTED]: 'Từ chối',
    [AccountStatus.DISABLE]: 'Chưa kích hoạt',
    [AccountStatus.ACCEPTED]: 'Đang hoạt động',
    [AccountStatus.LOCKED]: 'Bị khoá',
  };
  const STATUS_COLOR = {
    [AccountStatus.REJECTED]: '#dc3545',
    [AccountStatus.DISABLE]: '#4fc3f7',
    [AccountStatus.ACCEPTED]: '#28a745',
    [AccountStatus.LOCKED]: 'black',
  };
  const { theme } = useTheme();
  const isRejected = showExtraInput.status === AccountStatus.REJECTED;
  const urlQuery = useUrlQuery();
  const isDisable = getValues('status') === AccountStatus.DISABLE;
  const isAccepted = getValues('status') === AccountStatus.ACCEPTED;
  const isDenied = getValues('status') === AccountStatus.REJECTED;
  const isLocked = getValues('status') === AccountStatus.LOCKED;
  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (text, record, index) => index + currentPage * 10 + 1,
      sorter: (a, b) => a.no - b.no,
    },
    { title: 'Họ và tên', dataIndex: 'full_name', key: 'full_name' },
    { title: 'Số điện thoại', dataIndex: 'phone_number', key: 'phone_number' },
    { title: 'Mã khách hàng', dataIndex: 'code', key: 'code' },
    // { title: 'Email', dataIndex: 'email', key: 'email' },
    // { title: 'Ngày sinh', dataIndex: 'dob', key: 'dob' },
    { title: 'Tên seller', dataIndex: 'seller_name', key: 'seller_name' },
    { title: 'Nhóm khách hàng', dataIndex: 'level', key: 'level' },
    { title: 'Xu khả dụng', dataIndex: 'bonus_coin', key: 'bonus_coin' },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (value: any) => {
        return (
          //@ts-ignore
          <span style={{ color: STATUS_COLOR[value] }}>{STATUS_TEXT[value]}</span>
        );
      },
    },
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'x',
      width: '20%',
      render: (value: any) => {
        // const isDisable =
        //   value.status === AccountStatus.DISABLE || value.status === AccountStatus.REJECTED;
        // const isActive = value.status === AccountStatus.ACCEPTED && !isDisable;
        // const message = !isDisable
        //   ? `Bạn có muốn ${isActive ? 'vô hiệu hoá' : 'kích hoạt'} tài khoản này không?`
        //   : 'Hồ sơ cần được phê duyệt';
        return (
          <div className="flex items-center justify-center">
            {/* <PopoverPopup
              content={
                <StyledConfirmPopup>
                  <h5 className="text-center items-center mb-2 text-current text-base">
                    Thông báo
                  </h5>
                  <p className="text-sm">{message}</p>
                </StyledConfirmPopup>
              }
              isHaveConfirmButton={!isDisable}
              onConfirm={() => {
                value.onChangeStatus();
              }}
            >
              <SharedButton
                className={`edit-btn table-actions-btn ${
                  isActive ? ` btn-delete` : `btn-info`
                } action-btn mr-2`}
                backgroundColor={MAIN_THEME_DATA.mainColor}
                prevIcon={isActive ? <Trash color={'white'} /> : <TickBox color="white" />}
                textColor="white"
                text={isActive ? 'Vô hiệu hoá' : 'Kích hoạt'}
              />
            </PopoverPopup> */}
            <SharedButton
              onClick={() => {
                value.onEdit();
              }}
              className={`edit-btn table-actions-btn btn-edit action-btn`}
              backgroundColor={MAIN_THEME_DATA.mainColor}
              prevIcon={<ViewIcon color="white" />}
              textColor="white"
              text={'Xem hồ sơ'}
            />
          </div>
        );
      },
    },
  ];
  return (
    <StyledMemberListSection>
      <div className="head-actions">
        <div className="search-fil">
          <ShareInput
            containerClassName="search-rules__box"
            placeholder="Tìm kiếm"
            className="search-rules__input"
            type="text"
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
            prevIcon={<SearchIcon />}
          />
          <SharedAutocompleteV2
            name="customer_level"
            isClearable
            containerClassName="filter-wrapper"
            data={filters.customerLevels}
            placeholder="Chọn nhóm khách hàng"
            onSelect={(v) => {
              onSelectCustomerType(v);
            }}
          />
        </div>
      </div>
      <SharedTable columns={columns} dataSource={memberData.data} />
      <div className="bottom-pagination">
        <Pagination
          className="pagination pt-2"
          currentPage={currentPage}
          totalCount={memberData.count}
          pageSize={memberData.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      {modalData.opened && (
        <AppModal open={modalData.opened}>
          {/*@ts-ignore*/}
          <form onSubmit={handleSubmit} className="inner-modal">
            <h2 className="title">Chi tiết hồ sơ</h2>
            <Image
              src={getValues('avatar') || IMAGES.noImage}
              alt=""
              className="avatar"
              placeholder={
                <Image
                  preview={false}
                  src={getValues('avatar') || IMAGES.noImage}
                  className="avatar"
                />
              }
            />
            <div className="input-container">
              <div
                className={`level-block ${
                  errors['customer_level']?.message ? 'items-center' : 'items-end'
                }`}
              >
                <SharedAutocompleteV2
                  containerClassName="input-wrapper mt-3"
                  name="update_customer_level"
                  isClearable
                  data={filters.customerLevels}
                  placeholder="Chọn nhóm khách hàng"
                  defaultValue={getValues('customer_level')}
                  errors={errors?.['customer_level']?.message}
                  onSelect={(v) => {
                    onChangeCustomerType(v);
                  }}
                />
                <SharedButton
                  onClick={updateCustomerInfo}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  btnStyle="basic"
                  text={'Xác nhận'}
                />
              </div>
              {(isDisable || isDenied) && (
                <div className="info-block">
                  <h3 className="title mb-2">Các khoản tiền đầu kì</h3>
                  <div className="input-address-wrapper">
                    <ShareInput
                      control={control}
                      isNumericFormat
                      containerClassName="inp-wrapper"
                      name="total_money_revenue"
                      label="Nhập hàng đầu kì"
                      placeholder="Số tiền"
                      allowNegative={false}
                    />
                    <ShareInput
                      control={control}
                      isNumericFormat
                      containerClassName="inp-wrapper"
                      name="total_money_debt"
                      label="Dư nợ đầu kì"
                      placeholder="Số tiền"
                    />
                    <ShareInput
                      control={control}
                      isNumericFormat
                      containerClassName="inp-wrapper"
                      name="total_money_reward"
                      label="Doanh số tính thưởng đầu kì"
                      placeholder="Số tiền"
                      allowNegative={false}
                    />
                  </div>
                </div>
              )}
              <div className="customer-info">
                <h3 className="title mb-2 ">
                  {/*@ts-ignore*/}
                  Thông tin khách hàng (Trạng thái: {STATUS_TEXT[getValues('status')]})
                </h3>
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="full_name"
                  placeholder="Họ và tên"
                  label="Họ và tên"
                />
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="phone_number"
                  placeholder="Số điện thoại đăng kí"
                  label="Số điện thoại đăng kí"
                />
                {/* <SharedAutocomplete
                  onSelect={(value) => {
                    // setValue('warehouse_id', value.item.id);
                  }}
                  containerClassName="address-input mx mb-3"
                  data={autocompleteData.warehouses}
                  label={'Kho'}
                  placeholder="Chọn kho"
                  defaultValue={customer.seller?.warehouse?.name}
                  key={`seller:${customer.seller?.warehouse?.name || ''}`}
                  required
                  filterOption={(inputValue: string, option: AutocompleteItem<WarehouseItem>) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  errors={errors['warehouse_id']?.message}
                /> */}
                <div className="label">
                  <p className="label-text">Địa chỉ</p>
                </div>
                <div className="address-block">
                  <SharedAutocomplete
                    onSelect={(value) => {
                      onSelectProvince(value.item.code);
                    }}
                    containerClassName="address-input mx my-3"
                    data={autocompleteData.provinces}
                    label={''}
                    placeholder="Nhập tỉnh/thành phố"
                    defaultValue={customer?.address?.provinces?.province_name}
                    key={`${customer?.address?.provinces?.province_name}:province`}
                    required
                    filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    errors={errors['province_code']?.message}
                  />
                  <SharedAutocomplete
                    onSelect={(value) => {
                      onSelectDistrict(value.item.code);
                    }}
                    containerClassName="address-input mx my-3"
                    data={autocompleteData.districts}
                    label={''}
                    placeholder="Nhập quận/huyện"
                    defaultValue={customer?.address?.districts?.district_name}
                    key={`${customer?.address?.districts?.district_name}:district`}
                    required
                    filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    errors={errors['district_code']?.message}
                  />
                  <SharedAutocomplete
                    onSelect={(value) => {
                      onSelectWard(value.item.code);
                    }}
                    containerClassName="address-input my-3"
                    data={autocompleteData.wards}
                    label={''}
                    placeholder="Nhập phường/xã"
                    defaultValue={customer?.address?.wards?.ward_name}
                    key={`${customer?.address?.wards?.ward_name}:ward`}
                    required
                    errors={errors['ward_code']?.message}
                    filterOption={(inputValue: string, option: AutocompleteItem<AddressItem>) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </div>
                <ShareInput
                  containerClassName="input-wrapper"
                  register={register}
                  name="address_detail"
                  placeholder="Địa chỉ chi tiết"
                  label="Địa chỉ chi tiết"
                />
                {/* <ShareInput
                  containerClassName="input-wrapper"
                  disabled
                  register={register}
                  name="seller_name"
                  placeholder="Tên seller"
                  label="Tên seller"
                /> */}
                <div className="seller-block">
                  <SharedAutocompleteV2
                    name="seller"
                    isClearable
                    label="Tên seller"
                    containerClassName="input-wrapper"
                    onSelect={(v: AutocompleteItem<SellerItem>) => {
                      onSellerChange(v);
                    }}
                    data={sellerData}
                    placeholder="Chọn seller"
                    defaultValue={getValues('seller')}
                  />
                  <SharedButton
                    onClick={updateCustomerLevel}
                    backgroundColor={MAIN_THEME_DATA.mainColor}
                    btnStyle="basic"
                    text={'Xác nhận'}
                  />
                </div>
              </div>
            </div>
            {
              <div className="btn-block">
                <SharedButton
                  onClick={() => {
                    updateCustomerInfo();
                  }}
                  backgroundColor="rgb(6 182 212)"
                  btnStyle="basic"
                  text={'Cập nhật thông tin'}
                />
                {(isAccepted || isDisable) && (
                  <SharedButton
                    onClick={() => {
                      onDeny();
                    }}
                    backgroundColor="#dc3545"
                    btnStyle="basic"
                    text={isAccepted ? 'Khoá' : 'Từ chối'}
                  />
                )}
                {!isAccepted && (
                  <SharedButton
                    onClick={onAccept}
                    backgroundColor="#28a745"
                    btnStyle="basic"
                    text={'Kích hoạt'}
                  />
                )}
              </div>
            }
            {showExtraInput.shown && (
              <div
                className={`deny-wrapper ${
                  errors[isRejected ? 'reason' : 'code']?.message ? 'items-center' : 'items-end'
                }`}
              >
                <ShareInput
                  required
                  register={register}
                  name={isRejected ? 'reason' : 'code'}
                  placeholder={isRejected ? 'Lí do từ chối' : 'Mã khách hàng'}
                  label={isRejected ? 'Nhập lí do từ chối' : 'Nhập mã khách hàng'}
                  errors={errors[isRejected ? 'reason' : 'code']?.message}
                />
                <SharedButton
                  typeHtml="submit"
                  backgroundColor={isRejected ? '#dc3545' : '#28a745'}
                  btnStyle="basic"
                  text={'Xác nhận'}
                />
              </div>
            )}
            <IConCancel
              onClick={() => {
                onClose();
              }}
              className="cancel"
            />
          </form>
        </AppModal>
      )}
    </StyledMemberListSection>
  );
};

const StyledMemberListSection = styled.div`
  p {
    margin-bottom: 0;
  }
  .filter-wrapper {
    width: 22rem;
    margin-left: 1rem;
  }
  .app-modal {
    .content {
      height: 70rem;
      overflow-y: auto;
    }
  }
  .inner-modal {
    width: 100rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .ant-select-selector {
      @media (min-width: 768px) {
        height: 4rem;
      }
    }
    .address-block {
      display: flex;
      flex-direction: row;
      width: 100%;
      .address-input {
        flex: 1;
        margin-right: 2rem;
      }
      .sub-address-input {
        flex: 1;
      }
    }
    .customer-info {
      margin-top: 2rem;
    }
    .deny-wrapper {
      display: flex;
      width: 100%;
      .btn {
        width: 20%;
        padding: 1rem 0;
        margin-left: 1rem;
      }
    }
    .btn-block {
      display: flex;
      margin-top: 1rem;
      justify-content: center;
      width: 100%;
      .btn {
        width: 20%;
        padding: 1rem 0;
        margin-left: 1rem;
        height: unset;
      }
    }

    .cancel {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .input-container {
      width: 100%;
      .info-block {
        .title {
          font-size: 1.6rem;
        }
        .input-address-wrapper {
          display: flex;
          /* justify-content: space-between; */
          align-items: flex-end;
          margin-top: 1rem;
          .inp-wrapper {
            margin-right: 2rem;
          }
        }
      }
      .level-block {
        display: flex;
        align-items: center;
        .btn {
          width: 20%;
          margin-left: 1rem;
          height: 3.8rem;
        }
      }
      .seller-block {
        display: flex;
        align-items: flex-end;
        .btn {
          width: 20%;
          margin-left: 1rem;
          height: 3.8rem;
        }
        .input-wrapper {
          margin-bottom: 0;
        }
      }
    }
    .ant-image-mask {
      border-radius: 100px;
    }
    .avatar {
      width: 15rem;
      aspect-ratio: 1;
      border-radius: 100px;
    }
    .input-wrapper {
      margin-bottom: 1rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .action-btn {
    width: 13.5rem;
  }
  .head-actions {
    .search-fil {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
      .select__control {
        height: 4.3rem;
      }
      .search-rules__box {
        min-width: 30rem;
        width: fit-content;
        .search-rules__input {
          height: 4.5rem;
        }
      }
      .head-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .status-select {
          /* margin-right: 1.2rem; */
        }
      }
      .create-rule__button {
        height: 4.5rem;
        width: fit-content;
      }
    }
    .filter-contain {
      margin-bottom: 1.8rem;
    }
    .time-fil {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 2rem;
      .type-select {
        min-width: 15rem;
      }
      .time-select {
        height: 3.5rem;
        min-width: 25rem;
      }
      .type-time__box {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .input__label {
          /* min-width: 10.3rem; */
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
      .from-time {
        margin-left: 3.6rem;
      }
      .to-time {
        margin-left: 2rem;
      }
    }
    .filter-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .filters-btn {
      .text-btn {
        /* color: ${(p) => p.theme?.colors?.button?.text}; */
      }
    }
    .filter-box {
      .app-select__label {
        min-width: 15.3rem;
      }
      .select-filter {
        .label {
          margin-bottom: 0;
        }
      }
    }
    .ant-select {
      width: 100%;
      min-width: 10rem;
      max-width: 15rem;
      .ant-select-selector {
        height: 3.5rem;
      }
    }
  }

  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
`;

const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
