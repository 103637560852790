import { CreateProductModule } from '@modules';
import { StyledProductPage } from './Product';

export const CreateProductPage = () => {
  return (
    <StyledProductPage className="edit-announcement-page">
      <div className="page__head">
        <h2 className="title">Thêm sản phẩm</h2>
      </div>
      <CreateProductModule isEdit={false} />
    </StyledProductPage>
  );
};
