import { AppModal, ShareInput, SharedAutocomplete, SharedButton } from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { AutocompleteItem, ProductAttribute, ProductAutocompleteData } from '@interfaces';
import { themes, useTheme } from '@theme';
import { FieldErrorsImpl, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled from 'styled-components';
interface DetailInfoProps {
  attributeList: ProductAttribute[];
  register: UseFormRegister<any>;
  autocompleteData: ProductAutocompleteData;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  isOptionEmpty: (searchText: string) => void;
  onAddAttribute: (attr: ProductAttribute) => void;
  onConfirmAddAttribute: () => void;
  errors: Partial<FieldErrorsImpl<any>>;
  attrOpened: boolean;
  onAttrModalInteract: (value: boolean) => void;
}
export const DetailInfo = ({
  attributeList,
  register,
  autocompleteData,
  setValue,
  watch,
  isOptionEmpty,
  onAddAttribute,
  onConfirmAddAttribute,
  errors,
  attrOpened,
  onAttrModalInteract,
}: DetailInfoProps) => {
  const { theme } = useTheme();
  const attrWatch = watch('attr_item');
  const isEmpty = watch('is_empty');
  return (
    <StyledDetailInfo modalInputError={(errors['attr_item']?.message?.toString()?.length || 0) > 0}>
      <form className="form">
        {attributeList?.map((item) => {
          if (item.detail)
            return (
              <ShareInput
                key={item.id}
                containerClassName="input-ctn"
                className="input-mb"
                name={item.id}
                register={register}
                label={item.name}
                placeholder="Vui lòng nhập"
                // errors={errors['name']?.message}
              />
            );
        })}
      </form>
      <span
        onClick={() => {
          onAttrModalInteract(true);
        }}
        className="add-text"
      >
        Bổ sung thuộc tính
      </span>
      {attrOpened && (
        <AppModal open={attrOpened}>
          <div className="inner-modal">
            <SharedAutocomplete
              data={autocompleteData.attributes}
              label={'Danh sách phân loại'}
              placeholder="Chọn phân loại"
              required
              onSelect={(value: AutocompleteItem<ProductAttribute>) => {
                onAddAttribute({
                  id: value.item.id,
                  name: value.item.name,
                });
              }}
              onSearch={isOptionEmpty}
              filterOption={(inputValue: string, option: AutocompleteItem<ProductAttribute>) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              errors={errors['attr_item']?.message as string}
            />
            <div className="btn-block">
              <SharedButton
                onClick={() => {
                  onAttrModalInteract(false);
                }}
                backgroundColor="transparent"
                textColor={theme?.colors?.button?.text || themes.theme.light.colors.button.text}
                borderColor={
                  theme?.colors?.button?.border || themes.theme.light.colors.button.border
                }
                btnStyle="basic"
                text={'Hủy'}
              />
              <SharedButton
                onClick={onConfirmAddAttribute}
                disabled={!attrWatch}
                backgroundColor={MAIN_THEME_DATA.mainColor}
                btnStyle="basic"
                text={isEmpty && attrWatch ? 'Tạo' : 'Thêm'}
              />
            </div>
          </div>
        </AppModal>
      )}
    </StyledDetailInfo>
  );
};
const StyledDetailInfo = styled.div<{ modalInputError: boolean }>`
  .dropdown {
    margin-top: 1rem;
    .ant-select-selector {
      padding: 0.4rem !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 38px;
    padding: 0 11px;
  }
  .add-text {
    color: ${MAIN_THEME_DATA.mainColor};
    cursor: pointer;
  }
  .inner-modal {
    width: 50rem;
    .btn-block {
      display: flex;
      margin-top: 1rem;
      justify-content: center;
    }
    .btn {
      width: 20%;
      padding: 1rem 0;
      margin-left: 1rem;
      height: unset;
    }
  }
  .form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 140rem;
    .input-ctn {
      width: 30rem;
      margin-right: 5rem;
    }
    .input-mb {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
    /* width: 100rem;
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    } */
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
