import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';

import {
  Pagination,
  PopoverPopup,
  SearchIcon,
  SharedButton,
  SharedTable,
  ShareInput,
  TickBox,
  Trash,
} from '@components';
import { MAIN_THEME_DATA, SELLER_STATUS } from '@configs';
import { FilterDataItem, SellerData } from '@interfaces';
import { useTheme } from '@theme';
import { useUrlQuery } from '@utils';

interface IProps {
  memberData: SellerData;
  tierFilterData?: FilterDataItem[];
  currentPage: number;
  onSearch: (value: string) => void;
  onViewProfile: (id: number | string) => void;
  onPageChange: (value: number) => void;
  navigateToCreateForm: () => void;
}

interface DataType {
  key: React.Key;
  no: number;
  tierName: string;
  condition: string;
  monthsOfStatus: string;
  mainTier: string;
  statusTier: boolean;
}

export const SellerListSection = (props: IProps) => {
  const {
    memberData,
    currentPage,
    tierFilterData,
    onSearch,
    onViewProfile,
    onPageChange,
    navigateToCreateForm,
  } = props;

  const { theme } = useTheme();

  const urlQuery = useUrlQuery();

  const columns: ColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (text, record, index) => index + currentPage * 10 + 1,
      sorter: (a, b) => a.no - b.no,
    },
    { title: 'Họ và tên', dataIndex: 'full_name', key: 'full_name' },
    { title: 'Số điện thoại', dataIndex: 'phone_number', key: 'phone_number' },
    { title: 'Mã seller', dataIndex: 'code', key: 'code' },
    { title: 'Vai trò', dataIndex: 'role', key: 'role' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Ngày sinh', dataIndex: 'dob', key: 'dob' },
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'x',
      width: '20%',
      render: (value: any) => {
        const isActive = value.status === SELLER_STATUS.ACTIVE;
        return (
          <div className="flex items-center justify-center">
            <SharedButton
              textColor="white"
              // backgroundColor={MAIN_THEME_DATA.mainColor}
              className="edit-btn btn-edit table-actions-btn  mr-3"
              prevIcon={<TickBox color="white" />}
              text="Sửa"
              onClick={() => value.onEdit()}
            />
            <PopoverPopup
              content={
                <StyledConfirmPopup>
                  <h5 className="text-center items-center mb-2 text-current text-base">
                    Thông báo
                  </h5>
                  <p className="text-sm">
                    Bạn có muốn {isActive ? `vô hiệu hoá` : 'kích hoạt'} tài khoản này không?
                  </p>
                </StyledConfirmPopup>
              }
              isHaveConfirmButton
              onConfirm={() => {
                value.onHide();
              }}
            >
              <SharedButton
                className={`edit-btn table-actions-btn ${
                  isActive ? ` btn-delete` : `btn-info`
                } action-btn`}
                backgroundColor={MAIN_THEME_DATA.mainColor}
                prevIcon={isActive ? <Trash color={'white'} /> : <TickBox color="white" />}
                textColor="white"
                text={isActive ? 'Vô hiệu hoá' : 'Kích hoạt'}
              />
            </PopoverPopup>
          </div>
        );
      },
    },
  ];
  return (
    <StyledMemberListSection>
      <div className="head-actions">
        <div className="search-fil">
          <ShareInput
            containerClassName="search-rules__box"
            placeholder="Tìm kiếm"
            className="search-rules__input"
            type="text"
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
            prevIcon={<SearchIcon />}
          />
          <div className="flex">
            <SharedButton
              onClick={navigateToCreateForm}
              className="create-tier__button"
              backgroundColor={MAIN_THEME_DATA.mainColor}
              text="Thêm nhân viên"
              btnStyle="pad"
            />
          </div>
        </div>
      </div>
      <SharedTable columns={columns} dataSource={memberData.data} />
      <div className="bottom-pagination">
        <Pagination
          className="pagination pt-2"
          currentPage={currentPage}
          totalCount={memberData.count}
          pageSize={memberData.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
    </StyledMemberListSection>
  );
};

const StyledMemberListSection = styled.div`
  .action-btn {
    width: 13.5rem;
  }
  .head-actions {
    .search-fil {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2rem;
      margin-top: 1rem;
      justify-content: space-between;
      .search-rules__box {
        min-width: 30rem;
        width: fit-content;
        .search-rules__input {
          height: 4.5rem;
        }
      }
      .head-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .status-select {
          /* margin-right: 1.2rem; */
        }
      }
      .create-rule__button {
        height: 4.5rem;
        width: fit-content;
      }
    }
    .filter-contain {
      margin-bottom: 1.8rem;
    }
    .time-fil {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 2rem;
      .type-select {
        min-width: 15rem;
      }
      .time-select {
        height: 3.5rem;
        min-width: 25rem;
      }
      .type-time__box {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        .input__label {
          /* min-width: 10.3rem; */
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
      .from-time {
        margin-left: 3.6rem;
      }
      .to-time {
        margin-left: 2rem;
      }
    }
    .filter-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .filters-btn {
      .text-btn {
        /* color: ${(p) => p.theme?.colors?.button?.text}; */
      }
    }
    .filter-box {
      .app-select__label {
        min-width: 15.3rem;
      }
      .select-filter {
        .label {
          margin-bottom: 0;
        }
      }
    }
    .ant-select {
      width: 100%;
      min-width: 10rem;
      max-width: 15rem;
      .ant-select-selector {
        height: 3.5rem;
      }
    }
  }

  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }
`;

const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
