import { WarehouseModule } from '@modules';
import React from 'react';
import styled from 'styled-components';

export const WarehousePage = () => {
  return (
    <StyledWarehousePage>
      <div className="list-tier__head">
        <h2 className="title">Danh sách kho</h2>
      </div>
      <div className="list-tier__section">
        <WarehouseModule />
      </div>
    </StyledWarehousePage>
  );
};
const StyledWarehousePage = styled.div`
  .list-tier__head {
    margin-bottom: 2rem;
    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .list-tier__section {
    position: relative;
    padding: 2rem 2.8rem;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    box-shadow: 0px 3px 20px #0000000b;
  }
`;
