import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultApiParams, memberAPI } from '@api';
import { SellerListSection } from '@components';
import {
  INITIAL_PAGE,
  MAX_ITEM_PER_PAGE,
  PATH_CREATE_SELLER_PROFILE,
  PATH_MEMBER,
  PATH_SELLER,
  ROLES,
  SELLER_STATUS,
} from '@configs';
import { FilterDataItem, SellerColumn, SellerData, SellerRoot } from '@interfaces';
import {
  selectApp,
  selectAuth,
  setCurrentPage,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';

import { useImmer } from 'use-immer';

export const SellersModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(selectAuth);
  const { themeMode, currentPage } = useAppSelector(selectApp);
  const isAdmin = role === ROLES.ADMIN;
  const [tierFilterData, setTierFilterData] = useState<FilterDataItem[]>();
  const [members, setMembers] = useImmer<SellerData>({
    limit: 0,
    count: 0,
    data: [],
  });

  const [payload, setPayload] = useState<DefaultApiParams>({
    page: currentPage || 0,
    keyword: null,
  });

  const onSearch = debounce((value: string) => {
    setPayload({ ...payload, keyword: value === '' ? null : value.trim(), page: INITIAL_PAGE });
  }, 500);

  const onViewProfile = (id: number | string) => {
    dispatch(setCurrentPage(Number(payload.page)));
    navigate(PATH_MEMBER + `/member-profile/${id}`);
  };

  const onChangeAccountStatus = async (id: string, status: number) => {
    try {
      const res = await memberAPI.changeSellerStatus(id, status);
      await getMembers();
    } catch (error) {}
  };

  const getMembers = async () => {
    try {
      dispatch(setTableLoading(true));
      const res: SellerRoot = await memberAPI.getSellers({
        ...payload,
        keyword: payload.keyword?.trim(),
      });
      const members = res.data?.sellers;
      const memberList: SellerColumn[] = members?.map((item, index) => {
        return {
          no: index + 1,
          key: item.id,
          full_name: item.full_name,
          code: item?.code || '',
          phone_number: item.phone_number,
          brand_name: item.brand_name,
          role: item.role,
          email: item.email,
          dob: item.dob,
          status: item.status,
          onEdit: () => {
            navigateToEditForm(item.id);
          },
          onHide: () => {
            onChangeAccountStatus(
              item.id,
              item.status === SELLER_STATUS.ACTIVE ? SELLER_STATUS.INACTIVE : SELLER_STATUS.ACTIVE,
            );
          },
        };
      });
      setMembers({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.count,
        data: memberList,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  };

  const handleChangePage = (page: number) => {
    setPayload({ ...payload, page });
    dispatch(setCurrentPage(Number(page)));
  };
  const navigateToCreateForm = () => {
    navigate(PATH_CREATE_SELLER_PROFILE);
  };
  const navigateToEditForm = (id: string) => {
    navigate(`${PATH_SELLER}/edit/${id}`);
  };
  useEffect(() => {
    getMembers();
  }, [payload]);

  return (
    <SellerListSection
      onSearch={onSearch}
      onViewProfile={onViewProfile}
      memberData={members}
      tierFilterData={tierFilterData}
      currentPage={payload?.page || 0}
      onPageChange={handleChangePage}
      navigateToCreateForm={navigateToCreateForm}
    />
  );
};
