import { MAIN_THEME_DATA, PAGE_SIZE_OPTIONS } from '@configs';
import { CreateWarehouseResponse, WarehouseColumn, WarehouseData } from '@interfaces';
import { themes, useTheme } from '@theme';
import { LogApp } from '@utils';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { BaseSyntheticEvent } from 'react';
import {
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import styled from 'styled-components';
import { AppModal } from '../AppModal';
import { SearchIcon } from '../Icon';
import {
  Pagination,
  SharedButton,
  SharedTable,
  ShareInput,
  ShareSelectInput,
  ShareTimeSelect,
} from '../shared';
import { AddressAutofill } from '@mapbox/search-js-react';
import { WarehouseForm } from './WarehouseForm';
import { WarehouseFormModule } from '@modules';
interface IProps {
  columns: ColumnsType<WarehouseColumn>;
  warehouses: WarehouseData;
  currentPage?: number;
  onPageChange: (page: number) => void;
  onSearch: (value: string) => void;
  limit?: number;
  onLimitChange: (lim: number) => void;
  onModalInteract: (val: boolean, id: string) => void;
  onSuccess: (res: CreateWarehouseResponse) => void;
  modalVisible: boolean;
  handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  isEdit: boolean;
  onOpenCreateForm: () => void;
  defaultAddress: any;
  currentId: string;
}
export const WarehouseTable = ({
  columns,
  warehouses,
  onPageChange,
  onSearch,
  currentPage,
  onLimitChange,
  limit,
  onSuccess,
  modalVisible,
  onModalInteract,
  register,
  handleSubmit,
  errors,
  isEdit,
  onOpenCreateForm,
  defaultAddress,
  currentId,
}: IProps) => {
  const { theme } = useTheme();
  LogApp(warehouses, 'warehouses');
  return (
    <StyledTableWrapper>
      <StyledSearchWrapper>
        <div className="input-wrapper">
          <ShareInput
            containerClassName="search-tier__box"
            className="search-tier__input"
            onChange={(e: any) => {
              onSearch(e.target.value);
            }}
            placeholder="Tìm kiếm"
            prevIcon={<SearchIcon />}
          />
        </div>
        <div className="flex">
          <SharedButton
            onClick={() => {
              onOpenCreateForm();
            }}
            className="create-tier__button"
            backgroundColor={MAIN_THEME_DATA.mainColor}
            text="Thêm kho"
            btnStyle="pad"
          />
        </div>
      </StyledSearchWrapper>
      <SharedTable columns={columns} dataSource={warehouses.data} />
      <div className="bottom-pagination">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={warehouses.count}
          pageSize={warehouses.limit}
          onPageChange={(page: number) => {
            onPageChange(page);
          }}
        />
      </div>
      <WarehouseFormModule
        onSuccess={onSuccess}
        onModalInteract={onModalInteract}
        modalVisible={modalVisible}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        defaultAddress={defaultAddress}
        currentId={currentId}
      />
    </StyledTableWrapper>
  );
};
const StyledTableWrapper = styled.div`
  .input-wrapper {
    margin-top: 1rem;
  }
  .bottom-pagination {
    margin-top: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pagination-bar {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .page-size {
      display: flex;
      align-items: center;
      .label {
        display: inline-block;
        white-space: nowrap;
        padding-right: 0.8rem;
      }
    }
  }

  .modal {
    width: 100rem;
    .time-picker {
      padding: 0.6rem 1.2rem;
      /* height: 100%;
    width: 100%; */
      box-shadow: none !important;
      outline: none !important;
    }
    @media (max-width: 1280px) {
      width: 70rem;
    }
    @media (max-width: 820px) {
      width: 50rem;
    }
    .dropdown {
      margin-top: 1rem;
      .ant-select-selector {
        padding: 0.4rem !important;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 38px;
      padding: 0 11px;
    }
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
    .branch-list-block {
      display: flex;
      justify-content: center;
      .branch-list-wrapper {
        h4 {
          margin-bottom: 1rem;
        }
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        background-color: #f1f5f9;
        padding: 1rem 0rem;
        .selected-list {
          width: 40%;
        }
        .available-list {
          width: 40%;
        }
        .move-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .move-btn {
            padding: 0.8rem 1rem;
            width: fit-content;
          }
        }
      }
    }
  }
`;
const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .search-tier__box {
    min-width: 20rem;
    width: fit-content;
    .search-tier__input {
      height: 4.5rem;
    }
  }
  .create-tier__button {
    height: 4.5rem;
    width: fit-content;
  }
`;
