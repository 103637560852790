/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { logout, store } from 'src/redux';
import axios from 'axios';
import { LogApp } from '@utils';
import { toast } from 'react-toastify';
import { ERROR_CODES, RESET } from '@configs';
const queryString = require('query-string');
export interface ResponseType<T> {
  result_code: number;
  result_error: any;
  status: number;
  code: number;
  message: string;
  data: any;
  success: boolean;
}
export interface DefaultApiParams {
  keyword?: string | null;
  size?: number;
  page?: number;
  all?: boolean;
  warehouse_id?: string | null;
  category_id?: string | null;
  customer_id?: string | null;
  seller_id?: string | null;
  customer_type_id?: string | null;
  exclude_promotion?: string;
}
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://localhost:8089/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    charset: 'UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': 'vi',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config: any) => {
  const role = store.getState().app.role;
  const auth = store.getState().auth;
  const token = auth.accessToken;

  config.headers['Authorization'] = `Bearer ${token}`;
  // config.headers["Accept-Encoding"] = `gzip, deflate, br`;
  // config.headers["x-csrf-token"] = token;
  delete axios.defaults.headers.common['Accept-Encoding'];
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error: any) => {
    if (error.response) {
      // Request made and server responded
      if (error.response.status === ERROR_CODES.UNAUTHORIZED) {
        store.dispatch(logout());
        store.dispatch({ type: RESET });
      }
      if (error.response.data.data.detail) {
        toast.error(`${error.response.data.data.detail}`, {
          position: 'top-right',
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          theme: 'colored',
        });
      }
      LogApp(error.response.data, '~> Request made and server responded');
    } else if (error.request) {
      // The request was made but no response was received
      // LogApp(error.request, '~> The request was made but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      // LogApp(error.message,'~> Something happened in setting up the request that triggered an Error');
    }
    throw error.response.data.detail;
  },
);
function handleResult<T>(api: any, generic?: T) {
  return api.then((res: any) => handleResponse<T>(res));
}
function handleResponse<T>(data: ResponseType<T>) {
  return Promise.resolve(data);
}
export const ApiClient = {
  get: (url: string, payload?: any) => handleResult(axiosClient.get(url, payload)),
  post: (url: string, payload?: any) => handleResult(axiosClient.post(url, payload)),
  put: (url: string, payload?: any) => handleResult(axiosClient.put(url, payload)),
  path: (url: string, payload: any) => handleResult(axiosClient.patch(url, payload)),
  delete: (url: string, payload?: any) => handleResult(axiosClient.delete(url, { data: payload })),
  patch: (url: string, payload?: any) => handleResult(axiosClient.patch(url, payload)),
};
export default axiosClient;
