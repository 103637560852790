import { ChangeBannerStatusPayload } from '@interfaces';
import { ApiClient, DefaultApiParams } from './axiosClient';

export const bannerAPI = {
  getBanners: (params?: DefaultApiParams) => {
    const url = '/images/banners?size=10';
    return ApiClient.get(url, { params });
  },
  uploadBanners: (payload: FormData, dates: { start_date: string; end_date: string }) => {
    const url = `/images/banner-upload?start_date=${dates.start_date}&end_date=${dates.end_date}`;
    return ApiClient.post(url, payload);
  },
  changeBannerStatus: (payload: ChangeBannerStatusPayload) => {
    const url = '/images/banner-disable';
    return ApiClient.post(url, payload);
  },
  updateBannerDate: (id: string, payload: { start_date: string; end_date: string }) => {
    const url = `/images/${id}/banners?start_date=${payload.start_date}&end_date=${payload.end_date}`;
    return ApiClient.put(url);
  },
};
