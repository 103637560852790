import { CreateProductModule } from '@modules';
import { StyledProductPage } from './Product';

export const EditProductPage = () => {
  return (
    <StyledProductPage>
      <div className="page__head">
        <h2 className="title">Sửa sản phẩm</h2>
      </div>
      <CreateProductModule isEdit />
    </StyledProductPage>
  );
};
