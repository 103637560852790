import {
  AppModal,
  IConArrowRight,
  IConCancel,
  ShareInput,
  ShareTextEditor,
  SharedAutocompleteV2,
  SharedButton,
} from '@components';
import { MAIN_THEME_DATA } from '@configs';
import { AutocompleteItem, Category, ProductAutocompleteData, SubCategory } from '@interfaces';
import { useTheme } from '@theme';
import { EditorState } from 'draft-js';
import { FieldErrorsImpl, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
interface ProductInfoProps {
  register: UseFormRegister<any>;
  errors: Partial<FieldErrorsImpl<any>>;
  autoCompleteData: ProductAutocompleteData;
  onSelectBasicInfo: (value: string, type: 'warehouse' | 'category') => void;
  getValues: UseFormGetValues<any>;
  selectedCategory: AutocompleteItem<Category>;
  onSelectParentCategory: (item: AutocompleteItem<Category>) => void;
  onSelectSubCategory: (item: SubCategory) => void;
  selectedSubCategory: SubCategory;
  opened: boolean;
  onModalInteract: (value: boolean) => void;
  onCategoryConfirm: () => void;
  onCategoryCancel: () => void;
  editorRef: any;
  htmlDes: EditorState;
  isEdit?: boolean;
}
export const ProductInfo = ({
  register,
  errors,
  autoCompleteData,
  onSelectBasicInfo,
  getValues,
  selectedCategory,
  onSelectParentCategory,
  onSelectSubCategory,
  selectedSubCategory,
  opened,
  onModalInteract,
  onCategoryConfirm,
  onCategoryCancel,
  editorRef,
  htmlDes,
  isEdit,
}: ProductInfoProps) => {
  const { theme } = useTheme();
  const defaultWarehouse = autoCompleteData.warehouses.filter(
    (item) => item.item.id === getValues('warehouse_id'),
  );
  return (
    <StyledProductInfo>
      <form className="form">
        {/* <ShareInput
          className="input-mb"
          name="name"
          // register={register}
          label="Mã sản phẩm"
          required
          placeholder="Nhập mã sản phẩm"
          // errors={errors['name']?.message}
        /> */}
        <ShareInput
          className="input-mb"
          name="name"
          register={register}
          label="Tên sản phẩm"
          required
          placeholder="Nhập tên sản phẩm"
          errors={errors['name']?.message}
        />
        <ShareInput
          className="input-mb"
          name="origin"
          register={register}
          label="Xuất xứ"
          required
          placeholder="Nhập xuất xứ"
          errors={errors['origin']?.message}
        />
        <ShareInput
          className="input-mb"
          name="unit"
          register={register}
          label="Đơn vị"
          required
          placeholder="Nhập đơn vị"
          errors={errors['unit']?.message}
        />
        <ShareInput
          className="input-mb"
          name="type"
          register={register}
          label="Loại sản phẩm"
          required
          placeholder="Nhập loại sản phẩm (Có sẵn, Đặt trước,...)"
          errors={errors['type']?.message}
        />
        {/* <ShareInput
          type="number"
          className="input-mb"
          name="deduct_percent"
          register={register}
          label="Chiết khấu (%)"
          min={0}
          max={100}
          step={0.1}
          // required
          placeholder="Nhập chiết khấu"
          // errors={errors['deduct_percent']?.message}
        /> */}
        <StyledTextArea>
          <p className="title">Mô tả sản phẩm</p>
          <div className="editor-wrapper">
            <ShareTextEditor
              className="editorClassName"
              wrapperClassName="wrapperClassName"
              ref={editorRef}
              key={`htmlDes:${htmlDes}`}
              value={htmlDes}
            />
          </div>
        </StyledTextArea>
        {/* <SharedAutocompleteV2
          onSelect={(value) => {
            onSelectBasicInfo(value, 'category');
          }}
          isMulti
          containerClassName="input-mb"
          data={autoCompleteData.categories}
          label={'Danh mục sản phẩm'}
          placeholder="Chọn danh mục sản phẩm"
          defaultValue={defaultCategories}
          key={`categories_${defaultCategories.length}`}
          required
          errors={errors['category_ids']?.message}
        /> */}
        <ShareInput
          className="input-mb"
          name="category_ids"
          register={register}
          onClick={() => {
            onModalInteract(true);
          }}
          label="Danh mục sản phẩm"
          required
          placeholder="Chọn danh mục sản phẩm"
          errors={errors['category_ids']?.message}
        />
        <SharedAutocompleteV2
          isMulti={!isEdit}
          onSelect={(value) => {
            // LogApp(value,'vlll')
            onSelectBasicInfo(value, 'warehouse');
          }}
          data={autoCompleteData.warehouses}
          label={'Danh sách kho'}
          placeholder="Chọn kho"
          defaultValue={defaultWarehouse}
          key={`warehouse_${defaultWarehouse.length}`}
          required
          errors={errors['warehouse_ids']?.message}
        />
      </form>
      {opened && (
        <AppModal modalClassName="modal" open={opened}>
          <div className="modal-header">
            <p>Danh mục sản phẩm</p>
            <IConCancel
              onClick={() => {
                onModalInteract(false);
              }}
            />
          </div>
          <div className="modal-inner">
            <div className="category-block">
              {autoCompleteData.categories.map((item) => {
                return (
                  <div
                    onClick={() => {
                      onSelectParentCategory(item);
                    }}
                    key={item.item.id}
                    className="category-item"
                  >
                    <span className={`${selectedCategory.label === item.label ? 'selected' : ''}`}>
                      {item.label}
                    </span>
                    <IConArrowRight width={6} height={12} />
                  </div>
                );
              })}
            </div>
            <div className="divider" />
            <div className="category-block">
              {selectedCategory?.item?.sub_categories?.map((item) => {
                return (
                  <div
                    onClick={() => {
                      onSelectSubCategory(item);
                    }}
                    key={item.id}
                    className="category-item"
                  >
                    <span className={`${selectedSubCategory.name === item.name ? 'selected' : ''}`}>
                      {item.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="selected-block">
            <div className="text-wrapper">
              <span>Đã chọn: </span>
              <span className="selected-item ml">{selectedCategory.label}</span>
              <div className="mx">
                {selectedSubCategory?.name && <IConArrowRight width={6} height={12} />}
              </div>
              <span className="selected-item">{selectedSubCategory?.name}</span>
            </div>
            <div className="btn-wrapper">
              <SharedButton
                text="Huỷ"
                className="button"
                backgroundColor="transparent"
                textColor={theme?.colors?.button?.text}
                borderColor={theme?.colors?.button?.border}
                btnStyle="pad"
                onClick={onCategoryCancel}
              />
              <SharedButton
                onClick={onCategoryConfirm}
                text={'Xác nhận'}
                className="button ml"
                backgroundColor={MAIN_THEME_DATA.mainColor}
                btnStyle="pad"
              />
            </div>
          </div>
        </AppModal>
      )}
    </StyledProductInfo>
  );
};
const StyledProductInfo = styled.div`
  .editor-wrapper {
    position: relative;
    border-radius: 0.6rem;
    background: ${(p) => p.theme.colors.bgSection};
    .wrapperClassName {
      height: 100%;
      width: 100%;
    }
    .editorClassName {
      resize: vertical;
      overflow: auto;
    }
  }
  .modal {
    .modal-header {
      display: flex;
      justify-content: space-between;
    }
    .selected-block {
      display: flex;
      justify-content: space-between;
      .text-wrapper {
        display: flex;
        align-items: center;
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        .button {
          max-height: 3.1rem;
          width: fit-content;
        }
      }
      margin-top: 1rem;
      display: flex;
      align-items: center;
      .mx {
        margin: 0 0.5rem;
      }
      .ml {
        margin-left: 0.5rem;
      }
      .selected-item {
        font-weight: 500;
      }
    }
    p {
      font-size: 2rem;
      font-weight: 500;
    }
    .selected {
      font-weight: 500;
      color: ${MAIN_THEME_DATA.mainColor};
      text-decoration: underline;
    }
    .modal-inner {
      .divider {
        width: 3rem;
      }
      background-color: #f6f6f6;
      padding: 1.6rem;
      display: flex;
      border-radius: 1.2rem;
      .category-block {
        background-color: white;
        border-radius: 0.8rem;
        padding: 1.2rem;
        max-height: 30rem;
        min-width: 35rem;
        overflow-x: auto;
        .category-item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.5rem;
          width: 100%;
        }
      }
    }
  }
  .clickable-input {
    cursor: default;
    pointer-events: none;
  }
  .dropdown {
    margin-top: 1rem;
    .ant-select-selector {
      padding: 0.4rem !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 38px;
    padding: 0 11px;
  }
  .form {
    .input-mb {
      margin-bottom: 1rem;
    }
    width: 70%;
    @media (max-width: 1280px) {
      width: 60%;
    }
    @media (max-width: 820px) {
      width: 100%;
    }
    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      .btn-save {
        align-self: center;
        width: fit-content;
        padding: 1rem 2rem;
      }
      .btn-cancel {
        margin-right: 1rem;
      }
    }
  }
`;
const StyledTextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .title {
    margin-bottom: 0.8rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    color: #303030;
  }
  .text-area-wrapper {
    resize: none;
    height: 10rem;
    padding-left: 1.2rem;
    padding-top: 0.8rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.6rem;
    outline: none;
  }
`;
