import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { authAPI } from '@api';
import { LoginForm } from '@components';
import { PATH_DASHBOARD, PATH_FORGOT_PASSWORD, PATH_SIGN_UP } from '@configs';
import { DecodedRoot, ILoginResFields, RootResponse } from '@interfaces';
import {
  resetFilter,
  selectApp,
  setAccessToken,
  setLoading,
  setRole,
  setUserId,
  setUsername,
  setVersion,
  setWarehouseId,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp } from '@utils';
import jwt_decode from 'jwt-decode';

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

export const LoginModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { themeMode } = useAppSelector(selectApp);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const handleRedirectToForgot = () => {
    navigate(PATH_FORGOT_PASSWORD);
  };

  const handleRedirectToSignUp = () => {
    navigate(PATH_SIGN_UP);
  };
  const checkVersion = async () => {
    try {
      const response: RootResponse<string> = await authAPI.checkVersion();
      dispatch(setVersion(response.data));
    } catch (error) {}
  };
  const handleLogin = handleSubmit(async (data) => {
    const body = {
      username: data.username,
      password: data.password,
    };
    try {
      dispatch(setLoading(true));
      const res: any = await authAPI.login(body);
      const data = res.data as ILoginResFields;
      const decodedToken = jwt_decode(res.data.access_token) as DecodedRoot;
      checkVersion();
      dispatch(setAccessToken(data.access_token));
      dispatch(setRole(decodedToken.claims.authorities?.[0]?.authority));
      dispatch(setUsername(decodedToken.claims.username));
      dispatch(setUserId(decodedToken.claims.user_id));
      dispatch(resetFilter());

      if (decodedToken.claims.warehouse_id)
        dispatch(setWarehouseId(decodedToken.claims.warehouse_id || ''));
      navigate(PATH_DASHBOARD);
    } catch (err: any) {
      setError('password', { type: 'custom', message: err?.message });
    } finally {
      dispatch(setLoading(false));
    }
  });

  return (
    <LoginForm
      register={register}
      redirectToForgot={handleRedirectToForgot}
      redirectToSignUp={handleRedirectToSignUp}
      errors={errors}
      handleLogin={handleLogin}
    />
  );
};
